import moment from 'moment';
import Logger from './Logger';
import Unify from './Unify';
import User from './User';

const logger = new Logger('CMS');

/**
 *
 */
class CMS {
  // Types
  static NEWS = 'news';
  static EVENT = 'event';
  static REWARD = 'reward';
  static BROADCAST = 'broadcast';
  static SERVICE = 'service';
  static NSC = 'nsc';
  static PARTNER = 'partner';
  static CUSTOMER = 'customer';
  static FAQ = 'faq';

  /**
   *
   * @param data
   * @returns {Promise<any|never>}
   */
  async uploadAsset(data) {
    if (data instanceof FormData) {
      data.append('uploaded_by', User.profile.id);
      data.append('role', User.role.entity_key);
    }
    return Unify._post('/api/admin/asset/upload', data);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async get(type, id) {
    return Unify._get(`/api/cms/${type}/${id}`).then((item) => {
      if (!item.properties) {
        item.properties = {};
      }
      return item;
    });
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async create(type, obj) {
    return Unify._put(`/api/cms/create/${type}`, obj);
  }

  async save(type, id, obj) {
    return Unify._put(`/api/cms/create/${type}/${id}`, obj);
  }

  async editTc(id, obj) {
    return Unify._post(`/api/cms/edit/tc/${id}`, obj);
  }
  async unpublishTc(id) {
    return Unify._post(`/api/cms/unpublish/tc/${id}`);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async attendees(type, id) {
    return Unify._get(`/api/cms/${type}/${id}/attendees`);
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async createContents(type, obj) {
    return Unify._post(`/api/cms/${type}`, obj);
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async edit(type, id, obj) {
    return Unify._post(`/api/cms/edit/${type}/${id}`, obj);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async delete(type, id) {
    return Unify._delete(`/api/cms/${type}/${id}`);
  }

  /**
   *
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async update(type, id, obj) {
    if (typeof obj !== 'undefined') {
      obj.updated_at = moment().toISOString();
      return Unify._patch(`/api/cms/${type}/${id}`, obj);
    }
  }

  async unpublish(type, id) {
    return Unify._post(`/api/cms/unpublish/${type}/${id}`);
  }

  async list({ type, filter, dateFilter, sort, pagination, select }) {
    const payload = {
      type,
      filter,
      dateFilter,
      sort,
      pagination,
      select,
    };
    return Unify._post(`/api/cms/content/${type}`, payload).then((results) => {
      if (results && results.length > 0) {
        return results.map((item) => {
          if (!item.properties) {
            item.properties = {};
          }
          // Hoist the properties up
          item = Object.assign({}, item.properties, item);
          if (item.featured === null) {
            item.featured = '';
          }
          return item;
        });
      }
      return results;
    });
  }

  async analytic({ type, dateFilter = {} }) {
    const payload = {
      type,
      dateFilter,
    };
    return Unify._post(`/api/cms/content/${type}/analytics`, payload).then((results) => {
      return results;
    });
  }

  async getSurveyAll({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/survey/all', payload).then((results) => {
      return results;
    });
  }

  async getSurveyById(surveyId) {
    return Unify._get(`/api/survey/${surveyId}`).then((results) => {
      return results;
    });
  }

  async getSurveyAllBroadcast({ filter, sort, select }) {
    const payload = {
      filter,
      sort,
      select,
    };
    return Unify._post('/api/survey/all', payload).then((results) => {
      return results;
    });
  }

  async fetchBurnProduct({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/product/product/burn', payload);
  }

  async fetchEarnProduct({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/product/product/earn', payload);
  }

  async productAnalyticsBurn({ startDate, endDate }) {
    return Unify._get(`/api/analytics/rewards/burn?start_date=${startDate}&end_date=${endDate}`);
  }
  async productAnalyticsEarn({ startDate, endDate }) {
    return Unify._get(`/api/analytics/rewards/earn?start_date=${startDate}&end_date=${endDate}`);
  }

  async productAnalyticsChartBurn({ startDate, endDate, sort = 'ASC' }) {
    return Unify._get(`/api/analytics/rewards/chart/burn?start_date=${startDate}&end_date=${endDate}&sort=${sort}`);
  }
  async productAnalyticsChartEarn({ startDate, endDate, sort = 'ASC' }) {
    return Unify._get(`/api/analytics/rewards/chart/earn?start_date=${startDate}&end_date=${endDate}&sort=${sort}`);
  }

  async downloadCSVReward(type, payload) {
    return Unify._downloadFile(`https://staging.demo.unify.eco/api/admin/csv/rewards/${type}`, payload);
  }

  async triggerExportCSV(key = 'data', type, payload) {
    return Unify._post(`/api/admin/${key}/${type}/export`, payload);
  }

  async triggerExportCSVPartnerManagement(payload) {
    return Unify._post('/api/admin/crm/partner/export', payload);
  }

  async triggerExportPartnerCSV(type, payload) {
    return Unify._post(`/api/admin/crm/${type}/export`, payload);
  }

  async checkExportCSV(exportId) {
    const data = await Unify._get(`/api/admin/data/export/check/${exportId}`);

    // Prevent download when export is not completed
    if (!data || data.status !== 'completed') {
      throw new Error();
    }

    return data;
  }

  async createNewSurvey(payload) {
    return Unify._post('/api/survey', payload);
  }

  async updateSurvey(surveyId, payload) {
    return Unify._put(`/api/survey/update/${surveyId}`, payload);
  }

  async unpublishSurvey(surveyId) {
    return Unify._put(`/api/survey/unpublish/${surveyId}`);
  }

  async deleteSurvey(surveyId) {
    return Unify._delete(`/api/survey/${surveyId}`);
  }

  async fetchProductById(productId) {
    return Unify._get(`/api/product/product/${productId}`);
  }
  async fetchProductBurnById(productId) {
    return Unify._get(`/api/product/product/burn/${productId}`);
  }
  async fetchProductEarnById(productId) {
    return Unify._get(`/api/product/product/earn/${productId}`);
  }

  async fetchProductTransactions(productId, { filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post(`/api/product/rewards/${productId}/transactions`, payload);
  }

  async createProduct(payload) {
    return Unify._post('/api/product/product/create', payload);
  }

  async createBurnProduct(payload) {
    return Unify._post('/api/product/product/burn/create', payload);
  }

  async createEarnProduct(payload) {
    return Unify._post('/api/product/product/earn/create', payload);
  }

  async editProduct(itemId, payload) {
    return Unify._patch(`/api/product/product/edit/${itemId}`, payload);
  }

  async editBurnProduct(itemId, payload) {
    return Unify._patch(`/api/product/product/edit/burn/${itemId}`, payload);
  }

  async editEarnProduct(itemId, payload) {
    return Unify._patch(`/api/product/product/edit/earn/${itemId}`, payload);
  }

  async deleteProduct(itemId) {
    return Unify._delete(`/api/product/product/${itemId}`);
  }

  async fetchTransactions({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/admin/coins', payload);
  }

  async fetchTransactionsV2({ type, filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post(`/api/admin/coins/wallet/${type}`, payload);
  }

  async partnerWalletEarning({ startDate, endDate }) {
    return Unify._get(`/api/analytics/partner-summary/earning?start_date=${startDate}&end_date=${endDate}`);
  }

  async partnerWalletMarketing({ startDate, endDate }) {
    return Unify._get(`/api/analytics/partner-summary/marketing?start_date=${startDate}&end_date=${endDate}`);
  }

  async departmentWalletSummary({ startDate, endDate }) {
    return Unify._get(`/api/admin/crm/department/charts?start_date=${startDate}&end_date=${endDate}`);
  }

  async fetchPartnerById(id) {
    return Unify._get(`/api/admin/crm/partner/${id}`);
  }

  async fetchAllMission({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/cms/mission/all', payload);
  }

  async fetchPermissions() {
    return Unify._get('/api/admin/crm/masterEntities/rolesAndPermissions');
  }

  async updateAdminUser(type, id, edgeKey, body) {
    body.updated_at = moment().utc().toISOString();
    return Unify._patch(`/api/admin/crm/${type}/${id}/admin_user/${edgeKey}`, body);
  }

  async updateUserPermissions(id, permissions) {
    const payload = {
      id,
      permissions,
    };
    return Unify._patch('/api/admin/crm/updateAdminUserPermissions', payload);
  }

  async fetchAdminUser(payload) {
    return Unify._post('/api/admin/crm/admin_users', payload);
  }

  async fetchDepartment(type) {
    let typeQuery = '';
    if (type) {
      typeQuery = `?key=${type}`;
    }
    return Unify._get(`/api/admin/crm/department${typeQuery}`);
  }

  /**
   *
   * @param type {string} - Entity filter type
   * @param properties {Array} - Properties to get from targeted entities
   * @returns {Promise<any|never>}
   */
  async getEntitiesDetails(type, properties, extraFilter = []) {
    let entityType;
    if (type === 'entity') {
      entityType = 'entities';
    } else entityType = `${type}s`;
    const payload = {
      filter: [
        {
          key: 'type',
          value: type,
        },
      ],
      select: properties,
      sort: {
        key: properties[0],
        value: 'DESC',
      },
    };

    if (extraFilter && extraFilter.length > 0) {
      payload.filter.push(...extraFilter);
    }

    return Unify._post(`/api/admin/crm/getEntities/${entityType}`, payload);
  }

  async getEntitiesDetailsV2(rewardType, type, properties, extraFilter = []) {
    let entityType;
    if (type === 'entity') {
      entityType = 'entities';
    } else entityType = `${type}s`;
    const payload = {
      filter: [
        {
          key: 'type',
          value: type,
        },
      ],
      select: properties,
      sort: {
        key: properties[0],
        value: 'DESC',
      },
    };

    if (extraFilter && extraFilter.length > 0) {
      payload.filter.push(...extraFilter);
    }

    return Unify._post(`/api/admin/crm/getEntities/${rewardType}/${entityType}`, payload);
  }

  async fetchHomeAnalytics(type, { startDate, endDate }) {
    return Unify._get(`/api/analytics/home/${type}?start_date=${startDate}&end_date=${endDate}&sort=ASC`);
  }

  async fetchDiscountPromocode({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/product/promocode/discount', payload);
  }

  async deleteDiscountPromocodeById(promocodeId) {
    return Unify._delete(`/api/product/promocode/discount/${promocodeId}`);
  }
  async getDiscountPromocodeAudienceById(promocodeId) {
    return Unify._get(`/api/product/promocode/discount/${promocodeId}/audience`);
  }
  async updateDiscountPromocodeAudienceById(promocodeId, payload) {
    return Unify._put(`/api/product/promocode/discount/${promocodeId}/audience`, payload);
  }

  async createDiscountPromocode(payload) {
    return Unify._post('/api/product/promocode/discount/create', payload);
  }

  async editDiscountPromocode(promocodeId, payload) {
    return Unify._patch(`/api/product/promocode/edit/discount/${promocodeId}`, payload);
  }

  async editOfferPromocode(promocodeId, payload) {
    return Unify._patch(`/api/product/promocode/edit/offer/${promocodeId}`, payload);
  }

  async fetchOfferPromocode({ filter, dateFilter, sort, pagination }) {
    const payload = {
      filter,
      dateFilter,
      sort,
      pagination,
    };
    return Unify._post('/api/product/promocode/offer', payload);
  }

  async deleteOfferPromocodeById(promocodeId) {
    return Unify._delete(`/api/product/promocode/offer/${promocodeId}`);
  }
  async getOfferPromocodeAudienceById(promocodeId) {
    return Unify._get(`/api/product/promocode/offer/${promocodeId}/audience`);
  }
  async updateOfferPromocodeAudienceById(promocodeId, payload) {
    return Unify._put(`/api/product/promocode/offer/${promocodeId}/audience`, payload);
  }

  async createOfferPromocode(payload) {
    return Unify._post('/api/product/promocode/offer/create', payload);
  }

  async customerSignups({ startDate, endDate, sort = 'ASC' }) {
    return Unify._get(`/api/analytics/customer/signup?start_date=${startDate}&end_date=${endDate}&sort=${sort}`);
  }

  async getTransactionVolumeValue(analyticsPayload) {
    const {
      dateFilter: { startDate, endDate },
      analyticFilterType,
    } = analyticsPayload;
    const queryString =
      analyticFilterType === '' || !analyticFilterType
        ? `?start_date=${startDate}&end_date=${endDate}&sort=ASC`
        : `?start_date=${startDate}&end_date=${endDate}&type=${analyticFilterType}&sort=ASC`;
    return Unify._get(`/api/analytics/customer/transactions${queryString}`);
  }
}

export default new CMS();
