import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CRM } from '../services/Unify';

function* crm_list(action) {
  try {
    let result = yield call(CRM.list, action.payload.type);
    const actions = [];
    if (result) {
      if (result.length > 0) {
        // Request balances
        // result.forEach(e => {
        //   if (e.id) {
        //     actions.push(
        //       put({type: 'ACCOUNT_GET_BALANCE', payload: {id: e.id}}),
        //     );
        //   }
        // });

        // Workarounds for bad
        if (action.payload.type === 'partner') {
          result = result.map((e) => {
            if (!e.type) {
              e.type = 'Earn & Burn';
            }
            if (!e.category) {
              e.category = 'Others';
            }
            if (!e.balance || e.balance === 0 || e.balance === '0') {
              e.balance = 0;
            }
            if (!e.created_at) {
              e.created_at = e.updated_at;
            }
            if (!e.status) {
              e.status = 'active';
            }
            return e;
          });
        }
      }
    }
    actions.push(put({ type: action.type + '_SUCCESS', payload: result }));
    yield all(actions);
    //    yield put({type: action.type + '_SUCCESS', payload: result});
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_profiles(action) {
  try {
    const result = yield call(CRM.profiles, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_get(action) {
  try {
    const result = yield call(CRM.get, action.payload.type, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_delete(action) {
  try {
    const result = yield call(CRM.delete, action.payload.type, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_update(action) {
  try {
    const result = yield call(CRM.update, action.payload.type, action.payload.id, action.payload.body);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_list_edges(action) {
  try {
    const result = yield call(CRM.list_edges, action.payload.type, action.payload.id, action.payload.edgeType);
    yield put({
      type: action.type + '_SUCCESS',
      payload: Object.assign({}, action.payload, { list: result }),
    });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_get_edge(action) {
  try {
    const result = yield call(CRM.get_edge, action.payload.type, action.payload.id, action.payload.edgeType, action.payload.edgeKey);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crm_update_edge(action) {
  try {
    const result = yield call(
      CRM.update_edge,
      action.payload.type,
      action.payload.id,
      action.payload.edgeType,
      action.payload.edgeKey,
      action.payload.body
    );
    yield put({ type: action.type + '_SUCCESS', payload: result });
    yield;
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* loadCustomerByEmail(action) {
  try {
    const user = yield call(CRM.getProfileByEmail, action.payload.email);
    yield put({ type: action.type + '_SUCCESS', payload: user });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* crmSaga() {
  yield takeEvery('CRM_LIST', crm_list);
  yield takeEvery('CRM_PROFILES', crm_profiles);
  yield takeEvery('CRM_PROFILE_BY_EMAIL', loadCustomerByEmail);
  yield takeEvery('CRM_GET', crm_get);
  yield takeEvery('CRM_DELETE', crm_delete);
  yield takeEvery('CRM_UPDATE', crm_update);
  yield takeEvery('CRM_LIST_EDGES', crm_list_edges);
  yield takeEvery('CRM_GET_EDGE', crm_get_edge);
  yield takeEvery('CRM_UPDATE_EDGE', crm_update_edge);
}

export default crmSaga;
