const CryptoJS = require('crypto-js');
const AES = require('crypto-js/aes');
const SHA256 = require('crypto-js/sha256');
const SecureStorage = require('secure-web-storage');

// This is obfuscated to an extent
const SECRET_KEY = (function (p, a, c, k, e, d) {
  e = function (c) {
    return c.toString(36);
  };
  if (!''.replace(/^/, String)) {
    while (c--) {
      d[c.toString(a)] = k[c] || c.toString(a);
    }
    k = [
      function (e) {
        return d[e];
      },
    ];
    e = function () {
      return '\\w+';
    };
    c = 1;
  }
  while (c--) {
    if (k[c]) {
      p = p.replace(new RegExp('\\b' + e(c) + '\\b', 'g'), k[c]);
    }
  }
  return p;
})('4<5;3[2[0{1.7[8}6-e"c@b,9@+\\a)=d%', 15, 15, 'sETOD|Qtqp|79|Blq|nUx|hNqTp2l||v|S|7EbD|mR|2nfEN8|bqH|zz|'.split('|'), 0, {});

// Initialise
const secureStorage = new SecureStorage(sessionStorage, {
  hash: function hash(key) {
    key = SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
});

export default secureStorage;
export { secureStorage };
