import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { User } from '../services/Unify';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* loadUser(action) {
  try {
    // const user = yield call(User.getProfile, action.payload.userId);
    const user = yield call(User.getProfile);
    yield put({ type: 'USER_FETCH_SUCCEEDED', payload: user });
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* changeThemeMode(action) {
  yield action.isDarkTheme;
}

function* userSaga() {
  yield takeEvery('USER_LOAD', loadUser);
  yield takeEvery('CHANGE_THEME_MODE', changeThemeMode);
}

export default userSaga;
