import React from 'react';

export const home = <path d="M6.99967 0.5L0.333008 5.5V15.5H4.49967V9.66667H9.49967V15.5H13.6663V5.5L6.99967 0.5Z" />;
export const wallet = (
  <path d="M15.5 13V13.8333C15.5 14.75 14.75 15.5 13.8333 15.5H2.16667C1.24167 15.5 0.5 14.75 0.5 13.8333V2.16667C0.5 1.25 1.24167 0.5 2.16667 0.5H13.8333C14.75 0.5 15.5 1.25 15.5 2.16667V3H8C7.075 3 6.33333 3.75 6.33333 4.66667V11.3333C6.33333 12.25 7.075 13 8 13H15.5ZM8 11.3333H16.3333V4.66667H8V11.3333ZM11.3333 9.25C10.6417 9.25 10.0833 8.69167 10.0833 8C10.0833 7.30833 10.6417 6.75 11.3333 6.75C12.025 6.75 12.5833 7.30833 12.5833 8C12.5833 8.69167 12.025 9.25 11.3333 9.25Z" />
);
export const insights = (
  <path d="M3.50033 16.5001C3.50033 16.9167 3.83366 17.3334 4.33366 17.3334H7.66699C8.16699 17.3334 8.50033 16.9167 8.50033 16.5001V15.6667H3.50033V16.5001ZM6.00033 0.666748C2.75033 0.666748 0.166992 3.25008 0.166992 6.50008C0.166992 8.50008 1.16699 10.2501 2.66699 11.2501V13.1667C2.66699 13.5834 3.00033 14.0001 3.50033 14.0001H8.50033C9.00033 14.0001 9.33366 13.5834 9.33366 13.1667V11.2501C10.8337 10.1667 11.8337 8.41675 11.8337 6.50008C11.8337 3.25008 9.25033 0.666748 6.00033 0.666748Z" />
);
export const transactions = (
  <path d="M5.83301 14.0001C4.91634 14.0001 4.17467 14.7501 4.17467 15.6667C4.17467 16.5834 4.91634 17.3334 5.83301 17.3334C6.74967 17.3334 7.49967 16.5834 7.49967 15.6667C7.49967 14.7501 6.74967 14.0001 5.83301 14.0001ZM0.833008 0.666748V2.33341H2.49967L5.49967 8.65841L4.37467 10.7001C4.24134 10.9334 4.16634 11.2084 4.16634 11.5001C4.16634 12.4167 4.91634 13.1667 5.83301 13.1667H15.833V11.5001H6.18301C6.06634 11.5001 5.97467 11.4084 5.97467 11.2917L5.99967 11.1917L6.74967 9.83341H12.958C13.583 9.83341 14.133 9.49175 14.4163 8.97508L17.3997 3.56675C17.4663 3.45008 17.4997 3.30841 17.4997 3.16675C17.4997 2.70841 17.1247 2.33341 16.6663 2.33341H4.34134L3.55801 0.666748H0.833008ZM14.1663 14.0001C13.2497 14.0001 12.508 14.7501 12.508 15.6667C12.508 16.5834 13.2497 17.3334 14.1663 17.3334C15.083 17.3334 15.833 16.5834 15.833 15.6667C15.833 14.7501 15.083 14.0001 14.1663 14.0001Z" />
);
export const campaigns = (
  <path d="M12.1503 11.1999C12.1503 11.1999 14.5337 8.83327 15.342 7.99994C17.9003 5.49994 16.6253 0.349937 16.6253 0.349937C16.6253 0.349937 11.5003 -0.925062 9.00033 1.63327C7.05033 3.53327 5.78366 4.84994 5.78366 4.84994C5.78366 4.84994 2.58366 4.18327 0.666992 6.09994L10.8753 16.3333C12.792 14.3916 12.1503 11.1999 12.1503 11.1999ZM10.9003 3.69994C11.0551 3.54498 11.2389 3.42204 11.4413 3.33817C11.6436 3.2543 11.8605 3.21113 12.0795 3.21113C12.2985 3.21113 12.5154 3.2543 12.7177 3.33817C12.9201 3.42204 13.1039 3.54498 13.2587 3.69994C13.4916 3.9331 13.6501 4.23007 13.7143 4.55332C13.7784 4.87657 13.7453 5.21159 13.6191 5.51603C13.4929 5.82046 13.2793 6.08065 13.0053 6.2637C12.7312 6.44676 12.409 6.54446 12.0795 6.54446C11.7499 6.54446 11.4278 6.44676 11.1537 6.2637C10.8797 6.08065 10.6661 5.82046 10.5399 5.51603C10.4137 5.21159 10.3806 4.87657 10.4447 4.55332C10.5089 4.23007 10.6674 3.9331 10.9003 3.69994ZM1.50033 15.4999C3.04288 15.4559 4.51962 14.8652 5.66699 13.8333L3.16699 11.3333C1.50033 12.1666 1.50033 15.4999 1.50033 15.4999Z" />
);
export const marketplace = (
  <path d="M15.667 0.666748H2.33366C1.41699 0.666748 0.675326 1.41675 0.675326 2.33341L0.666992 17.3334L4.00033 14.0001H15.667C16.5837 14.0001 17.3337 13.2501 17.3337 12.3334V2.33341C17.3337 1.41675 16.5837 0.666748 15.667 0.666748ZM4.00033 10.6667V8.60841L9.73366 2.87508C9.90033 2.70841 10.1587 2.70841 10.3253 2.87508L11.8003 4.35008C11.967 4.51675 11.967 4.77508 11.8003 4.94175L6.05866 10.6667H4.00033ZM14.0003 10.6667H7.75033L9.41699 9.00008H14.0003V10.6667Z" />
);
export const broadcasts = (
  <path d="M16.5003 4.00008H14.8337V11.5001H4.00033V13.1667C4.00033 13.6251 4.37533 14.0001 4.83366 14.0001H14.0003L17.3337 17.3334V4.83341C17.3337 4.37508 16.9587 4.00008 16.5003 4.00008ZM13.167 9.00008V1.50008C13.167 1.04175 12.792 0.666748 12.3337 0.666748H1.50033C1.04199 0.666748 0.666992 1.04175 0.666992 1.50008V13.1667L4.00033 9.83341H12.3337C12.792 9.83341 13.167 9.45841 13.167 9.00008Z" />
);
export const news = (
  <path d="M16.5003 4.00008H14.8337V11.5001H4.00033V13.1667C4.00033 13.6251 4.37533 14.0001 4.83366 14.0001H14.0003L17.3337 17.3334V4.83341C17.3337 4.37508 16.9587 4.00008 16.5003 4.00008ZM13.167 9.00008V1.50008C13.167 1.04175 12.792 0.666748 12.3337 0.666748H1.50033C1.04199 0.666748 0.666992 1.04175 0.666992 1.50008V13.1667L4.00033 9.83341H12.3337C12.792 9.83341 13.167 9.45841 13.167 9.00008Z" />
);
export const events = (
  <path d="M16.5003 4.00008H14.8337V11.5001H4.00033V13.1667C4.00033 13.6251 4.37533 14.0001 4.83366 14.0001H14.0003L17.3337 17.3334V4.83341C17.3337 4.37508 16.9587 4.00008 16.5003 4.00008ZM13.167 9.00008V1.50008C13.167 1.04175 12.792 0.666748 12.3337 0.666748H1.50033C1.04199 0.666748 0.666992 1.04175 0.666992 1.50008V13.1667L4.00033 9.83341H12.3337C12.792 9.83341 13.167 9.45841 13.167 9.00008Z" />
);
export const surveys = (
  <path d="M16.5003 4.00008H14.8337V11.5001H4.00033V13.1667C4.00033 13.6251 4.37533 14.0001 4.83366 14.0001H14.0003L17.3337 17.3334V4.83341C17.3337 4.37508 16.9587 4.00008 16.5003 4.00008ZM13.167 9.00008V1.50008C13.167 1.04175 12.792 0.666748 12.3337 0.666748H1.50033C1.04199 0.666748 0.666992 1.04175 0.666992 1.50008V13.1667L4.00033 9.83341H12.3337C12.792 9.83341 13.167 9.45841 13.167 9.00008Z" />
);
export const manageDepartments = (
  <path d="M6.99967 6.99992C8.84134 6.99992 10.333 5.50825 10.333 3.66659C10.333 1.82492 8.84134 0.333252 6.99967 0.333252C5.15801 0.333252 3.66634 1.82492 3.66634 3.66659C3.66634 5.50825 5.15801 6.99992 6.99967 6.99992ZM6.99967 8.66659C4.77467 8.66659 0.333008 9.78325 0.333008 11.9999V13.6666H13.6663V11.9999C13.6663 9.78325 9.22467 8.66659 6.99967 8.66659Z" />
);
export const managePartners = (
  <path
    d="M13.333 9.16666C14.7163 9.16666 15.8247 8.04999 15.8247 6.66666C15.8247 5.28332 14.7163 4.16666 13.333 4.16666C11.9497 4.16666 10.833 5.28332 10.833 6.66666C10.833 8.04999 11.9497 9.16666 13.333 9.16666ZM6.66634 9.16666C8.04967 9.16666 9.15801 8.04999 9.15801 6.66666C9.15801 5.28332 8.04967 4.16666 6.66634 4.16666C5.28301 4.16666 4.16634 5.28332 4.16634 6.66666C4.16634 8.04999 5.28301 9.16666 6.66634 9.16666ZM6.66634 10.8333C4.72467 10.8333 0.833008 11.8083 0.833008 13.75V15.8333H12.4997V13.75C12.4997 11.8083 8.60801 10.8333 6.66634 10.8333ZM13.333 10.8333C13.0913 10.8333 12.8163 10.85 12.5247 10.875C13.4913 11.575 14.1663 12.5167 14.1663 13.75V15.8333H19.1663V13.75C19.1663 11.8083 15.2747 10.8333 13.333 10.8333Z"
    fill="#D1D1D1"
  />
);
export const manageEntities = (
  <path d="M13.333 5.16675C14.7163 5.16675 15.8247 4.05008 15.8247 2.66675C15.8247 1.28341 14.7163 0.166748 13.333 0.166748C11.9497 0.166748 10.833 1.28341 10.833 2.66675C10.833 4.05008 11.9497 5.16675 13.333 5.16675ZM6.66634 5.16675C8.04967 5.16675 9.15801 4.05008 9.15801 2.66675C9.15801 1.28341 8.04967 0.166748 6.66634 0.166748C5.28301 0.166748 4.16634 1.28341 4.16634 2.66675C4.16634 4.05008 5.28301 5.16675 6.66634 5.16675ZM6.66634 6.83342C4.72467 6.83342 0.833008 7.80842 0.833008 9.75008V11.8334H12.4997V9.75008C12.4997 7.80842 8.60801 6.83342 6.66634 6.83342ZM13.333 6.83342C13.0913 6.83342 12.8163 6.85008 12.5247 6.87508C13.4913 7.57508 14.1663 8.51675 14.1663 9.75008V11.8334H19.1663V9.75008C19.1663 7.80842 15.2747 6.83342 13.333 6.83342Z" />
);
export const manageUsers = (
  <path d="M6.99967 6.99992C8.84134 6.99992 10.333 5.50825 10.333 3.66659C10.333 1.82492 8.84134 0.333252 6.99967 0.333252C5.15801 0.333252 3.66634 1.82492 3.66634 3.66659C3.66634 5.50825 5.15801 6.99992 6.99967 6.99992ZM6.99967 8.66659C4.77467 8.66659 0.333008 9.78325 0.333008 11.9999V13.6666H13.6663V11.9999C13.6663 9.78325 9.22467 8.66659 6.99967 8.66659Z" />
);
export const manageLeads = (
  <path
    d="M17.4997 4.16666V15.8333H19.1663V4.16666H17.4997ZM14.1663 15.8333H15.833V4.16666H14.1663V15.8333ZM11.6663 4.16666H1.66634C1.20801 4.16666 0.833008 4.54166 0.833008 4.99999V15C0.833008 15.4583 1.20801 15.8333 1.66634 15.8333H11.6663C12.1247 15.8333 12.4997 15.4583 12.4997 15V4.99999C12.4997 4.54166 12.1247 4.16666 11.6663 4.16666ZM6.66634 6.45832C7.69967 6.45832 8.54134 7.29999 8.54134 8.33332C8.54134 9.36666 7.69967 10.2083 6.66634 10.2083C5.63301 10.2083 4.79134 9.36666 4.79134 8.33332C4.79134 7.29999 5.63301 6.45832 6.66634 6.45832ZM10.4163 14.1667H2.91634V13.5417C2.91634 12.2917 5.41634 11.6667 6.66634 11.6667C7.91634 11.6667 10.4163 12.2917 10.4163 13.5417V14.1667Z"
    fill="#D1D1D1"
  />
);
export const manageSettingsAndPermissions = (
  <>
    <path
      d="M14.1667 9.16667C14.45 9.16667 14.725 9.2 15 9.24167V5.225L8.75 2.5L2.5 5.225V9.31667C2.5 13.1 5.16667 16.6417 8.75 17.5C9.20833 17.3917 9.65 17.2333 10.0833 17.0417C9.50833 16.225 9.16667 15.2333 9.16667 14.1667C9.16667 11.4083 11.4083 9.16667 14.1667 9.16667Z"
      fill="#D1D1D1"
    />
    <path
      d="M14.1663 10.8333C12.3247 10.8333 10.833 12.325 10.833 14.1667C10.833 16.0083 12.3247 17.5 14.1663 17.5C16.008 17.5 17.4997 16.0083 17.4997 14.1667C17.4997 12.325 16.008 10.8333 14.1663 10.8333ZM14.1663 11.9833C14.683 11.9833 15.0997 12.4083 15.0997 12.9167C15.0997 13.425 14.6747 13.85 14.1663 13.85C13.658 13.85 13.233 13.425 13.233 12.9167C13.233 12.4083 13.6497 11.9833 14.1663 11.9833ZM14.1663 16.4583C13.3913 16.4583 12.7163 16.075 12.2997 15.4833C12.3413 14.8833 13.558 14.5833 14.1663 14.5833C14.7747 14.5833 15.9913 14.8833 16.033 15.4833C15.6163 16.075 14.9413 16.4583 14.1663 16.4583Z"
      fill="#D1D1D1"
    />
  </>
);
export const manageSecurity = (
  <>
    <path
      d="M10 0.832031L2.5 4.16536V9.16536C2.5 13.7904 5.7 18.1154 10 19.1654C14.3 18.1154 17.5 13.7904 17.5 9.16536V4.16536L10 0.832031ZM10 9.99036H15.8333C15.3917 13.4237 13.1 16.482 10 17.4404V9.9987H4.16667V5.2487L10 2.65703V9.99036Z"
      fill="white"
    />
  </>
);
export const managePermissions = (
  <>
    <path d="M12.1667 7.16667C12.45 7.16667 12.725 7.2 13 7.24167V3.225L6.75 0.5L0.5 3.225V7.31667C0.5 11.1 3.16667 14.6417 6.75 15.5C7.20833 15.3917 7.65 15.2333 8.08333 15.0417C7.50833 14.225 7.16667 13.2333 7.16667 12.1667C7.16667 9.40833 9.40833 7.16667 12.1667 7.16667Z" />
    <path d="M12.1663 8.83325C10.3247 8.83325 8.83301 10.3249 8.83301 12.1666C8.83301 14.0083 10.3247 15.4999 12.1663 15.4999C14.008 15.4999 15.4997 14.0083 15.4997 12.1666C15.4997 10.3249 14.008 8.83325 12.1663 8.83325ZM12.1663 9.98325C12.683 9.98325 13.0997 10.4083 13.0997 10.9166C13.0997 11.4249 12.6747 11.8499 12.1663 11.8499C11.658 11.8499 11.233 11.4249 11.233 10.9166C11.233 10.4083 11.6497 9.98325 12.1663 9.98325ZM12.1663 14.4583C11.3913 14.4583 10.7163 14.0749 10.2997 13.4833C10.3413 12.8833 11.558 12.5833 12.1663 12.5833C12.7747 12.5833 13.9913 12.8833 14.033 13.4833C13.6163 14.0749 12.9413 14.4583 12.1663 14.4583Z" />
  </>
);
export const organisationSettings = (
  <>
    <path d="M12.1667 7.16667C12.45 7.16667 12.725 7.2 13 7.24167V3.225L6.75 0.5L0.5 3.225V7.31667C0.5 11.1 3.16667 14.6417 6.75 15.5C7.20833 15.3917 7.65 15.2333 8.08333 15.0417C7.50833 14.225 7.16667 13.2333 7.16667 12.1667C7.16667 9.40833 9.40833 7.16667 12.1667 7.16667Z" />
    <path d="M12.1663 8.83325C10.3247 8.83325 8.83301 10.3249 8.83301 12.1666C8.83301 14.0083 10.3247 15.4999 12.1663 15.4999C14.008 15.4999 15.4997 14.0083 15.4997 12.1666C15.4997 10.3249 14.008 8.83325 12.1663 8.83325ZM12.1663 9.98325C12.683 9.98325 13.0997 10.4083 13.0997 10.9166C13.0997 11.4249 12.6747 11.8499 12.1663 11.8499C11.658 11.8499 11.233 11.4249 11.233 10.9166C11.233 10.4083 11.6497 9.98325 12.1663 9.98325ZM12.1663 14.4583C11.3913 14.4583 10.7163 14.0749 10.2997 13.4833C10.3413 12.8833 11.558 12.5833 12.1663 12.5833C12.7747 12.5833 13.9913 12.8833 14.033 13.4833C13.6163 14.0749 12.9413 14.4583 12.1663 14.4583Z" />
  </>
);
export const faq = (
  <path d="M16.5003 4.00008H14.8337V11.5001H4.00033V13.1667C4.00033 13.6251 4.37533 14.0001 4.83366 14.0001H14.0003L17.3337 17.3334V4.83341C17.3337 4.37508 16.9587 4.00008 16.5003 4.00008ZM13.167 9.00008V1.50008C13.167 1.04175 12.792 0.666748 12.3337 0.666748H1.50033C1.04199 0.666748 0.666992 1.04175 0.666992 1.50008V13.1667L4.00033 9.83341H12.3337C12.792 9.83341 13.167 9.45841 13.167 9.00008Z" />
);

export const tc = (
  <path d="M13.8333 2.49992H10.35C10 1.53325 9.08333 0.833252 8 0.833252C6.91667 0.833252 6 1.53325 5.65 2.49992H2.16667C1.25 2.49992 0.5 3.24992 0.5 4.16659V15.8333C0.5 16.7499 1.25 17.4999 2.16667 17.4999H13.8333C14.75 17.4999 15.5 16.7499 15.5 15.8333V4.16659C15.5 3.24992 14.75 2.49992 13.8333 2.49992ZM8 2.49992C8.45833 2.49992 8.83333 2.87492 8.83333 3.33325C8.83333 3.79159 8.45833 4.16659 8 4.16659C7.54167 4.16659 7.16667 3.79159 7.16667 3.33325C7.16667 2.87492 7.54167 2.49992 8 2.49992ZM9.66667 14.1666H3.83333V12.4999H9.66667V14.1666ZM12.1667 10.8333H3.83333V9.16658H12.1667V10.8333ZM12.1667 7.49992H3.83333V5.83325H12.1667V7.49992Z" />
);
export const marketPlace = (
  <path
    d="M16.6667 3.33337H3.33333V5.00004H16.6667V3.33337ZM17.5 11.6667V10L16.6667 5.83337H3.33333L2.5 10V11.6667H3.33333V16.6667H11.6667V11.6667H15V16.6667H16.6667V11.6667H17.5ZM10 15H5V11.6667H10V15Z"
    fill="#D1D1D1"
  />
);
