import Bugsnag from '@bugsnag/js';
import { CLIENTS } from '../../constants';
import Utils from '../../Utils';
import Logger from './Logger';
import secureStorage from './SecureStorage';
import Unify from './Unify';

const logger = new Logger('User');
const getUserIdMapping = Utils.getUserIDMapping();

const AG_UUID = '00000000-0000-0000-0000-000000000001';
const NSC_UUID = '00000000-0000-0000-0000-000000000002';

//fixed

/**
 *
 */
class User {
  _fetched = false;

  _profile = {
    name: '',
    email: 'nobody@unify.eco',
    avatar: 'assets/img/avatars/default.png',
    mobile: '',
  };

  _notifications = [];

  _permissions = {};

  _todoIndex = 0;
  _todo = [
    // {
    //   id: 1,
    //   type: 'danger',
    //   icon: 'cui-star',
    //   message: 'TOKEN Request',
    //   link: '#',
    //   status: 'Pending Approval',
    //   date: '1 Jan 2019',
    // },
    // {
    //   id: 2,
    //   type: 'success',
    //   icon: 'cui-circle-check',
    //   message: 'TOKEN Request',
    //   link: '#',
    //   status: 'Approved',
    //   date: '1 Jan 2019',
    // },
  ];

  // role = {
  //   entity_key: 'KR',
  //   entity_name: 'UNIFY HQ',
  //   entity_image: 'assets/demo/logo.png',
  // };

  // Stores the users role information
  _role = null;

  constructor() {
    try {
      const _profile = secureStorage.getItem('_profile');
      if (_profile) {
        // const cachedProfile = JSON.parse(sessionStorage.getItem('_profile'));
        this._profile = Object.assign({}, this._profile, _profile);
      }
    } catch (ignore) {}
    try {
      if ('_role' in window.sessionStorage) {
        if (window.sessionStorage['_role'] === 'KR') {
          this.switchToNSC();
        }
        if (window.sessionStorage['_role'] === 'PFSXQERX') {
          this.switchToPartner();
        }
      }
    } catch (ignore) {}
  }

  getClients() {
    return CLIENTS;
  }

  getClient() {
    const entry = CLIENTS.filter((c) => c.id === this.getClientId());
    if (entry && entry.length > 0) {
      return entry[0];
    } else {
      return null;
    }
  }

  getClientId() {
    return Unify.getClient();
  }

  setClientId(clientId) {
    return Unify.setClient(clientId);
  }

  /**
   * Get the users roll
   * @returns {{entity_key: string, entity_name: string, entity_image: string}|null}
   */
  get role() {
    const tokenData = Unify.getTokenData();
    if (tokenData) {
      let entity_key = tokenData['entity'];
      if (entity_key === AG_UUID || entity_key === 'AG') {
        entity_key = 'GLOBAL';
      } else if (entity_key === NSC_UUID) {
        entity_key = 'KR';
      }
      const permissionsArray = Utils.transformToV1Permissions(tokenData['permissions']);
      const permissionsObject = {}; // for O(1) retrieval
      for (const perm of permissionsArray) permissionsObject[perm] = true;

      this._role = {
        type: tokenData['role'],
        entity_key: entity_key.toUpperCase(),
        entity_id: tokenData['entity_id'],
        entity_type: tokenData['entity_type'],
        entity_name: tokenData['entity_name'],
        entity_image: tokenData['entity_image'],
        // TODO: switch all v1 permissions to cater for v2 permissions
        // this is just a hotfix
        // permissions: tokenData['permissions'],
        permissions: permissionsArray,
        permissionsObject,
      };
    } else {
      // We need to fallback to something for the moment
      return {
        entity_key: '',
        entity_id: '',
        entity_name: '',
        entity_image: 'assets/demo/logo.png',
        permissions: [],
        permissionsObject: {},
      };
    }
    return this._role;
    /*
    if (!this._role) {
      // Extract the role information from the token
      const tokenData = Unify.getTokenData();
      if (tokenData) {
        let entity_key = tokenData['entity'];
        if (entity_key === AG_UUID || entity_key === 'AG') {
          entity_key = 'GLOBAL';
        } else if (entity_key === NSC_UUID) {
          entity_key = 'KR';
        }
        const permissionsArray = Utils.transformToV1Permissions(
          tokenData['permissions'],
        );
        const permissionsObject = {}; // for O(1) retrieval
        for (const perm of permissionsArray) permissionsObject[perm] = true;

        this._role = {
          type: tokenData['role'],
          entity_key: entity_key.toUpperCase(),
          entity_id: tokenData['entity_id'],
          entity_type: tokenData['entity_type'],
          entity_name: tokenData['entity_name'],
          entity_image: tokenData['entity_image'],
          // TODO: switch all v1 permissions to cater for v2 permissions
          // this is just a hotfix
          // permissions: tokenData['permissions'],
          permissions: permissionsArray,
          permissionsObject,
        };
      }
    }
    if (this._role) {
      return this._role;
    } else {
      // We need to fallback to something for the moment
      return {
        entity_key: 'KR',
        entity_id: NSC_UUID,
        entity_name: 'UNIFY HQ',
        entity_image: 'assets/demo/logo.png',
        permissions: [],
        permissionsObject: {},
      };
    }
    */
  }

  /**
   * Checks if user has the required permissions
   * @param {PERMISSION_TYPES} permission permission type
   */
  hasPermission = (permission) => {
    if (!this._role) return false;
    const permissionsObject = typeof this._role.permissionsObject === 'object' ? this._role.permissionsObject : {};
    return !!permissionsObject[permission];
  };

  // To use with new permissions structure
  hasPermissionv2 = (group, permission) => {
    if (!this._permissions || !this._permissions[group]) {
      return false;
    }

    return !!this._permissions[group][permission];
  };

  hasAnyPermissionv2 = (group, ...permissions) => {
    if (!this._permissions || !this._permissions[group]) {
      return false;
    }

    return permissions.reduce((result, permission) => result || !!this._permissions[group][permission], false);
  };

  isAdmin = () => {
    // return (
    //   this._profile.email === 'michael@unify.eco' ||
    //   this._profile.email === 'michaelcutler@kpmg.com.sg' ||
    //   this._profile.email === 'michael@cutler.io' ||
    //   this._profile.email === 'm@cutler.io' ||
    //   this._profile.email === 'michellekhor@kpmg.com.sg' ||
    //   this._profile.email.indexOf('michaelcutler') === 0
    // );
    return true;
  };

  isAG = () => {
    // return (
    //   this.role.entity_key === 'GLOBAL' || this.role.entity_key === AG_UUID
    // );
    return false;
  };

  isNSC = () => {
    // return (
    //   this.role.entity_key === 'KR' ||
    //   this.role.entity_key === NSC_UUID ||
    //   this.role.entity_type === 'nsc'
    // );
    return true;
  };

  isDepartment = () => {
    return this.role.entity_type === 'department';
  };

  isPartner = () => {
    return this.role.entity_type === 'partner';
  };

  isEntity = () => {
    return this.role.entity_type === 'entity';
  };

  getEntityId = () => {
    return this.role.entity_id;
  };

  reset = () => {
    this._profile.name = '';
    this._profile.mobile = '';
    this._role = null;
    this._fetched = false;
  };

  /**
   * Override the property to kick off the async fetch
   *
   * @returns {{name: string, avatar: string, email: string}}
   */
  get profile() {
    const tokenData = Unify.getTokenData();

    // Get the user_id
    try {
      this._profile.user_id = tokenData['user_id'];
    } catch (e) {}

    // Get the email
    try {
      this._profile.email = tokenData['email'];
    } catch (e) {}

    // Get their role
    if (tokenData !== null) {
      if (typeof tokenData['entity'] !== 'undefined') {
        if (tokenData['entity'] !== 'AG' && tokenData['entity'] !== 'KR') {
          try {
            // this.role.entity_key = tokenData['entity'];
            // this.role.entity_name = 'TODO';
            // this.role.entity_image = '';
            // console.log('entity:', this.role, this.isPartner());
          } catch (e) {}
        }
      }
    }

    // Fetch their profile
    if (this._fetched === false) {
      this._fetched = true;
      this.getProfile().then((profile) => {
        this._profile = Object.assign({}, this._profile, profile);
        if (typeof this._profile.name === 'undefined' || this._profile.name === '') {
          this._profile.name = profile['display_name'];
        }

        // Permissions
        const permissions = [];
        Object.keys(this._profile)
          .filter((key) => key.indexOf('manage') === 0)
          .forEach((key) => {
            if (this._profile[key] === true || this._profile[key] === 'enabled') {
              const permission = key
                .replace(/\.?([A-Z]+)/g, function (x, y) {
                  return '_' + y.toLowerCase();
                })
                .replace(/^_/, '');
              permissions.push(permission);
            }
          });
        this._profile.permissions = permissions;

        //
        if (typeof this._profile.entity === 'undefined') {
          this._profile.entity = 'GLOBAL';
        }
        if (this._profile.entity === 'UNIFY HQ') {
          this._profile.entity = 'KR';
        }
        if (typeof this._profile.role === 'undefined') {
          this._profile.role = 'administrator';
        }

        // eslint-disable-next-line no-console
        console.log('profile:', this._profile);
        // eslint-disable-next-line no-console
        console.log('role:', this.role);

        // Cache the profile
        try {
          // window.sessionStorage.setItem(
          //   '_profile',
          //   JSON.stringify(this._profile),
          // );
          secureStorage.setItem('_profile', this._profile);
        } catch (ignore) {}
        this._trackUser();
      });
    }

    // Update the cache
    try {
      // window.sessionStorage.setItem('_profile', JSON.stringify(this._profile));
      // secureStorage.setItem('_profile', this._profile);
    } catch (ignore) {}

    return this._profile;
  }

  /**
   *
   * @param permission
   * @returns {boolean}
   */
  can = (permission) => {
    // Temporarily allow if no permissions are set
    if (typeof this._profile.permissions !== 'object') {
      return true;
    }
    if (!Array.isArray(this._profile.permissions)) {
      return true;
    }
    if (this._profile.permissions.length === 0) {
      return true;
    }
    // Check the specified permission
    if (this._profile.permissions.includes(permission)) {
      return true;
    }
    // Check the root permission
    if (permission.match(/_/g).length > 1) {
      const parts = permission.split(/_/);
      const root_permission = parts[0] + '_' + parts[1];
      if (this._profile.permissions.includes(root_permission)) {
        return true;
      }
    }
    return false;
  };

  /**
   *
   * @returns {{icon: string, link: string, id: number, type: string, message: string}[]}
   */
  get notifications() {
    return this._notifications;
  }

  /**
   *
   * @returns {*[]}
   */
  get todo() {
    return this._todo;
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getACL() {
    return new Promise((resolve, reject) => {
      Unify._get('/api/admin/acl')
        .then((result) => {
          if (result.status === 'OK' && typeof result.acl !== 'undefined' && result.acl !== null) {
            resolve(result.acl);
          }
        })
        .catch((error) => {
          resolve({});
        });
    });
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getProfile() {
    return Unify._get('/api/admin/crm/profile');
  }

  setLoginProfile(profile) {
    this._profile = Object.assign({}, this._profile, profile);
    this._fetched = false;
  }

  /**
   *
   * @param profile
   * @returns {Promise<any|never>}
   */
  async setProfile(profile) {
    this._profile = Object.assign({}, this._profile, profile);
    this._fetched = false;
    return Unify._put('/api/admin/crm/profile', this._profile);
  }

  async updateProfilePreferences(profile) {
    return Unify._put('/api/admin/crm/profile', profile);
  }

  /**
   *
   * @param profile
   * @returns {Promise<any|never>}
   */
  async updateProfile(partialProfile) {
    return Unify._patch('/api/admin/crm/profile', partialProfile);
  }

  /**
   *
   * @param otp
   * @param mobile
   * @returns {Promise<any|never>}
   */
  async requestOtp(otp, mobile) {
    return Unify._post('/api/bmw/otp', {
      otp,
      mobile,
    });
  }

  /**
   *
   * @param item
   * @returns {Promise<void>}
   */
  async addTodo(item) {
    this._todo.push(
      Object.assign(
        {
          id: this._todoIndex++,
          type: 'danger',
          icon: 'cui-star',
          message: 'TOKEN Request',
          link: '#',
          status: 'Pending Approval',
          date: new Date(),
        },
        item
      )
    );
  }

  async deleteTodo(id) {
    this._todo = this._todo.filter((e) => e['id'] !== id);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async request2FA(body = {}) {
    return Unify._post('/api/auth/admin/Request2FA', body);
  }

  /**
   *
   * @param token
   * @returns {Promise<any|never>}
   */
  async respond2FA(token) {
    return Unify._post('/api/auth/admin/Respond2FA', { token });
  }

  /**
   *
   */
  switchToAG() {
    this._role = {
      entity_key: 'GLOBAL',
      entity_name: 'UNIFY HQ',
      entity_image: 'assets/demo/logo.png',
    };
    // try {
    //   window.sessionStorage['_role'] = 'GLOBAL';
    // } catch (ignore) {}
    this._trackUser();
  }

  /**
   *
   */
  switchToNSC() {
    this._role = {
      entity_key: 'KR',
      entity_name: 'UNIFY HQ',
      entity_image: 'assets/demo/logo.png',
    };
    // try {
    //   window.sessionStorage['_role'] = 'KR';
    // } catch (ignore) {}
    this._trackUser();
  }

  /**
   *
   */
  switchToPartner() {
    this._role = {
      entity_key: '18438fd8-f49a-4129-b840-4a404b62ad90', // 'PFSXQERX',
      entity_name: 'Bavarian', // 'BMW Driving Centre',', // 'PFSXQERX',
      entity_image: 'assets/demo/logo.png',
    };
    // try {
    //   window.sessionStorage['_role'] = 'PFSXQERX';
    // } catch (ignore) {}
    this._trackUser();
  }

  /**
   *
   * @private
   */
  _trackUser() {
    try {
      const data = Unify.getTokenData();
      if ('user_id' in data) {
        Bugsnag.setUser(data.user_id, data.email, this._profile.name);
      }
    } catch (ignore) {}
  }

  logout(isDarkTheme = false) {
    secureStorage.removeItem('_profile');
    try {
      localStorage.clear();
      localStorage.setItem('mode', isDarkTheme);
    } catch (ignore) {}
    try {
      sessionStorage.clear();
    } catch (ignore) {}
    Unify._token = null;
  }
}

export default new User();
