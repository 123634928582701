import merge from 'lodash/merge';
import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import * as ActionTypes from '../actions';
import { Admin, Analytics } from '../services/Unify';
import analytics from './analytics';
import coins from './coins';
import crm from './crm';
import event from './event';
import leads from './leads';
import partners from './partners';
import rewards from './rewards';
import services from './services';
import user from './user';

// Updates an entity cache in response to any action with response.entities.
const entities = (state = { users: {}, balance: {} }, action) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  return state;
};

// Updates an entity cache in response to any action with response.entities.
const accounts = (state = { balance: {} }, action) => {
  switch (action.type) {
    case 'ACCOUNT_BALANCE_UPDATED': {
      const payload = action.payload;
      if (payload.balance !== '') {
        return Object.assign({}, state, {
          balance: Object.assign({}, state.balance, {
            [payload.account]: parseInt(payload.balance),
          }),
        });
      }
      return state;
    }
    case 'ANALYTICS_UPDATE': {
      const payload = action.payload;

      if (payload.accounts) {
        if (payload.accounts.balance) {
          const updates = Object.entries(payload.accounts.balance).map((e) => {
            const key = e[0];
            const val = parseInt(e[1]);

            // Workaround
            if (key === 'starbucks') {
              Admin.details['PK3WKXO2'].balance = val;
              Analytics.country.STARBUCKS.coins_balance = val;
            }

            return { [key]: val };
          });
          if (updates.length > 0) {
            return Object.assign({}, state, {
              balance: Object.assign({}, state.balance, ...updates),
            });
          }
        }
      }

      return state;
    }
    default:
      return state;
  }
};

// Special cases where we want to reduce across the root, syncing things between child reduces
const root = (state = {}, action) => {
  switch (action.type) {
    // Update the balance displayed in any CRM listings
    case 'ACCOUNT_BALANCE_UPDATED': {
      const payload = action.payload;
      const account = payload.account;
      const balance = payload.balance;
      if (state.crm) {
        if (state.crm.list) {
          if (state.crm.list.length > 0) {
            state.crm.list = state.crm.list.map((e) => {
              if (e.id === account) {
                e.balance = balance ? parseInt(balance) : 0;
              }
              return e;
            });
          }
        }
      }
      return state;
    }
    // Update multiple balances displayed in CRM listings
    case 'ANALYTICS_UPDATE': {
      const payload = action.payload;
      if (payload.accounts) {
        if (payload.accounts.balance) {
          const balance = payload.accounts.balance;
          if (state.crm) {
            if (state.crm.list) {
              if (state.crm.list.length > 0) {
                state.crm.list = state.crm.list.map((e) => {
                  if (typeof balance[e.id] !== 'undefined') {
                    if (balance[e.id] > 0) {
                      e.balance = balance[e.id];
                    }
                  }
                  return e;
                });
              }
            }
          }
        }
      }
      return state;
    }
    default:
      return state;
  }
};

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action;
  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return error;
  }
  return state;
};

const rootReducer = reduceReducers(
  combineReducers({
    entities,
    accounts,
    event,
    errorMessage,
    rewards,
    partners,
    services,
    user,
    crm,
    analytics,
    coins,
    leads,
  }),
  root
);

export default rootReducer;
