import { call, put, takeLatest } from 'redux-saga/effects';
import { Admin } from '../services/Unify';

function* listServices(action) {
  try {
    const list = yield call(Admin.getServices);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* servicesSaga() {
  yield takeLatest('SERVICES_LIST', listServices);
}

export default servicesSaga;
