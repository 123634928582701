import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import _moment from 'moment';
import 'moment/locale/en-sg';
import 'moment/locale/ko';
import _numeral from 'numeral';
import 'numeral/locales';
import React from 'react';
import { initReactI18next, withTranslation } from 'react-i18next';
import config from '../config';
import Backend from './backend';

//
// i18next
//

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    Object.assign({}, config.i18n, {
      lng: localStorage.getItem('_lang') || config.i18n.lng,
    })
  );

i18n.addResourceBundle('en', 'translation', require('../locales/en'));
i18n.addResourceBundle('ko', 'translation', require('../locales/ko'));

i18n.on('languageChanged', function (lng) {
  if (localStorage) {
    localStorage.setItem('_lang', lng);
  }
  _moment.locale(lng);
  // _numeral.locale(lng);
});

//
// Utilities
//

// Default implementation
// const moment = (...args) => {
//   _moment.locale(i18n.lng);
//   return _moment(...args);
// };
const moment = _moment;

// Default implementation
const numeral = (...args) => {
  _numeral.locale(i18n.lng);
  return _numeral(...args);
};

const Context = React.createContext();

// create Context.Provider : the value prop is our toolbox
const LocalizationProvider = (props) => {
  return (
    <Context.Provider
      value={{
        moment: props.moment ? props.moment : moment,
        numeral: props.numeral ? props.numeral : numeral,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

// a higher order component to save us the hassle of calling Context.Consumer
const withLocalization = (Component) => {
  return withTranslation()(function LocalizedComponent(props) {
    return (
      <Context.Consumer>
        {(context) => {
          if (!context) {
            context = {
              moment,
              numeral,
            };
          }
          return <Component {...props} moment={context.moment} numeral={context.numeral} />;
        }}
      </Context.Consumer>
    );
  });
};

//
// Exports
//

export default i18n;
export { LocalizationProvider, withLocalization, withTranslation, moment, numeral };
