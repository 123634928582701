import { debounce } from 'lodash';
import Auth from './Auth';
import CMS from './CMS';
import Coins from './Coins';
import CRM from './CRM';
import Logger from './Logger';
import Unify from './Unify';
import User from './User';

const logger = new Logger('Admin');

/**
 *
 */
class Admin {
  constructor() {
    this.service_bookings = [];
    this.partners = [];
    this.details = [];

    this.generateInitialData();
  }

  generateInitialData() {
    // Defer startup if the user is not logged in
    if (!Auth.isAuthenticated()) {
      return setTimeout(() => {
        this.generateInitialData();
      }, 1000);
    }

    // TODO: CURRENTLY USELESS API - Nothing use Admin.customers
    // this.getData('customers').then((data) => {
    //   this.customers = data;
    // });

    // TODO: CURRENTLY USELESS API - RETURNING 404 Not Found
    // this.getData('services').then((data) => {
    //   this.service_bookings = data;
    // });

    // TODO: CURRENTLY USELESS API - RETURNING 404 Not Found
    // this.getData('details').then((data) => {
    //   if (data) {
    //     const details = {};
    //     for (let i = 0; i < data.length; i++) {
    //       const entry = data[i];
    //       details[entry.id] = entry;
    //     }
    //     this.details = details;
    //     // this.partners.push(this.details['PK3WKXO2']);
    //     const partners = Object.keys(this.details).filter((e) => e.charAt(0) === 'P');
    //     partners.forEach((e) => {
    //       this.partners.push(this.details[e]);
    //     });
    //   }
    // });

    // this.customers = Dummy.generateCustomers();
    // this.service_bookings = Dummy.generateServices();
    // this.details = Dummy.generateDetails();
    // this.partners.push(this.details['STARBUCKS']);
  }

  async getProfiles(id) {
    return Unify._get('/api/admin/crm/profiles?id=' + encodeURIComponent(id));
  }

  /**
   *
   * @param func
   * @param list
   * @returns {Promise<void>}
   */
  async fetchProfiles(func, list) {
    this.fetchProfilesAll = this.fetchProfilesAll || new Set();
    this.fetchProfilesList = this.fetchProfilesList || new Set();
    list.forEach((item) => {
      if (!this.fetchProfilesAll.has(item)) {
        this.fetchProfilesList.add(item);
        this.fetchProfilesAll.add(item);
      }
    });
    debounce(() => {
      if (this.fetchProfilesList.size > 0) {
        const keys = [...this.fetchProfilesList];
        this.fetchProfilesList = new Set();
        const batches = this.chunk(keys, 50);
        if (batches.length > 0) {
          for (const batch of batches) {
            func(batch);
          }
        }
      }
    }, 250)();
  }

  /**
   *
   * @param array
   * @param size
   * @returns {[]}
   */
  chunk = (array, size) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  };

  async onboardPartner(email) {
    return Unify._post('/api/admin/onboarding/partner', {
      email,
    });
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getOnboardingPartner(id) {
    return Unify._get(`/api/admin/onboarding/partner/${id}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getOnboardingPartnerTnc(id) {
    return Unify._get('/api/cms/partner/tnc');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async patchOnboardingPartner(id, data) {
    return Unify._patch(`/api/admin/onboarding/partner/${id}`, data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async confirmOnboardingPartner(id, data) {
    return Unify._post(`/api/admin/onboarding/partner/${id}/confirm`, data);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getOrganizationSettings() {
    return Unify._get('/api/admin/settings/organization-' + User.role.entity_key.toLowerCase());
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async setOrganizationSettings(settings) {
    return Unify._put('/api/admin/settings/organization-' + User.role.entity_key.toLowerCase(), settings);
  }

  async getSettings(key, subKey) {
    return Unify._get(`/api/admin/settings/${key}/${subKey}`);
  }

  async setSettings(key, subKey, settings) {
    return Unify._post(`/api/admin/settings/${key}/${subKey}`, settings);
  }

  async getSettingMaintenance() {
    return Unify._get('/api/admin/settings/app/maintenance/mode');
  }

  async updateSettingMaintenance(settings) {
    return Unify._patch('/api/admin/settings/app/maintenance/mode', settings);
  }

  async deleteSettings(key, subKey, id) {
    return Unify._delete(`/api/admin/settings/${key}/${subKey}/${id}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getDetails(id) {
    // const result = this.details[id];
    // return new Promise((resolve, reject) => {
    //   resolve(result);
    // });
    if (id.startsWith('P')) {
      return CRM.get('partner', id);
    } else {
      return CRM.get('nsc', id);
    }
  }

  async checkAdminUserExists(email) {
    return Unify._post('/api/admin/crm/admin_user/exists', { email });
  }

  async addPartner(partner) {
    return Unify._post('/api/admin/crm/partner', partner);
  }

  async getPartnerDetails(id) {
    return CRM.get('partner', id);
  }
  async getDepartmentDetails(id) {
    return CRM.get('department', id);
  }
  async setDetails(id, details) {
    // return new Promise((resolve, reject) => {
    //   this.details[id] = Object.assign({}, this.details[id], details);
    //   Unify._patch(`/api/admin/data/details/${id}`, details);
    //   resolve(this.details[id]);
    // });
    if (id.startsWith('P')) {
      return CRM.save('partner', id, details);
    } else {
      return CRM.save('nsc', id, details);
    }
  }

  async activatePartner(id, payload) {
    return Unify._post(`/api/admin/crm/partner/${id}/activate`, payload);
  }

  async deactivatePartner(id) {
    return Unify._post(`/api/admin/crm/deactivate/${id}`);
  }

  async setPartnerDetails(id, details) {
    return CRM.save('partner', id, details);
  }

  async setDepartmentDetails(id, details) {
    return CRM.save('department', id, details);
  }

  async getNscDetails(id) {
    return this.getDetails(id);
  }

  async setNscDetails(id, details) {
    // this.details[id] = Object.assign({}, this.details[id], details);
    // return Unify._patch(`/api/admin/data/details/${id}`, details);
    // return new Promise((resolve, reject) => {
    //   this.details[id] = Object.assign({}, this.details[id], details);
    //   Unify._patch(`/api/admin/data/details/${id}`, details);
    //   resolve(this.details[id]);
    // });
    if (id.startsWith('P')) {
      return CRM.save('partner', id, details);
    } else {
      return CRM.save('department', id, details);
    }
  }

  /**
   *
   * @param data
   * @returns {Promise<any|never>}
   */
  async uploadAsset(data) {
    return Unify._post('/api/admin/asset/upload', data);
  }

  /**
   *
   * @param data
   * @returns {Promise<any|never>}
   */
  async uploadOnboardingAsset(tokenId, data) {
    // if (data instanceof FormData) {
    //   data.append('uploaded_by', User.profile.id);
    //   data.append('role', User.role.entity_key);
    // }
    return Unify._post(`/api/admin/onboarding/partner/${tokenId}/upload`, data);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async listNews() {
    return CMS.list('news');
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getNews(id) {
    return CMS.get('news', id);
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async putNews(id, obj) {
    return CMS.save('news', id, obj);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getTier(id) {
    return CMS.get('tier', id);
  }

  async listTier() {
    return CMS.list('tiers');
  }

  /**
   *
   * @param settings
   * @returns {Promise<any|never>}
   */
  async updateTier(id, obj) {
    return CMS.save('tier', id, obj);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async deleteNews(id) {
    return CMS.delete('news', id);
  }

  /**
   *
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async updateNews(id, obj) {
    return CMS.update('news', id, obj);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getServices() {
    const key = 'services';
    return Unify._get(`/api/admin/data/${key}`);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getLeads() {
    return Unify._get('/api/customer/leads');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getLead(id) {
    return Unify._get(`/api/customer/leads/${id}`);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async postLeads(data) {
    return Unify._post('/api/customer/leads', data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async patchLeads(id, data) {
    return Unify._patch(`/api/customer/leads/${id}`, data);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async patchCustomerTiers(data) {
    return Unify._patch('/api/customer/customer-tiers', data);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getTiers() {
    return Unify._get('/api/customer/customer-tiers/list');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async deleteLead(id) {
    return Unify._delete(`/api/customer/leads/${id}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getService(id) {
    const key = 'services';
    return Unify._get(`/api/admin/data/${key}/${id}`);
  }

  /**
   *
   * @returns {Promise<*>}
   */
  getPartners = async () => {
    return new Promise((resolve, reject) => {
      resolve(this.partners);
    });
  };

  /**
   *
   * @param id
   * @returns {Promise<void>}
   */
  async getPartner(id) {
    return new Promise((resolve, reject) => {
      const result = this.partners.filter((e) => {
        return e['id'] === id;
      });
      resolve(result);
    });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async getCustomers() {
    // return new Promise((resolve, reject) => {
    //   resolve(this.customers);
    // });
    // return this.getData('customers').then(data => {
    //   this.customers = data;
    //   return data;
    // });
    return this.getData('customers');
  }

  /**
   *
   * @param email
   * @param role
   * @param entity
   * @returns {Promise<any|never>}
   */
  async inviteAdminUser(email, role, entity, profile) {
    return Unify._post('/api/admin/invite', {
      email,
      role,
      entity,
      profile,
    });
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getData(key, payload = {}) {
    return Unify._post(`/api/admin/data/${key}`, payload);
  }

  async getTransactionsData(type, payload = {}) {
    return Unify._post(`/api/admin/data/transactions/${type}`, payload);
  }

  async getAllProductsWithoutFilter(payload) {
    return Unify._post('/api/product/product/burn', payload);
  }

  async manageCustomer(payload) {
    return Unify._post('/api/admin/data/customers', payload);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getDataById(key, id) {
    return Unify._get(`/api/admin/data/${key}/${id}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async setDataById(key, id, data) {
    return Unify._put(`/api/admin/data/${key}/${id}`, data);
  }

  /**
   *
   * @param {string} key
   * @param {string} id
   * @param {object} data
   */
  setStatusById(key, id, data) {
    return Unify._put(`/api/admin/${key}/${id}/status`, data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async patchDataById(key, id, data) {
    return Unify._patch(`/api/admin/data/${key}/${id}`, data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async deleteDataById(key, id) {
    return Unify._delete(`/api/admin/data/${key}/${id}`);
  }

  /**
   *
   * @param key
   * @param id
   * @returns {Promise<any|never>}
   */
  async getAnalyticsById(key, id) {
    return Unify._get(`/api/admin/data/${key}/${id}/analytics`);
  }

  async getTransactionAnalytics(analyticsPayload) {
    const {
      dateFilter: { startDate, endDate },
      analyticFilterType,
    } = analyticsPayload;
    const queryString =
      analyticFilterType === '' || !analyticFilterType
        ? `?start_date=${startDate}&end_date=${endDate}`
        : `?start_date=${startDate}&end_date=${endDate}&type=${analyticFilterType}`;
    return Unify._get(`/api/admin/data/transactions/analytics${queryString}`);
  }

  /**
   *
   * @param key
   * @param id
   * @returns {Promise<any|never>}
   */
  async getTransactionsById(key, id) {
    return Unify._get(`/api/admin/data/${key}/${id}/transactions`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getTransactions(id) {
    return Unify._get(`/api/admin/tokens/transactions/${id}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async addTransaction(key, data) {
    return Unify._post(`/api/admin/tokens/transactions/${key}`, data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async getBalance(id) {
    return Coins.balance(id);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async transfer(from, to, value) {
    return Unify._get(`/api/admin/tokens/transfer?from=${from}&to=${to}&value=${value}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async mint(account, value) {
    return Unify._get(`/api/admin/tokens/mint?value=${value}`);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async inviteCustomer(invite) {
    return Unify._post('/api/admin/customer/invite', invite);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async sendCoinsToCustomer(customer_id, amount) {
    return Unify._get(`/api/admin/tokens/customer/${customer_id}/send?amount=${amount}`);
  }

  /**
   * Send a push notification to the given user
   * @param userId
   * @param title
   * @param body
   * @param link
   * @returns {Promise<*|SimpleCacheConfigurator.never>}
   */
  async sendMessage(userId, title, body, link) {
    return Unify._post(`/api/admin/neura/push/${userId}`, {
      userId,
      title,
      body,
      link,
    });
  }

  /**
   *
   * @param users
   * @param title
   * @param body
   * @param link
   * @returns {Promise<*>}
   */
  async sendMessages(users, title, body, link) {
    return Unify._post('/api/admin/customer/push', {
      users,
      title,
      body,
      link,
    });
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async DeleteCustomer(customer_id) {
    return Unify._delete(`/api/admin/data/customers/${customer_id}`);
  }

  async VoidReward(rewardId, data) {
    return Unify._post(`/api/admin/data/rewards/${rewardId}/refund`, data);
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async retrieveExchangeRate() {
    return Unify._get('/api/broker/coin');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async UpdateExchangeRate(rate) {
    return Unify._patch('/api/broker/coin', { rate });
  }

  async fetchAuditTrail(payload) {
    return Unify._post('/api/admin/audit/all', payload);
  }

  async fetchFraudManagement(payload) {
    return Unify._post('/api/admin/fraud/management/all', payload);
  }

  async editFraudManagement(id, payload) {
    return Unify._patch(`/api/admin/fraud/management/${id}`, payload);
  }

  async fetchTransactionsByFraudId(id, payload) {
    return Unify._post(`/api/admin/fraud/management/${id}/transactions`, payload);
  }
}

export default new Admin();
