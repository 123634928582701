import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = (preloadedState) => {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, preloadedState, compose(applyMiddleware(sagaMiddleware)));

  // then run the saga
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
