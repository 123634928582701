import { call, put, takeLatest } from 'redux-saga/effects';
//import Api from '...';
import { Admin, User } from '../services/Unify';

const Api = {
  fetchUser: () => {},
};

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {
  try {
    // const user = yield call(User.getProfile, action.payload.userId);
    const user = yield call(User.getProfile);
    yield put({ type: 'USER_FETCH_SUCCEEDED', user });
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message });
  }
}

function* accountLoad(action) {
  try {
    // const user = yield call(User.getProfile, action.payload.userId);
    const response = yield call(Admin.getBalance, action.payload.id);
    const payload = {
      account: action.payload.id,
      balance: response.data,
    };
    yield put({ type: 'ACCOUNT_BALANCE_UPDATED', payload });
  } catch (e) {
    yield put({ type: 'ACCOUNT_ERROR', message: e.message });
  }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
// function* mySaga() {
//   yield takeEvery('USER_FETCH_REQUESTED', fetchUser);
// }

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
  yield takeLatest('USER_FETCH_REQUESTED', fetchUser);
  yield takeLatest('ACCOUNT_GET_BALANCE', accountLoad);
  yield takeLatest('ACCOUNT_LOAD', accountLoad);
}

export default mySaga;
