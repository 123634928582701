import { PERMISSION_GROUPS_V2, PERMISSION_TYPES, PERMISSION_TYPES_V2 } from './config/_permissions';
import i18n from './i18n';
import { User } from './services/Unify';
import {
  campaigns,
  faq,
  home,
  insights,
  manageDepartments,
  manageLeads,
  managePartners,
  managePermissions,
  manageUsers,
  marketplace,
  tc,
  transactions,
  wallet,
  manageSettingsAndPermissions,
  marketPlace,
  manageSecurity,
} from './containers/DefaultLayout/components/DrawerItem/icons';

// Transalation function
const t = (e) => (typeof i18n.t === 'function' ? i18n.t(e) : e);

class Nav {
  permissions = [];

  hasPermission = (key) => {
    return this.permissions.length === 0 || this.permissions.filter((e) => e === key).length === 1;
  };

  // Get all routes to display on navbar
  get nav() {
    this.permissions = User.role.permissions || [];
    const isPartner = User.isPartner();
    const items = [];
    const marketingContentRoute = () => {
      if (canViewBroadcast) return '/broadcast';
      if (canViewNews) return '/news';
      if (canViewEvents) return '/event';
      if (canViewSurvey) return '/survey';
      return '';
    };

    User._permissions = JSON.parse(window.sessionStorage.getItem('permissions'));

    // Home Dashboard
    // if (!isDepartment) {
    //   items.push({
    //     name: t('Home'),
    //     url: '/dashboard',
    //     icon: home,
    //     class: 'role-all',
    //   });
    // }

    items.push({
      name: t('Home'),
      url: '/dashboard',
      icon: home,
      class: 'role-all',
    });

    // Coins - Wallet
    const canViewDepartment = User.hasPermissionv2(PERMISSION_GROUPS_V2.WALLET, PERMISSION_TYPES_V2.WALLET.DEPARTMENT.VIEW);
    const canViewBroker = User.hasPermissionv2(PERMISSION_GROUPS_V2.WALLET, PERMISSION_TYPES_V2.WALLET.BROKER.VIEW);
    const canViewPartnerEarnings = User.hasPermissionv2(PERMISSION_GROUPS_V2.WALLET, PERMISSION_TYPES_V2.WALLET.PARTNER_EARNINGS.VIEW);
    const canViewPartnerMarketing = User.hasPermissionv2(PERMISSION_GROUPS_V2.WALLET, PERMISSION_TYPES_V2.WALLET.PARTNER_MARKETING.VIEW);
    if (canViewDepartment || canViewBroker || canViewPartnerEarnings || canViewPartnerMarketing) {
      const walletChildren = [];

      if (canViewDepartment) {
        walletChildren.push({
          name: t('Platform Department'),
          url: '/coins/department',
          class: 'role-all',
        });
      }
      if (canViewBroker) {
        walletChildren.push({
          name: t('Broker'),
          url: '/coins/broker',
          class: 'role-all',
        });
      }
      if (canViewPartnerEarnings || canViewPartnerMarketing) {
        walletChildren.push({
          name: t('Partner'),
          class: 'role-all',
          url: '/coins/partner',
        });
      }

      items.push({
        name: t('Wallet'),
        icon: wallet,
        children: walletChildren,
      });
    }

    // Insights
    const canViewInsightCustomer = User.hasAnyPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, Object.values(PERMISSION_TYPES_V2.INSIGHTS.CUSTOMER));
    const canViewAdvanceInsightApp = User.hasPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, PERMISSION_TYPES_V2.INSIGHTS.ADVANCED_VIEW);
    const canViewAppInsightApp = User.hasPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, PERMISSION_TYPES_V2.INSIGHTS.APP.APP_PERFORMANCE_ANALYTICS);
    if (canViewInsightCustomer || canViewAdvanceInsightApp || canViewAppInsightApp) {
      if (!isPartner) {
        items.push({
          name: t('Insights'),
          url: '/insights/customer',
          icon: insights,
          class: 'role-all',
        });
      } else {
        items.push({
          name: t('Insights'),
          url: '/insights/partner',
          icon: insights,
          class: 'role-all',
        });
      }
    }

    // Transactions
    const canViewTxCustomer = User.hasPermissionv2(PERMISSION_GROUPS_V2.TX, PERMISSION_TYPES_V2.TX.CUSTOMER_VIEW);
    const canViewTxEmployee = User.hasPermissionv2(PERMISSION_GROUPS_V2.TX, PERMISSION_TYPES_V2.TX.EMPLOYEE_VIEW);
    const canViewTxPeerToPeer = User.hasPermissionv2(PERMISSION_GROUPS_V2.TX, PERMISSION_TYPES_V2.TX.P2P_VIEW);
    const canViewTxSales = User.hasPermissionv2(PERMISSION_GROUPS_V2.TX, PERMISSION_TYPES_V2.TX.SALES_CONSULTANT_VIEW);

    if (canViewTxCustomer || canViewTxEmployee || canViewTxPeerToPeer || canViewTxSales) {
      const txChildren = [];
      if (canViewTxCustomer) {
        txChildren.push({
          name: t('User'),
          url: '/transactions/customer',
          class: 'role-all',
        });
      }

      if (canViewTxEmployee) {
        txChildren.push({
          name: t('Sales Consultant'),
          url: '/transactions/agent',
          class: 'role-all',
        });
      }

      if (canViewTxPeerToPeer) {
        txChildren.push({
          name: t('Employee'),
          url: '/transactions/employee',
          class: 'role-all',
        });
      }

      if (canViewTxSales) {
        txChildren.push({
          name: t('Peer to Peer'),
          url: '/transactions/p2p',
          class: 'role-all',
        });
      }

      items.push({
        name: t('Transactions'),
        icon: transactions,
        children: txChildren,
      });
    }

    // Campaigns
    const canViewRewards = User.hasAnyPermissionv2(
      PERMISSION_GROUPS_V2.CAMPAIGNS,
      PERMISSION_TYPES_V2.CAMPAIGNS.REWARDS.BURN.VIEW,
      PERMISSION_TYPES_V2.CAMPAIGNS.REWARDS.EARN.VIEW
    );
    const canViewMissions = User.hasPermissionv2(PERMISSION_GROUPS_V2.CAMPAIGNS, PERMISSION_TYPES_V2.CAMPAIGNS.MISSIONS.VIEW);
    const canViewGames = User.hasPermissionv2(PERMISSION_GROUPS_V2.CAMPAIGNS, PERMISSION_TYPES_V2.CAMPAIGNS.GAMES.VIEW);
    const campaignsChildren = [];
    if (canViewRewards) {
      campaignsChildren.push({
        name: t('Rewards'),
        url: '/rewards',
        // icon: 'icon-present',
        class: 'role-all',
      });
    }
    if (canViewMissions) {
      campaignsChildren.push({
        name: t('Missions'),
        url: '/mission',
        // icon: 'icon-compass',
        class: 'role-all',
      });
    }
    if (canViewGames) {
      campaignsChildren.push({
        name: t('Games'),
        url: '/game',
        // icon: 'icon-puzzle',
        class: 'role-all',
      });
    }

    const canViewPromocode = User.hasPermissionv2(PERMISSION_GROUPS_V2.CAMPAIGNS, PERMISSION_TYPES_V2.CAMPAIGNS.PROMOCODE.VIEW);

    // add logic checking later when Be confirm
    if (canViewPromocode) {
      campaignsChildren.push({
        name: t('Promo Code'),
        url: '/promo',
        class: 'role-all',
      });
    }

    items.push({
      name: t('Campaigns'),
      icon: campaigns,
      children: campaignsChildren,
    });

    // Marketing Content
    const canViewMarketingContent = User.hasAnyPermissionv2(
      PERMISSION_GROUPS_V2.MARKETING_CONTENT,
      PERMISSION_TYPES_V2.MARKETING_CONTENT.BROADCASTS.VIEW,
      PERMISSION_TYPES_V2.MARKETING_CONTENT.EVENTS.VIEW,
      PERMISSION_TYPES_V2.MARKETING_CONTENT.NEWS.VIEW,
      PERMISSION_TYPES_V2.MARKETING_CONTENT.SURVEYS.VIEW
    );

    const canViewBroadcast = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.BROADCASTS.VIEW);
    const canViewEvents = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.EVENTS.VIEW);
    const canViewNews = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.NEWS.VIEW);
    const canViewSurvey = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.SURVEYS.VIEW);

    if (canViewMarketingContent) {
      items.push({
        name: t('Marketing Content'),
        icon: marketplace,
        url: marketingContentRoute(),
      });
    }

    if (User.hasPermission(PERMISSION_TYPES.MANAGE_EDIT_NSC)) {
      items.push({
        name: t('Manage Departments'),
        url: '/manage/nsc',
        icon: manageDepartments,
        class: 'role-global',
      });
    }

    // Partner Management - PM
    const canViewPMCounter = User.hasPermissionv2(PERMISSION_GROUPS_V2.PARTNER_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.COUNTER.VIEW);
    const canViewPMOutlet = User.hasPermissionv2(PERMISSION_GROUPS_V2.PARTNER_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.OUTLET.VIEW);
    const canViewPMEntity = User.hasPermissionv2(PERMISSION_GROUPS_V2.PARTNER_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.ENTITY.VIEW);
    if (canViewPMCounter || canViewPMOutlet || canViewPMEntity) {
      items.push({
        name: t('Partner Management'),
        url: '/manage/partner',
        icon: managePartners,
        class: 'role-all',
      });
    }

    // Outlet Management - OM
    const canViewOMCounter = User.hasPermissionv2(PERMISSION_GROUPS_V2.OUTLET_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.COUNTER.VIEW);
    const canViewOMOutlet = User.hasPermissionv2(PERMISSION_GROUPS_V2.OUTLET_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.OUTLET.VIEW);
    const canViewOMEntity = User.hasPermissionv2(PERMISSION_GROUPS_V2.OUTLET_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.ENTITY.VIEW);
    if (canViewOMCounter || canViewOMOutlet || canViewOMEntity) {
      items.push({
        name: t('Outlet Management'),
        url: `/manage/outlet/${User.getEntityId()}`,
        icon: managePartners,
        class: 'role-all',
      });
    }

    // Entity Management - EM
    const canViewEMCounter = User.hasPermissionv2(PERMISSION_GROUPS_V2.ENTITY_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.COUNTER.VIEW);
    const canViewEMOutlet = User.hasPermissionv2(PERMISSION_GROUPS_V2.ENTITY_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.OUTLET.VIEW);
    const canViewEMEntity = User.hasPermissionv2(PERMISSION_GROUPS_V2.ENTITY_MANAGEMENT, PERMISSION_TYPES_V2.PARTNER_MANAGEMENT.ENTITY.VIEW);
    if (canViewEMCounter || canViewEMOutlet || canViewEMEntity) {
      items.push({
        name: t('Entity Management'),
        url: `/manage/entity/${User.getEntityId()}`,
        icon: managePartners,
        class: 'role-all',
      });
    }

    // User Management
    if (!isPartner) {
      const canViewCustomerManagement = User.hasPermissionv2(PERMISSION_GROUPS_V2.USER_MANAGEMENT, PERMISSION_TYPES_V2.USER_MANAGEMENT.CUSTOMER.VIEW);
      const canViewSalesManagement = User.hasPermissionv2(
        PERMISSION_GROUPS_V2.USER_MANAGEMENT,
        PERMISSION_TYPES_V2.USER_MANAGEMENT.SALE_CONSULTANT.VIEW
      );
      const canViewEmployeeeManagement = User.hasPermissionv2(
        PERMISSION_GROUPS_V2.USER_MANAGEMENT,
        PERMISSION_TYPES_V2.USER_MANAGEMENT.EMPLOYEE.VIEW
      );

      if (canViewCustomerManagement || canViewSalesManagement || canViewEmployeeeManagement) {
        const userManagementChildren = [];

        if (canViewCustomerManagement) {
          userManagementChildren.push({
            name: t('User'),
            url: '/manage/users/user',
            class: 'role-all',
          });
        }

        if (canViewSalesManagement) {
          userManagementChildren.push({
            name: t('Sales Consultant'),
            url: '/manage/users/agent',
            class: 'role-all',
          });
        }

        if (canViewEmployeeeManagement) {
          userManagementChildren.push({
            name: t('Employee'),
            url: '/manage/users/employee',
            class: 'role-all',
          });
        }

        items.push({
          name: t('User Management'),
          icon: manageUsers,
          children: userManagementChildren,
        });
      }
    }

    // Lead Management
    const canViewManageLeads = User.hasPermissionv2(PERMISSION_GROUPS_V2.LEAD_MANAGEMENT, PERMISSION_TYPES_V2.LEAD_MANAGEMENT.VIEW);
    if (canViewManageLeads && !isPartner) {
      items.push({
        name: t('Manage Leads'),
        url: '/manage/leads',
        icon: manageLeads,
        class: 'role-global role-kr',
      });
    }

    // Settings and Permissions
    const canViewOrgSettings = User.hasPermissionv2(PERMISSION_GROUPS_V2.SETTINGS_PERMISSIONS, PERMISSION_TYPES_V2.SETTINGS_PERMISSIONS.VIEW);
    if (this.hasPermission(PERMISSION_TYPES.MANAGE_PERMISSIONS) || canViewOrgSettings) {
      const viewChildren = [];

      if (this.hasPermission(PERMISSION_TYPES.MANAGE_PERMISSIONS)) {
        viewChildren.push({
          name: t('Manage Permissions'),
          url: '/manage/permissions',
          icon: managePermissions,
          class: 'role-global role-kr role-partner',
        });
      }

      if (canViewOrgSettings) {
        viewChildren.push({
          name: t('Organisation Settings'),
          url: '/settings/organisation',
          icon: managePermissions,
          class: 'role-global role-kr role-partner',
        });
      }

      items.push({
        name: t('Settings and Permissions'),
        icon: manageSettingsAndPermissions,
        class: 'role-global role-kr',
        children: viewChildren,
      });
    }
    // Security
    const canViewAuditTrail = User.hasPermissionv2(PERMISSION_GROUPS_V2.SECURITY, PERMISSION_TYPES_V2.SECURITY.SECURITY_AUDIT_TRAIL_VIEW);
    const viewChildren = [];
    if (canViewAuditTrail) {
      viewChildren.push({
        name: t('Audit Trail'),
        url: '/security/audit',
        class: 'role-global role-kr role-partner',
      });
    }
    const canViewFraudManagement = User.hasPermissionv2(PERMISSION_GROUPS_V2.SECURITY, PERMISSION_TYPES_V2.SECURITY.SECURITY_FRAUD_MANAGEMENT_VIEW);
    if (canViewFraudManagement) {
      viewChildren.push({
        name: t('Fraud Management'),
        url: '/security/fraud',
        class: 'role-global role-kr role-partner',
      });
    }
    if (canViewAuditTrail || canViewFraudManagement) {
      items.push({
        name: t('Security'),
        icon: manageSecurity,
        class: 'role-global role-kr',
        children: viewChildren,
      });
    }
    // T&C
    const canViewTC = User.hasPermissionv2(PERMISSION_GROUPS_V2.TC, PERMISSION_TYPES_V2.TC.VIEW);
    if (canViewTC) {
      items.push({
        name: t('T&C'),
        url: '/tc',
        icon: tc,
        class: 'role-global role-kr',
      });
    }

    // FAQ
    const canViewFAQ = User.hasPermissionv2(PERMISSION_GROUPS_V2.FAQ, PERMISSION_TYPES_V2.FAQ.VIEW);
    if (canViewFAQ) {
      items.push({
        name: t('FAQ'),
        url: '/faq',
        icon: faq,
        class: 'role-global role-kr',
      });
    }

    return items;
  }

  // Get remaining routes that is not displayed on navbar
  get navChildren() {
    const items = [];

    const canViewInsightCustomer = User.hasAnyPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, Object.values(PERMISSION_TYPES_V2.INSIGHTS.CUSTOMER));
    const canViewInsightAdvanced = User.hasPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, PERMISSION_TYPES_V2.INSIGHTS.ADVANCED_VIEW);
    const canViewInsightApp = User.hasPermissionv2(PERMISSION_GROUPS_V2.INSIGHTS, PERMISSION_TYPES_V2.INSIGHTS.APP.APP_PERFORMANCE_ANALYTICS);

    if (canViewInsightCustomer) {
      items.push({
        url: '/insights/customer',
      });
    }

    if (canViewInsightAdvanced) {
      items.push({
        url: '/insights/advanced',
      });
    }

    if (canViewInsightApp) {
      items.push({
        url: '/insights/app',
      });
    }

    const canViewBroadcast = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.BROADCASTS.VIEW);
    const canViewNews = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.NEWS.VIEW);
    const canViewEvents = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.EVENTS.VIEW);
    const canViewSurveys = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETING_CONTENT, PERMISSION_TYPES_V2.MARKETING_CONTENT.SURVEYS.VIEW);
    if (canViewBroadcast) {
      items.push({
        url: '/broadcast',
      });
    }
    if (canViewNews) {
      items.push({
        url: '/news',
      });
    }
    if (canViewEvents) {
      items.push({
        url: '/event',
      });
    }
    if (canViewSurveys) {
      items.push({
        url: '/survey',
      });
    }

    // Simulate
    items.push({
      url: '/simulate',
    });

    // Personal Settings
    items.push({
      url: '/settings/personal',
    });

    // WIP
    items.push({
      url: '/work-in-progress',
    });

    // Error
    items.push({
      url: '/error',
    });

    return items;
  }

  get marketplace() {
    const isPartner = User.isPartner();

    if (!isPartner) {
      const canViewSummary = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETPLACE, PERMISSION_TYPES_V2.MARKETPLACE.SUMMARY.VIEW);
      const canViewCatalogue = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETPLACE, PERMISSION_TYPES_V2.MARKETPLACE.CATALOGUE.VIEW);
      const canViewTx = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETPLACE, PERMISSION_TYPES_V2.MARKETPLACE.TX.VIEW);
      const canViewSettings = User.hasPermissionv2(PERMISSION_GROUPS_V2.MARKETPLACE, PERMISSION_TYPES_V2.MARKETPLACE.SETTING.VIEW);
      const marketplaceChildren = [];

      if (canViewSummary || canViewCatalogue || canViewTx || canViewSettings) {
        if (canViewSummary) {
          marketplaceChildren.push({
            name: t('Summary'),
            url: '/marketplace/summary',
            class: 'role-all',
          });
        }
        if (canViewCatalogue) {
          marketplaceChildren.push({
            name: t('Catalogue'),
            url: '/marketplace/catalogue',
            class: 'role-all',
          });
        }
        if (canViewTx) {
          marketplaceChildren.push({
            name: t('Transactions'),
            url: '/marketplace/transactions',
            class: 'role-all',
          });
        }
        if (canViewSettings) {
          marketplaceChildren.push({
            name: t('Settings'),
            url: '/marketplace/settings',
            class: 'role-all',
          });
        }

        return {
          name: t('Marketplace'),
          icon: marketPlace,
          children: marketplaceChildren,
        };
      }
    }

    return {};
  }
}

export default new Nav();
