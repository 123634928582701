// Updates an entity cache in response to any action with response.entities.
export default function crm(state = {}, action) {
  switch (action.type) {
    case 'CRM_LIST_SUCCESS':
      if (action.payload.length > 0) {
        const item = action.payload[0];
        return Object.assign({}, state, {
          list: action.payload,
          [item.type]: action.payload,
        });
      } else {
        return Object.assign({}, state, {
          list: action.payload,
        });
      }
    case 'CRM_GET_SUCCESS':
      return Object.assign({}, state, {
        object: action.payload,
      });
    case 'CRM_UPDATE_SUCCESS':
      return Object.assign({}, state, {
        object: action.payload,
      });
    case 'CRM_LIST_EDGES_SUCCESS':
      return Object.assign({}, state, {
        edges: Object.assign({}, state.edges ? state.edges : {}, {
          [action.payload.edgeType]: action.payload.list,
        }),
      });
    case 'CRM_CLEAR':
      return Object.assign({}, state, {
        object: undefined,
      });
    case 'CRM_PROFILE_SUCCESS':
      return Object.assign({}, state, {
        profiles: action.payload,
      });
    case 'CRM_PROFILES_SUCCESS':
      // eslint-disable-next-line
      const merged = Object.assign({}, state.profiles || {}, ...action.payload.map((e) => ({ [e.id]: e })));
      return Object.assign({}, state, {
        profiles: merged,
      });
    case 'CRM_PROFILE_BY_EMAIL_SUCCESS':
      return Object.assign({}, state, {
        profiles: Object.assign({}, state.profiles || {}, {
          [action.payload.email]: action.payload,
        }),
      });
    default:
      return state;
  }
}
