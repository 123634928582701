// Updates an entity cache in response to any action with response.entities.

export default function leads(state = {}, action) {
  switch (action.type) {
    case 'LEADS_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    case 'LEADS_LIST_FAILED':
      return Object.assign({}, state, {});

    case 'GET_LEAD_SUCCESS':
      return Object.assign({}, state, {
        lead: Array.isArray(action.payload) && action.payload.length > 0 ? action.payload[0] : action.payload,
      });
    case 'GET_LEAD_FAILED':
      return Object.assign({}, state, {});

    case 'POST_LEADS_LIST_SUCCESS':
      return Object.assign({}, state, {});
    case 'POST_LEADS_LIST_FAILED':
      return Object.assign({}, state, {});

    case 'PATCH_LEAD_SUCCESS':
      return Object.assign({}, state, {});
    case 'PATCH_LEAD_FAILED':
      return Object.assign({}, state, {});

    case 'DELETE_LEAD_SUCCESS':
      return Object.assign({}, state, {});
    case 'DELETE_LEAD_FAILED':
      return Object.assign({}, state, {});

    default:
      return state;
  }
}
