import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import Coins from '../services/Unify/Coins';

function* coinsList(action) {
  try {
    const result = yield call(Coins.list);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* coinsGet(action) {
  try {
    const result = yield call(Coins.get, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* coinsUpdate(action) {
  try {
    const result = yield call(Coins.update, action.payload.id, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* coinsApprove(action) {
  try {
    const result = yield call(Coins.approve, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* rewardsSaga() {
  yield takeLatest('COINS_LIST', coinsList);
  yield takeEvery('COINS_GET', coinsGet);
  yield takeEvery('COINS_UPDATE', coinsUpdate);
  yield takeEvery('COINS_APPROVE', coinsApprove);
}

export default rewardsSaga;
