// Updates an entity cache in response to any action with response.entities.
export default function user(
  state = {
    isDarkTheme: JSON.parse(localStorage.getItem('mode')) ? true : false,
  },
  action
) {
  switch (action.type) {
    case 'USER_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    case 'USER_GET_SUCCESS':
      return Object.assign({}, state, {
        user: action.payload,
      });
    case 'USER_FETCH_SUCCEEDED':
      return Object.assign({}, state, {
        user: action.payload,
      });
    case 'USER_CLEAR':
      return Object.assign({}, state, {
        user: undefined,
      });
    case 'CHANGE_THEME_MODE':
      return Object.assign({}, state, {
        isDarkTheme: action.isDarkTheme,
      });
    default:
      return state;
  }
}
