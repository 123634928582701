import { all, call, spawn } from 'redux-saga/effects';
import accountsSaga from './accounts';
import analyticsSaga from './analytics';
import coinsSaga from './coins';
import crmSaga from './crm';
import eventSaga from './event';
import leadsSaga from './leads';
import partnersSaga from './partners';
import rewardsSaga from './rewards';
import servicesSaga from './services';
import userSaga from './user';

export default function* rootSaga() {
  const sagas = [accountsSaga, analyticsSaga, eventSaga, rewardsSaga, partnersSaga, servicesSaga, userSaga, crmSaga, coinsSaga, leadsSaga];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (ignore) {}
        }
      })
    )
  );
}
