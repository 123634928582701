import { call, put, takeLatest } from 'redux-saga/effects';
import { Admin } from '../services/Unify';

function* listLeads(action) {
  try {
    const list = yield call(Admin.getLeads);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* getLead(action) {
  try {
    const list = yield call(Admin.getLead, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* postLeads(action) {
  try {
    const list = yield call(Admin.postLeads, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* patchLead(action) {
  try {
    const list = yield call(Admin.patchLeads, action.payload.id, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* deleteLead(action) {
  try {
    const list = yield call(Admin.deleteLead, action.payload.id);
    yield put({ type: action.type + '_SUCCESS', payload: list });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* leadsSaga() {
  yield takeLatest('LEADS_LIST', listLeads);
  yield takeLatest('GET_LEAD', getLead);
  yield takeLatest('POST_LEADS_LIST', postLeads);
  yield takeLatest('PATCH_LEAD', patchLead);
  yield takeLatest('DELETE_LEAD', deleteLead);
}

export default leadsSaga;
