import { isUndefined } from 'lodash';
import moment from 'moment';
import Logger from './Logger';
import Unify from './Unify';

const logger = new Logger('CRM');

const pack = (item) => {
  // Ignore NSC as we have reimplemented it
  // once old data is fixed, we will remove packing and unpacking..TODO
  if (item.type === 'nsc' || item.type === 'partner' || item.type === 'entity') {
    return item;
  }
  const fields = ['id', 'key', 'type', 'status', 'name', 'logo', 'properties', 'parent', 'createdAt', 'updatedAt', 'version'];
  const partial = Object.assign({ properties: {} }, item);
  Object.keys(partial).forEach((key) => {
    if (!fields.includes(key)) {
      partial.properties[key] = partial[key];
      delete partial[key];
    }
  });
  return item;
};

const unpack = (item) => {
  if (item) {
    // Ignore NSC as we have reimplemented it
    if (item.type === 'nsc' || item.type === 'partner') {
      return item;
    }

    if (!item.properties) {
      item.properties = {};
    }
    // Hoist the properties up
    item = Object.assign({}, item, item.properties);
    if (item.createdAt) {
      item.created_at = item.createdAt;
    }
    if (item.updatedAt) {
      item.updated_at = item.updatedAt;
    }
  }
  return item;
};

/**
 *
 */
class CRM {
  // Types
  static NSC = 'nsc';
  static PARTNER = 'partner';
  static ENTITY = 'entity';
  static OUTLET = 'outlet';
  static COUNTER = 'counter';
  static CUSTOMER = 'customer';

  /**
   *
   * @param payload
   * @returns {Promise<*|SimpleCacheConfigurator.never>}
   */
  async addToGuestList(payload) {
    return Unify._post('/api/admin/crm/add-to-guest-list', payload);
  }

  async getEntities(payload) {
    return Unify._post('/api/admin/crm/getEntities', payload);
  }

  async getEntitiesV2(type, payload) {
    return Unify._post(`/api/admin/crm/getEntities/${type}`, payload);
  }

  async addEntity(id, type, payload) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._post(`/api/admin/crm/${id}/${type}`, payload);
  }

  /**
   *
   * @param type
   * @returns {Promise<any|never>}
   */
  async profiles(list) {
    if (isUndefined(list)) throw new Error('list is required');

    return Unify._get(`/api/admin/crm/profiles?id=${encodeURIComponent(list.join(','))}`).then((results) => {
      return results;
    });
  }

  /**
   *
   * @param type
   * @returns {Promise<any|never>}
   */
  async list(type) {
    if (isUndefined(type)) throw new Error('type is required');
    return Unify._get(`/api/admin/crm/${type}`).then((results) => {
      if (results) {
        return results.map((item) => unpack(item));
      }
      return results;
    });
  }

  /**
   *
   * @param type
   * @param id
   * @returns {Promise<any|never>}
   */
  async get(type, id, needUnpack = true) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._get(`/api/admin/crm/${type}/${id}`).then((item) => {
      if (needUnpack) {
        return unpack(item);
      } else {
        return item;
      }
    });
  }

  /**
   * Create a new object
   *
   * @param type
   * @param obj
   * @returns {Promise<any|never>}
   */
  async create(type, obj) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(obj)) throw new Error('body is required');
    const now = moment().utc().toISOString();
    if (isUndefined(obj.created_at)) {
      obj.created_at = now;
    }
    obj.updated_at = now;
    return Unify._post(`/api/admin/crm/${type}`, pack(obj));
  }

  /**
   * Update complete object
   *
   * @param type
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async save(type, id, obj) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(obj)) throw new Error('body is required');
    const now = moment().utc().toISOString();
    if (isUndefined(obj.createdAt)) {
      obj.createdAt = now;
    }
    obj.updatedAt = now;
    return Unify._patch(`/api/admin/crm/${type}/${id}`, pack(obj));
  }

  /**
   * Partial update of existing object
   *
   * @param type
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async update(type, id, obj) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(obj)) throw new Error('body is required');
    // const now = moment().utc().toISOString();
    // obj.updatedAt = now;
    return Unify._patch(`/api/admin/crm/${type}/${id}`, pack(obj));
  }

  /**
   * Delete object
   *
   * @param type
   * @param id
   * @returns {Promise<any|never>}
   */
  async delete(type, id) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._delete(`/api/admin/crm/${type}/${id}`);
  }

  /**
   *
   * @param type
   * @param id
   * @param edgeType
   * @returns {Promise<any|never>}
   */
  async list_edges(type, id, edgeType) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(edgeType)) throw new Error('edgeType is required');
    return Unify._get(`/api/admin/crm/${type}/${id}/${edgeType}`);
  }

  /**
   *
   * @param type
   * @param id
   * @param edgeType
   * @param edgeKey
   * @returns {Promise<any|never>}
   */
  async get_edge(type, id, edgeType, edgeKey) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(edgeType)) throw new Error('edgeType is required');
    if (isUndefined(edgeKey)) throw new Error('edgeKey is required');
    return Unify._get(`/api/admin/crm/${type}/${id}/${edgeType}/${edgeKey}`);
  }

  /**
   *
   * @param type
   * @param id
   * @param edgeType
   * @param edgeKey
   * @param body
   * @returns {Promise<any|never>}
   */
  async update_edge(type, id, edgeType, edgeKey, body) {
    if (isUndefined(type)) throw new Error('type is required');
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(edgeType)) throw new Error('edgeType is required');
    if (isUndefined(edgeKey)) throw new Error('edgeKey is required');
    if (isUndefined(body)) throw new Error('body is required');
    const now = moment().utc().toISOString();
    body.updated_at = now;
    return Unify._patch(`/api/admin/crm/${type}/${id}/${edgeType}/${edgeKey}`, body);
  }

  /**
   *
   * @param email
   * @returns {Promise<any|never>}
   */
  async getProfileByEmail(email) {
    return Unify._get('/api/customer/email/' + email);
  }
}

export default new CRM();
