import Unify from './Unify';

/**
 * Manage misc settings
 */
class Settings {
  async GetP2PSettings() {
    return Unify._get('/api/admin/settings/p2p');
  }

  async UpdateP2PSettings(form) {
    return Unify._put('/api/admin/settings/p2p', form);
  }
}

export default new Settings();
