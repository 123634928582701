import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Admin } from '../services/Unify';

function* listPartners(action) {
  try {
    const list = yield call(Admin.getPartners);
    const actions = [];
    if (list) {
      if (list.length > 0) {
        list.forEach((e) => {
          if (e.id) {
            actions.push(put({ type: 'ACCOUNT_GET_BALANCE', payload: { id: e.id } }));
          }
        });
      }
    }
    actions.push(put({ type: 'PARTNER_LIST_SUCCESS', payload: list }));
    // yield put({type: 'PARTNER_LIST_SUCCESS', payload: list});
    yield all(actions);
  } catch (e) {
    yield put({ type: 'PARTNER_LIST_FAILED', payload: e.message });
  }
}

// function* getPartner(action) {
//   try {
//     // const user = yield call(User.getProfile, action.payload.userId);
//     const reward = yield call(getRewardById, action.payload);
//     yield put({type: 'PARTNER_GET_SUCCESS', payload: reward});
//   } catch (e) {
//     yield put({type: 'PARTNER_GET_FAILED', payload: e.message});
//   }
// }
//
// function* deletePartner(action) {
//   try {
//     // const user = yield call(User.getProfile, action.payload.userId);
//     const reward = yield call(deleteRewardById, action.payload);
//     yield put({type: 'PARTNER_DELETE_SUCCESS', payload: reward});
//   } catch (e) {
//     yield put({type: 'PARTNER_DELETE_FAILED', payload: e.message});
//   }
// }
//
// function* updatePartner(action) {
//   try {
//     const reward = yield call(updateRewardById, action.payload);
//     yield put({type: 'PARTNER_UPDATE_SUCCESS', payload: reward});
//   } catch (e) {
//     yield put({type: 'PARTNER_UPDATE_FAILED', payload: e.message});
//   }
// }
//
// function* getPartnerAnalytics(action) {
//   try {
//     const result = yield call(
//       Admin.getAnalyticsById,
//       'partner',
//       action.payload,
//     );
//     yield put({type: action.type + '_SUCCESS', payload: result});
//   } catch (e) {
//     yield put({type: action.type + '_FAILED', payload: e.message});
//   }
// }
//
// function* getPartnerTransactions(action) {
//   try {
//     const result = yield call(
//       Admin.getTransactionsById,
//       'partner',
//       action.payload,
//     );
//     yield put({type: action.type + '_SUCCESS', payload: result});
//   } catch (e) {
//     yield put({type: action.type + '_FAILED', payload: e.message});
//   }
// }

function* partnersSaga() {
  yield takeLatest('PARTNER_LIST', listPartners);
  // yield takeEvery('PARTNER_GET', getPartner);
  // yield takeEvery('PARTNER_DELETE', deletePartner);
  // yield takeEvery('PARTNER_UPDATE', updatePartner);
  // yield takeEvery('PARTNER_GET_ANALYTICS', getPartnerAnalytics);
  // yield takeEvery('PARTNER_GET_TRANSACTIONS', getPartnerTransactions);
}

export default partnersSaga;
