import Logger from './Logger';
import Unify from './Unify';
import User from './User';

const logger = new Logger('Auth');

/**
 *
 */
class Auth {
  /**
   *
   * @returns {boolean}
   */
  isAuthenticated() {
    return Unify.validToken();
  }

  /**
   *
   * @param email
   * @param password
   * @returns {Promise<any|never>}
   */
  async login(email, password) {
    logger.debug('login:', email);
    return Unify._post('/api/auth/admin/login', {
      email,
      password,
      scope: 'admin',
    });
  }

  /**
   *
   * @param email
   * @param password
   * @returns {Promise<any|never>}
   */
  // async register(email, password) {
  //   logger.debug('register:', email);
  //   return Unify._post('/api/admin/auth/register', {
  //     email: email,
  //     password: password,
  //   });
  // }
  async register(token, password) {
    logger.debug('register:', token);
    return Unify._post('/api/auth/admin/register', {
      token,
      password,
    });
  }

  /**
   *
   * @param otp
   * @param token
   * @returns {Promise<any|never>}
   */
  async otp(otp, token) {
    logger.debug('otp:', otp, token);
    return Unify._post('/api/admin/auth/otp', {
      otp,
      token,
    });
  }

  /**
   *
   * @param email
   * @returns {Promise<any|never>}
   */
  async forgot(email) {
    logger.debug('forgot:', email);
    return Unify._post('/api/auth/admin/forgot', {
      email,
    });
  }

  /**
   *
   * @param token
   * @param password
   * @returns {Promise<any|never>}
   */
  async reset(token, password) {
    logger.debug('reset:', token);
    return Unify._post('/api/auth/admin/reset', {
      token,
      password,
    });
  }

  /**
   *
   * @param old_password
   * @param new_password
   * @returns {Promise<any|never>}
   */
  async changePassword(old_password, new_password) {
    logger.debug('changePassword:');
    return Unify._post('/api/auth/admin/changepassword', {
      password: new_password,
      old_password,
    });
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async logout() {
    logger.debug('logout:', Unify._token);
    return Unify._post('/api/auth/admin/logout', { token: Unify._token })
      .then((result) => {
        Unify.deleteToken();
        window.sessionStorage.clear();
        User.reset();
        return result;
      })
      .catch((err) => {
        Unify.deleteToken();
      });
  }
}

export default new Auth();
