export const MAXIMUM_DATE = '2038-01-19';
export const MAXIMUM_DATETIME = '2038-01-19T03:14:07.0Z';
// Hmm.. Temporary
export const USER_SEGMENTS = {
  customer: [
    { label: 'Group' },
    { value: 'group_beta', label: 'Beta Testers' },
    // Member types
    { label: 'Membership Type' },
    { value: 'customer_tier_blue', label: 'Blue' },
    { value: 'customer_tier_silver', label: 'Silver' },
    { value: 'customer_tier_gold', label: 'Gold' },
    { value: 'customer_tier_black', label: 'Black' },
    // Behaviour group
    { label: 'Behavioural Group' },
    { value: 'behaviour_top_spenders', label: 'Top Spenders' },
    { value: 'behaviour_top_gamers', label: 'Top Gamers' },
    { value: 'behaviour_recurring_visitors', label: 'Recurring Visitors' },
    // Interest group
    { label: 'Interests' },
    { value: 'interest_food_and_drink', label: 'Food & Drinks' },
    { value: 'interest_entertainment', label: 'Entertainment' },
    { value: 'interest_family_and_kids', label: 'Family & Kids' },
    { value: 'interest_romance_and_dining', label: 'Romance & Dining' },
    { value: 'interest_sport_and_fitness', label: 'Sport & Fitness' },
    // By source of the user
    { label: 'Source:' },
    { value: 'source_staff', label: 'Staff' },
    { value: 'source_referral', label: 'Referral' },
    { value: 'source_leads', label: 'Leads' },
  ],
  agent: [],
  employee: [
    { label: 'Membership Type' },
    { value: 'employee_tier_blue', label: 'Blue' },
    { value: 'employee_tier_silver', label: 'Silver' },
    { value: 'employee_tier_gold', label: 'Gold' },
    { value: 'employee_tier_black', label: 'Black' },
  ],
};

// TODO: Move this into server-side configuration
export const DEFAULT_CLIENT = '00000000-0000-0000-0000-000000000000';
export const CLIENTS = [
  {
    id: '00000000-0000-0000-0000-000000000000',
    type: 'client',
    name: 'Unify Demo',
    image: '/assets/demo/logo.png',
    features: ['coins', 'transactions', 'news', 'events', 'broadcast', 'rewards', 'games', 'missions', 'content'],
    tiers: ['blue', 'silver', 'gold'],
    segments: {
      customer: [
        //
        { label: 'Group' },
        { value: 'group_beta', label: 'Beta Testers' },
        // Member types
        { label: 'Membership Type' },
        { value: 'customer_tier_blue', label: 'Blue' },
        { value: 'customer_tier_silver', label: 'Silver' },
        { value: 'customer_tier_gold', label: 'Gold' },
        // Behaviour group
        { label: 'Behavioural Group' },
        { value: 'behaviour_top_spenders', label: 'Top Spenders' },
        { value: 'behaviour_top_gamers', label: 'Top Gamers' },
        { value: 'behaviour_recurring_visitors', label: 'Recurring Visitors' },
        // Interest group
        { label: 'Interests' },
        { value: 'interest_food_and_drink', label: 'Food & Drinks' },
        { value: 'interest_entertainment', label: 'Entertainment' },
        { value: 'interest_family_and_kids', label: 'Family & Kids' },
        { value: 'interest_romance_and_dining', label: 'Romance & Dining' },
        { value: 'interest_sport_and_fitness', label: 'Sport & Fitness' },
        // By source of the user
        { label: 'Source:' },
        { value: 'source_staff', label: 'Staff' },
        { value: 'source_referral', label: 'Referral' },
        { value: 'source_leads', label: 'Leads' },
      ],
      agent: [],
      employee: [
        { label: 'Membership Type' },
        { value: 'employee_tier_blue', label: 'Blue' },
        { value: 'employee_tier_silver', label: 'Silver' },
        { value: 'employee_tier_gold', label: 'Gold' },
        { value: 'employee_tier_black', label: 'Black' },
      ],
    },
  },
];

export const DEPARTMENT_LABELS = {
  sales: 'Sales',
  marketing: 'Marketing',
  CRM: 'CRM',
  affiliate: 'Affiliate',
  after_sales_marketing: 'After-Sales Marketing',
  after_sales_sales: 'After-Sales Sales',
  alliance_event: 'Alliance Event',
};

export const PARTNER_DEPARTMENT_LABELS = {
  marketing: 'Marketing',
};

export const DROPDOWN_BUTTON_ONE = [
  {
    id: 1,
    name: 'Most',
    sortOrder: 1,
  },
  {
    id: 2,
    name: 'Least',
    sortOrder: -1,
  },
];

export const DROPDOWN_BUTTON_TWO = [
  {
    id: 1,
    name: 'Top',
    sortOrder: 1,
  },
  {
    id: 2,
    name: 'Bottom',
    sortOrder: -1,
  },
];

export const EMPTY_TEMPLATE = {
  labels: [],
  transaction_volume: {
    datasets: {
      burn_data: [],
      send_data: [],
      gift_data: [],
      games_data: [],
      earn_data: [],
      p2p_data: [],
    },
  },
  partner_transactions: {
    datasets: {
      amount_data: [],
    },
  },
};

export const ITEM_STATUSES = ['active', 'pending', 'published', 'unpublished', 'draft', 'scheduled'];
