const config = {
  //
  domain: 'unify.eco',

  //
  i18n: {
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en', 'ko'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    useSuspense: false,
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
    defaultNS: 'translation',
    availableLanguages: [
      { key: 'en', label: 'English' },
      { key: 'ko', label: 'Korean' },
    ],

    // Locize
    // backend: {
    //   projectId: '54586e64-ab3a-4eca-bfd0-2ee4c9e7433a',
    //   apiKey: 'f5b02acc-0ee1-4a60-9a30-3fb45795050d',
    //   referenceLng: 'en',
    // },
    backend: {
      loadPath: '/api/admin/locales/{{lng}}/{{ns}}.json',
      addPath: '/api/admin/locales/add/{{lng}}/{{ns}}',
    },
    saveMissing: true,
  },

  //
  logger: {
    level: 'verbose',
    fluent: {
      host: 'unify-fluentd',
      port: 24224,
      timeout: 3.0,
      requireAckResponse: false, // Add this option to wait response from Fluentd certainly
    },
  },

  //
  flags: {
    USE_ZXCVBN: false, // Use the zxcvbn password strength estimator
  },
};

module.exports = config;
