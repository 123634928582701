const isPRD = document.location.hostname === 'unify.eco';
const isPRP = document.location.hostname === 'prp.unify.eco';
const isUAT = document.location.hostname === 'demo.unify.eco' || document.location.hostname === 'staging.demo.unify.eco';
const isDEV = document.location.hostname === 'localhost';

export default {
  endpoint: isDEV ? 'https://staging.demo.unify.eco' : 'https://' + document.location.hostname,

  headers: {
    'X-Client-Id': '00000000-0000-0000-0000-000000000000',
  },
};
