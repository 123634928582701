import { isUndefined } from 'lodash';
import Logger from './Logger';
import Unify from './Unify';

const logger = new Logger('Marketplace');

/**
 *
 */
class Marketplace {
  /**
   * Return all marketplace items
   * @param type
   * @returns {Promise<any|never>}
   */
  async list() {
    return Unify._get('/api/marketplace/');
  }

  /**
   * Get a single marketplace item
   * @param id
   * @returns {Promise<any|never>}
   */
  async get(id) {
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._get(`/api/marketplace/${id}`);
  }

  /**
   * Create a new object
   *
   * @param type
   * @param obj
   * @returns {Promise<any|never>}
   */
  async create(partialObject) {
    if (isUndefined(partialObject)) throw new Error('partialObject is required');
    return Unify._post('/api/marketplace/', partialObject);
  }

  /**
   * Save complete object
   *
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async save(id, completeObject) {
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(completeObject)) throw new Error('completeObject is required');
    return Unify._put(`/api/marketplace/${id}`, completeObject);
  }

  /**
   * Partial update of existing object
   *
   * @param type
   * @param id
   * @param obj
   * @returns {Promise<any|never>}
   */
  async update(id, partialObject) {
    if (isUndefined(id)) throw new Error('id is required');
    if (isUndefined(partialObject)) throw new Error('partialObject is required');
    return Unify._patch(`/api/marketplace/${id}`, partialObject);
  }

  /**
   * Delete object
   *
   * @param type
   * @param id
   * @returns {Promise<any|never>}
   */
  async delete(id) {
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._delete(`/api/marketplace/${id}`);
  }

  /**
   * List all marketplace transactions
   * @returns {Promise<any|never>}
   */
  async transactions() {
    return Unify._get('/api/marketplace/transactions');
  }

  /**
   * Get a single marketplace transaction
   * @param id
   * @returns {Promise<any|never>}
   */
  async getTransaction(id) {
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._get(`/api/marketplace/transaction/${id}`);
  }

  /**
   * Initiate a return for this transaction
   * @param id
   * @returns {Promise<any|never>}
   */
  async initiateReturn(id, returnDetails) {
    if (isUndefined(id)) throw new Error('id is required');
    return Unify._post(`/api/marketplace/transaction/${id}/return`, returnDetails);
  }

  /**
   * Get outlet of marketplace item
   * @param id
   * @returns {Promise<any|never>}
   */
  async getOutlets() {
    return Unify._get('/api/location');
  }
}

export default new Marketplace();
