import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { CMS } from '../services/Unify';

const EVENT = 'event';

function* listEvent(action) {
  try {
    const result = yield call(CMS.list, EVENT);
    yield put({ type: 'EVENT_LIST_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: 'EVENT_LIST_FAILED', payload: e.message });
  }
}

function* getEvent(action) {
  try {
    const result = yield call(CMS.get, EVENT, action.payload);
    yield put({ type: 'EVENT_GET_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: 'EVENT_GET_FAILED', payload: e.message });
  }
}

function* deleteEvent(action) {
  try {
    const result = yield call(CMS.delete, EVENT, action.payload);
    yield put({ type: 'EVENT_DELETE_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: 'EVENT_DELETE_FAILED', payload: e.message });
  }
}

function* updateEvent(action) {
  try {
    const result = yield call(CMS.update, EVENT, action.id, action.payload);
    yield put({ type: 'EVENT_UPDATE_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: 'EVENT_UPDATE_FAILED', payload: e.message });
  }
}

function* eventAnalytics(action) {
  try {
    const result = yield call(CMS.analytics, EVENT, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* eventAttendees(action) {
  try {
    const result = yield call(CMS.attendees, EVENT, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* eventSaga() {
  yield takeLatest('EVENT_LIST', listEvent);
  yield takeEvery('EVENT_GET', getEvent);
  yield takeEvery('EVENT_DELETE', deleteEvent);
  yield takeEvery('EVENT_UPDATE', updateEvent);
  yield takeEvery('EVENT_GET_ANALYTICS', eventAnalytics);
  yield takeEvery('EVENT_GET_ATTENDEES', eventAttendees);
}

export default eventSaga;
