// Accounts
const accounts_admins_and_permissions = {
  role: 'accounts',
  resource: 'admin',
  name: 'Manage Admin Accounts & Permissions',
};

// Coin
const coins_create = {
  role: 'coins',
  resource: 'create',
  name: 'Create TOKEN',
};
const coins_request_topup = {
  role: 'coins',
  resource: 'request_topup',
  name: 'Request Top Up',
};
const coins_request_cashout = {
  role: 'coins',
  resource: 'request_cashout',
  name: 'Request Cash Out',
};
const coins_approve_topup = {
  role: 'coins',
  resource: 'approve_topup',
  name: 'Approve / Reject Top Up Requests',
};
const coins_approve_cashout1 = {
  role: 'coins',
  resource: 'approve_cashout1',
  name: 'Approve / Reject Cash Out Requests - 1st',
};
const coins_topup_nsc = {
  role: 'coins',
  resource: 'topup_nsc',
  name: 'Ad-Hoc Top Up UNIFY TOKEN to Department',
};
const coins_topup_partner = {
  role: 'coins',
  resource: 'topup_partner',
  name: 'Ad-Hoc Top Up UNIFY TOKEN to Partner',
};

// Transactions
const transactions_void = {
  role: 'transactions',
  resource: 'void',
  name: 'Void Transactions',
};

// Rewards
const rewards_create = {
  role: 'rewards',
  resource: 'create',
  name: 'Create & Manage Rewards',
};

// Content
const content_create_news = {
  role: 'content',
  resource: 'create_news',
  name: 'Create & Manage News & Events',
};
const content_create_broadcasts = {
  role: 'content',
  resource: 'create_broadcasts',
  name: 'Create & Manage Broadcasts',
};

// Manage
const manage_ag = {
  role: 'manage',
  resource: 'edit_ag',
  name: 'Edit Global Settings',
};
const edit_nsc = {
  role: 'manage',
  resource: 'edit_nsc',
  name: 'Edit Country Settings',
};
const manage_nsc = {
  role: 'manage',
  resource: 'nsc',
  name: 'Manage Departments',
};
const manage_partner = {
  role: 'manage',
  resource: 'partner',
  name: 'Manage Partners',
};
const manage_entity = {
  role: 'manage',
  resource: 'entity',
  name: 'Manage Entities',
};
const manage_outlet = {
  role: 'manage',
  resource: 'outlet',
  name: 'Manage Outlets',
};
const manage_sales_counter = {
  role: 'manage',
  resource: 'sales_counter',
  name: 'Sales Counters',
};

// Customer
const customer_accounts = {
  role: 'customer',
  resource: 'accounts',
  name: 'Manage User Accounts',
};
const customer_send_coins = {
  role: 'customer',
  resource: 'send_coins',
  name: 'Send TOKEN to users',
};

// Settings
const settings_organisation = {
  role: 'settings',
  resource: 'organisation',
  name: 'Organisation Settings',
};

export default {
  ag: [
    {
      role: 'accounts',
      name: 'Accounts',
      children: [accounts_admins_and_permissions],
    },
    {
      role: 'coins',
      name: 'TOKEN',
      children: [coins_create, coins_approve_topup, coins_topup_nsc],
    },
    {
      role: 'manage',
      name: 'Organisations',
      children: [manage_ag, manage_nsc],
    },
    {
      role: 'settings',
      name: 'Settings',
      children: [settings_organisation],
    },
  ],
  nsc: [
    {
      role: 'accounts',
      name: 'Accounts',
      children: [accounts_admins_and_permissions],
    },
    {
      role: 'coins',
      name: 'TOKEN',
      children: [
        coins_request_topup,
        coins_approve_topup,
        coins_approve_cashout1,
        // coins_approve_cashout2,
        coins_topup_partner,
      ],
    },
    {
      role: 'transactions',
      name: 'Transactions',
      children: [transactions_void],
    },
    {
      role: 'rewards',
      name: 'Rewards',
      children: [rewards_create],
    },
    {
      role: 'content',
      name: 'Content',
      children: [content_create_news, content_create_broadcasts],
    },
    {
      role: 'manage',
      name: 'Organisations',
      children: [edit_nsc, manage_partner, manage_entity, manage_outlet, manage_sales_counter],
    },
    {
      role: 'customer',
      name: 'User',
      children: [customer_accounts, customer_send_coins],
    },
    {
      role: 'settings',
      name: 'Settings',
      children: [settings_organisation],
    },

    // {
    //   role: 'manage_faq',
    //   name: 'FAQ',
    //   children: [manage_faq],
    // },

    // {
    //   role: 'manage_tc',
    //   name: 'Terms and Conditions',
    //   children: [manage_tc],
    // },
  ],

  department: [
    {
      role: 'coins',
      name: 'TOKEN',
      children: [coins_request_topup, coins_request_cashout],
    },
    {
      role: 'customer',
      name: 'User',
      children: [customer_accounts, customer_send_coins],
    },
  ],

  partner: [
    {
      role: 'accounts',
      name: 'Accounts',
      children: [accounts_admins_and_permissions],
    },
    {
      role: 'coins',
      name: 'TOKEN',
      children: [coins_request_topup, coins_request_cashout],
    },
    {
      role: 'transactions',
      name: 'Transactions',
      children: [transactions_void],
    },
    {
      role: 'manage',
      name: 'Organisations',
      children: [manage_entity, manage_outlet, manage_sales_counter],
    },
  ],
  entity: [
    {
      role: 'accounts',
      name: 'Accounts',
      children: [accounts_admins_and_permissions],
    },
    {
      role: 'transactions',
      name: 'Transactions',
      children: [transactions_void],
    },
    {
      role: 'manage',
      name: 'Organisations',
      children: [manage_outlet, manage_sales_counter],
    },
  ],
  outlet: [
    {
      role: 'accounts',
      name: 'Accounts',
      children: [accounts_admins_and_permissions],
    },
    {
      role: 'transactions',
      name: 'Transactions',
      children: [transactions_void],
    },
    {
      role: 'manage',
      name: 'Organisations',
      children: [manage_sales_counter],
    },
  ],
};

export const PERMISSION_TYPES = {
  CUSTOMER_SEND_COINS: 'customer_send_coins',
  EDIT_NSC: 'edit_nsc',
  MANAGE_NSC: 'manage_nsc',
  REQUEST_CASHOUT: 'request_cashout',
  CREATE_BROADCASTS: 'content_create_broadcasts',
  CREATE_NEWS: 'content_create_news',
  ADHOC_TOPUP_PARTNER: 'coins_topup_partner',
  CREATE_COINS: 'coins_create',
  ADHOC_TOPUP_NSC: 'coins_topup_nsc',
  APPROVE_REJECT_TOPUP: 'coins_approve_topup',
  APPROVE_REJECT_CASHOUT: 'coins_approve_cashout1',
  MANAGE_PERMISSIONS: 'accounts_admin',
  REQUEST_TOPUP: 'coins_request_topup',
  MANAGE_CUSTOMER_ACCOUNTS: 'customer_accounts',
  MANAGE_EDIT_NSC: 'manage_edit_nsc',
  COINS_REQUEST_CASHOUT: 'coins_request_cashout',
};

export const PERMISSION_TYPES_V2 = {
  TC: {
    VIEW: 'tc_view',
    CREATE: 'tc_create',
    UNPUBLISH: 'tc_unpublish',
  },
  FAQ: {
    VIEW: 'faqs_view',
    CREATE: 'faqs_create',
    UNPUBLISH: 'faqs_unpublish',
  },
  HOME: {
    TX_ANALYTICS: 'home_view_transactions_analytics',
    TOTAL_BALANCE_ANALYTICS: 'home_view_total_balance_analytics',
    TOTAL_PARTNERS_ANALYTICS: 'home_view_total_partners_analytics',
    TOKEN_BAR_CHART_ANALYTICS: 'home_view_token_bar_chart_analytics',
    TOKEN_PIE_CHART_ANALYTICS: 'home_view_token_pie_chart_analytics',
    TOTAL_CUSTOMERS_ANALYTICS: 'home_view_total_customers_analytics',
    TX_VALUE_ANALYTICS: 'home_view_transactions_value_analytics',
    MARKETING_CREDIT_ANALYTICS: 'home_view_marketing_credit_bar_chart_analytics',
    MARKETING_CREDIT: {
      BAR_CHART_ANALYTICS: 'home_view_marketing_credit_bar_chart_analytics',
      COVERSION_ANALYTICS: 'home_view_marketing_credit_conversion_analytics',
      BY_PARTNERS: 'home_view_marketing_credit_by_partners_analytics',
    },
  },
  WALLET: {
    DEPARTMENT: {
      VIEW: 'wallet_platform_department_view',
      TOPUP_REQUEST: 'wallet_platform_department_topup_request',
      TOPUP_APPROVE: 'wallet_platform_department_topup_approve',
      TOPUP_REJECT: 'wallet_platform_department_topup_reject',
      CREATE_COIN: 'wallet_platform_department_create_coin',
    },
    BROKER: {
      VIEW: 'wallet_broker_view',
    },
    PARTNER_EARNINGS: {
      VIEW: 'wallet_partner_earnings_view',
      CASHOUT_REQUEST: 'wallet_partner_earnings_cashout_request',
      CASHOUT_APPROVE: 'wallet_partner_earnings_cashout_approve',
      CASHOUT_REJECT: 'wallet_partner_earnings_cashout_reject',
    },
    PARTNER_MARKETING: {
      VIEW: 'wallet_partner_marketing_view',
      TOPUP_REQUEST: 'wallet_partner_marketing_topup_request',
      TOPUP_APPROVE: 'wallet_partner_marketing_topup_approve',
      TOPUP_REJECT: 'wallet_partner_marketing_topup_reject',
    },
  },
  INSIGHTS: {
    ADVANCED_VIEW: 'insights_advanced_view',
    CUSTOMER: {
      CAMPAIGN_ANALYTICS: 'insights_customer_campaign_analytics',
      DEMOGRAPHICS_ANALYTICS: 'insights_customer_demographics_analytics',
      TX_ANALYTICS: 'insights_customer_transactions_analytics',
    },
    APP: {
      APP_PERFORMANCE_ANALYTICS: 'insights_customer_app_performance_analytics',
    },
  },
  CAMPAIGNS: {
    GAMES: {
      VIEW: 'campaigns_games_view',
      CREATE: 'campaigns_games_create',
      UNPUBLISH: 'campaigns_games_unpublish',
    },
    REWARDS: {
      BURN: {
        VIEW: 'campaigns_rewards_burn_view',
        CONVERT_PRIZE: 'campaigns_rewards_burn_convert_prize',
        DEACTIVATE: 'campaigns_rewards_burn_deactivate',
        DUPLICATE: 'campaigns_rewards_burn_duplicate',
        SEND_CUSTOMERS: 'campaigns_rewards_burn_send_customers',
        CREATE: 'campaigns_rewards_burn_create',
        PUBLISH: 'campaigns_rewards_burn_publish',
      },
      EARN: {
        VIEW: 'campaigns_rewards_earn_view',
        CREATE: 'campaigns_rewards_earn_create',
        DEACTIVATE: 'campaigns_rewards_earn_deactivate',
        DUPLICATE: 'campaigns_rewards_earn_duplicate',
        PUBLISH: 'campaigns_rewards_earn_publish',
      },
    },
    PROMOCODE: {
      BURN: {
        VIEW: 'campaigns_promocode_burn_view',
        CREATE: 'campaigns_promocode_burn_create',
      },
      EARN: {
        VIEW: 'campaigns_promocode_earn_view',
        CREATE: 'campaigns_promocode_earn_create',
      },
    },
    MISSIONS: {
      VIEW: 'campaigns_missions_view',
      CREATE: 'campaigns_missions_create',
      UNPUBLISH: 'campaigns_missions_unpublish',
    },
  },
  MARKETPLACE: {
    SUMMARY: {
      VIEW: 'marketplace_summary_view',
    },
    TX: {
      VIEW: 'marketplace_transaction_view',
      VOID: 'marketplace_transaction_void',
    },
    SETTING: {
      VIEW: 'marketplace_settings_view',
      CONFIGURE_PAYMENT: 'marketplace_settings_configure_payment',
    },
    CATALOGUE: {
      VIEW: 'marketplace_catalogue_view',
      ADD: 'marketplace_catalogue_add',
    },
  },
  TX: {
    P2P_VIEW: 'transactions_p2p_view',

    CUSTOMER_VIEW: 'transactions_customer_view',
    CUSTOMER_VOID: 'transactions_customer_void',

    EMPLOYEE_VIEW: 'transactions_employee_view',

    SALES_CONSULTANT_VIEW: 'transactions_sales_consultant_view',
  },
  LEAD_MANAGEMENT: {
    VIEW: 'lead_management_view',
    EXPORT: 'lead_management_export',
    IMPORT: 'lead_management_import',
    DOWNLOAD_TEMPLATE: 'lead_management_download_template',
  },
  USER_MANAGEMENT: {
    CUSTOMER: {
      VIEW: 'user_management_customer_view',
      INVITE: 'user_management_customer_invite',
      ACTIVATE: 'user_management_customer_activate',
      DEACTIVATE: 'user_management_customer_deactivate',
      SEND_TOKEN: 'user_management_customer_send_token',
    },
    EMPLOYEE: {
      VIEW: 'user_management_employee_view',
      INVITE: 'user_management_employee_invite',
      ACTIVATE: 'user_management_employee_activate',
      DEACTIVATE: 'user_management_employee_deactivate',
      SEND_TOKEN: 'user_management_employee_send_token',
    },
    SALE_CONSULTANT: {
      VIEW: 'user_management_sales_consultant_view',
      INVITE: 'user_management_sales_consultant_invite',
      ACTIVATE: 'user_management_sales_consultant_activate',
      DEACTIVATE: 'user_management_sales_consultant_deactivate',
      SEND_TOKEN: 'user_management_sales_consultant_send_token',
    },
  },
  MARKETING_CONTENT: {
    NEWS: {
      VIEW: 'marketing_content_news_view',
      CREATE: 'marketing_content_news_create',
      UNPUBLISH: 'marketing_content_news_unpublish',
    },
    EVENTS: {
      VIEW: 'marketing_content_events_view',
      CREATE: 'marketing_content_events_create',
      UNPUBLISH: 'marketing_content_events_unpublish',
    },
    SURVEYS: {
      VIEW: 'marketing_content_surveys_view',
      CREATE: 'marketing_content_surveys_create',
      UNPUBLISH: 'marketing_content_surveys_unpublish',
    },
    BROADCASTS: {
      VIEW: 'marketing_content_broadcasts_view',
      CREATE: 'marketing_content_broadcasts_create',
      UNPUBLISH: 'marketing_content_broadcasts_unpublish',
    },
  },
  PERSONAL_SETTINGS: {
    VIEW: 'personal_settings_view',
    EDIT: 'personal_settings_edit',
  },
  PARTNER_MANAGEMENT: {
    ENTITY: {
      ADD: 'partner_management_entity_add',
      EDIT: 'partner_management_entity_edit',
      VIEW: 'partner_management_entity_view',
      EXPORT: 'partner_management_entity_export',
    },
    OUTLET: {
      ADD: 'partner_management_outlet_add',
      EDIT: 'partner_management_outlet_edit',
      VIEW: 'partner_management_outlet_view',
      EXPORT: 'partner_management_outlet_export',
    },
    COUNTER: {
      ADD: 'partner_management_counter_add',
      EDIT: 'partner_management_counter_edit',
      VIEW: 'partner_management_counter_view',
      DEACTIVATE: 'partner_management_counter_deactivate',
      EXPORT: 'partner_management_counter_export',
    },
    PARTNER: {
      ADD: 'partner_management_partner_add',
      EDIT: 'partner_management_partner_edit',
      VIEW: 'partner_management_partner_view',
      TOPUP: 'partner_management_partner_topup',
      INVITE: 'partner_management_partner_invite',
      EXPORT: 'partner_management_partner_export',
    },
  },
  SETTINGS_PERMISSIONS: {
    VIEW: 'settings_view',
    DETAILS_EDIT: 'manage_permissions_details_edit',
    ADMIN: {
      ADD: 'manage_permissions_admin_add',
      EDIT: 'manage_permissions_admin_edit',
      DEACTIVATE: 'manage_permissions_admin_deactivate',
      LIST: 'manage_permissions_admin_list',
    },
    SETTINGS: {
      MOBILE_APP: 'settings_manage_mobile_app',
      INTEGRATION: 'settings_manage_integration',
      OTHER_SETTINGS: 'settings_manage_other_settings',
    },
  },
  ENTITY_MANAGEMENT: {
    ENTITY: {
      ADD: 'partner_management_entity_add',
      EDIT: 'partner_management_entity_edit',
      VIEW: 'partner_management_entity_view',
      EXPORT: 'partner_management_entity_export',
    },
    OUTLET: {
      ADD: 'partner_management_outlet_add',
      EDIT: 'partner_management_outlet_edit',
      EXPORT: 'partner_management_outlet_export',
      VIEW: 'partner_management_outlet_view',
    },
    COUNTER: {
      ADD: 'partner_management_counter_add',
      DEACTIVATE: 'partner_management_counter_deactivate',
      EDIT: 'partner_management_counter_edit',
      EXPORT: 'partner_management_counter_export',
      VIEW: 'partner_management_counter_view',
    },
  },
  SECURITY: {
    SECURITY_AUDIT_TRAIL_VIEW: 'security_audit_trail_view',
    SECURITY_FRAUD_MANAGEMENT_VIEW: 'security_fraud_management_view',
    SECURITY_FRAUD_MANAGEMENT_EDIT: 'security_fraud_management_edit',
    SECURITY_FRAUD_MANAGEMENT_TRANSACTIONS_VIEW: 'security_fraud_management_transactions_view',
  },
};

// TODO: Code to generate below PERMISSION_GROUPS_V2. For later use
// export const PERMISSION_GROUPS_V2 = Object.keys(PERMISSION_TYPES_V2).reduce((result, current) => {
//   let value = current;
//   if (current === 'TX') {
//     value = 'TRANSACTIONS';
//   }

//   return {
//     ...result,
//     [current]: value.toLowerCase(),
//   };
// }, {});
//
// console.log(PERMISSION_GROUPS_V2);

export const PERMISSION_GROUPS_V2 = {
  TC: 'tc',
  FAQ: 'faq',
  HOME: 'home',
  WALLET: 'wallet',
  INSIGHTS: 'insights',
  CAMPAIGNS: 'campaigns',
  MARKETPLACE: 'marketplace',
  TX: 'transactions',
  LEAD_MANAGEMENT: 'lead_management',
  USER_MANAGEMENT: 'user_management',
  MARKETING_CONTENT: 'marketing_content',
  PERSONAL_SETTINGS: 'personal_settings',
  PARTNER_MANAGEMENT: 'partner_management',
  ENTITY_MANAGEMENT: 'entity_management',
  OUTLET_MANAGEMENT: 'outlet_management',
  SETTINGS_PERMISSIONS: 'settings_permissions',
  SECURITY: 'security',
};
