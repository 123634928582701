// Updates an entity cache in response to any action with response.entities.
export default function rewards(state = {}, action) {
  switch (action.type) {
    case 'REWARD_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    case 'REWARD_GET_SUCCESS':
      return Object.assign({}, state, {
        reward: action.payload,
      });
    case 'REWARD_GET_ANALYTICS_SUCCESS':
      return Object.assign({}, state, {
        analytics: action.payload,
      });
    case 'REWARD_GET_TRANSACTIONS_SUCCESS':
      return Object.assign({}, state, {
        transactions: action.payload.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          } else if (a.created_at < b.created_at) {
            return 1;
          } else {
            return 0;
          }
        }),
      });
    case 'REWARD_CLEAR':
      return Object.assign({}, state, {
        reward: undefined,
      });
    case 'REWARD_UPDATE_SUCCESS':
      return Object.assign({}, state, {
        reward: action.payload,
        reward_success: true,
      });
    case 'REWARD_UPDATE_FAILED':
      return Object.assign({}, state, {
        reward_success: false,
      });

    case 'REWARD_REMOVE_SUCCESSMESSAGE':
      return Object.assign({}, state, {
        reward_success: undefined,
      });
    default:
      return state;
  }
}
