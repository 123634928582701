import Logger from './Logger';
import Unify from './Unify';

const logger = new Logger('Customer');

/**
 *
 */
class Customer {
  /**
   *
   * @returns {Promise<any|never>}
   */
  async getProfile() {
    logger.debug('getProfile:');
    return Unify._get('/api/customer/profile');
  }

  /**
   *
   * @param query
   * @returns {Promise<*>}
   */
  async search(query) {
    return Unify._post('/api/customer/search', { query });
  }
}

export default new Customer();
