import Logger from './Logger';
import Unify from './Unify';

const logger = new Logger('Actions');

/**
 *
 */
class Actions {
  constructor() {}

  async CreateCoins(form) {
    return Unify._post('/api/admin/coins/commands/CreateCoins', form);
  }

  async VoidTransaction(form) {
    return Unify._post('/api/admin/action/VoidTransaction', form);
  }

  async SendTopUp(form) {
    return Unify._post('/api/admin/coins/commands/SendTopUp', form);
  }

  async RequestTopUp(form) {
    return Unify._post('/api/admin/coins/commands/RequestTopUp', form);
  }

  async RequestTopUpV2(type, form) {
    return Unify._post(`/api/admin/coins/commands/${type}/RequestTopUp`, form);
  }

  async ApproveTopUp(type, form) {
    return Unify._post(`/api/admin/coins/commands/${type}/ApproveTopUp`, form);
  }

  async RejectTopUp(type, form) {
    return Unify._post(`/api/admin/coins/commands/${type}/RejectTopUp`, form);
  }

  async RequestCashOut(form) {
    return Unify._post('/api/admin/coins/commands/RequestCashOut', form);
  }

  async ApproveCashOut(form) {
    return Unify._post('/api/admin/coins/commands/ApproveCashOut', form);
  }

  async RejectCashOut(form) {
    return Unify._post('/api/admin/coins/commands/RejectCashOut', form);
  }

  async AddAdminAccount(form) {
    return Unify._post('/api/admin/action/AddAdminAccount', form);
  }

  async UpdateAdminAccount(form) {
    return Unify._post('/api/admin/action/UpdateAdminAccount', form);
  }

  async ActivateAdminAccount(form) {
    return Unify._post('/api/admin/action/ActivateAdminAccount', form);
  }

  async DeactivateAdminAccount(form) {
    return Unify._post('/api/admin/action/DeactivateAdminAccount', form);
  }

  async SendCustomerCoins(form) {
    return Unify._post('/api/admin/coins/commands/SendCustomerCoins', form);
  }

  async CheckEmail(form) {
    return Unify._post('/api/bmw/check-email', form);
  }
}

export default new Actions();
