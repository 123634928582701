import faker from 'faker';
import moment from 'moment';
import numeral from 'numeral';
import Utils from '../../Utils';
import Admin from './Admin';
import Unify from './Unify';
import User from './User';

/**
 *
 */
class Analytics {
  constructor() {
    const instance = this;

    this.timer = setInterval(function () {
      const customers = Math.floor(Math.random() * 5);
      instance.global.customers += customers;
      instance.country.KR.customers += customers;

      const transactions = Math.floor(Math.random() * 3);
      instance.global.transactions += transactions;
      instance.country.KR.transactions += transactions;

      instance.country.KR.signups += customers;
      instance.country.KR.referrals += Math.floor(customers / 2);
    }, 1000);

    // this.generateInitialData();
    // this.updatePeriodicData();

    // this.refresh = setInterval(() => {
    //   this.updatePeriodicData();
    // }, 60000);
  }

  load(action) {
    return Unify._get(`/api/analytics/summary?start_date=${action.startDate}&end_date=${action.endDate}`);
  }

  getAnalytics(analyticsType, entityType, entityId, startDate, endDate) {
    let url = `/api/analytics/${analyticsType}/${entityType}/${entityId}`;
    if (startDate && endDate) {
      url += `?start_date=${startDate}&end_date=${endDate}`;
    }
    return Unify._get(url);
  }

  getDemographics(type, option, sort, limit) {
    let url = `/api/analytics/insights/customers/demographics/${type}`;
    if (option) {
      url += `/${option}`;
    }
    if (sort && limit) {
      url += `?sort=${sort}&limit=${limit}`;
    }
    return Unify._get(url);
  }

  customers(action) {
    const { startDate, endDate } = action;
    return Unify._get(`/api/analytics/customers?start_date=${startDate}&end_date=${endDate}`);
  }

  partnersAnalytics(action) {
    const { startDate, endDate, type, id, sort = null } = action;
    let url = `/api/analytics/partners-analytics/${type}?`;
    if (startDate) {
      url += `start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (id) {
      url += `&id=${id}`;
    }
    if (sort === 'asc') {
      url += `&sort=${sort}`;
    }
    return Unify._get(url);
  }

  activeCustomers(action) {
    const { startDate, endDate, activeTab } = action;
    return Unify._get(`/api/analytics/customers/customer-summary?start_date=${startDate}&end_date=${endDate}&status=${activeTab}`);
  }
  activeSales(action) {
    const { startDate, endDate, activeTab } = action;
    return Unify._get(`/api/analytics/customers/sales-summary?start_date=${startDate}&end_date=${endDate}&status=${activeTab}`);
  }
  activeEmployees(action) {
    const { startDate, endDate, activeTab } = action;
    return Unify._get(`/api/analytics/customers/employee-summary?start_date=${startDate}&end_date=${endDate}&status=${activeTab}`);
  }

  customersInsights(action) {
    return Unify._get(`/api/analytics/customer-insights?start_date=${action.startDate}&end_date=${action.endDate}`);
  }

  rewards(action) {
    return Unify._get(`/api/analytics/rewards?start_date=${action.startDate}&end_date=${action.endDate}`);
  }

  customerActivity(action) {
    return Unify._get(`/api/analytics/customer-activity?start_date=${action.startDate}&end_date=${action.endDate}`);
  }

  spenderCategories(action) {
    return Unify._get(`/api/analytics/spender-categories?start_date=${action.startDate}&end_date=${action.endDate}`);
  }

  getCustomerPool(action) {
    return Unify._get(`/api/product/product/${action.id}/audience`);
  }
  getBurnCustomerPool(action) {
    return Unify._get(`/api/product/product/burn/${action.id}/audience`);
  }
  getEarnCustomerPool(action) {
    return Unify._get(`/api/product/product/earn/${action.id}/audience`);
  }

  updateCustomerPool(action) {
    return Unify._put(`/api/product/product/${action.id}/audience`, action.data);
  }

  updateBurnCustomerPool(action) {
    return Unify._put(`/api/product/product/burn/${action.id}/audience`, action.data);
  }

  updateEarnCustomerPool(action) {
    return Unify._put(`/api/product/product/earn/${action.id}/audience`, action.data);
  }

  ecosystemAnalysis(action) {
    return Unify._get('/api/analytics/ecosystem-analysis');
  }

  geolocationHeatmap(action) {
    return Unify._get('/api/analytics/geolocation-heatmap');
  }

  async fraudManagementAnalytics({ startDate, endDate }) {
    return Unify._get(`/api/analytics/fraud/management?start_date=${startDate}&end_date=${endDate}`);
  }

  generateInitialData() {
    faker.locale = 'ko';

    //
    // Coins
    const coins = [];
    for (var i = 0; i < 100; i++) {
      const id = Math.random().toString(36).substring(2, 10).toUpperCase();
      const status = Math.random() > 0.33 ? 'completed' : 'pending';
      const seconds = Math.floor(Math.random() * (86400 * 30));
      const timestamp = moment().subtract(seconds, 'seconds').format();
      coins.push({
        timestamp,
        activity_id: id,
        type: 'Top up',
        organisation: 'UNIFY HQ',
        entity: 'UNIFY HQ',
        entityType: faker.random.arrayElement(['NSC']),
        from: {
          entity: 'UNIFY HQ',
          entityType: 'UNIFY HQ',
        },
        to: {
          entity: 'UNIFY HQ',
          entityType: faker.random.arrayElement(['NSC']),
        },
        amount: (Math.floor(Math.random() * 100) + 1) * 1000,
        status,
        purpose: status === 'pending' ? 'Top up balance' : null,
        startedBy: faker.name.lastName() + ' ' + faker.name.firstName(),
      });

      if (status === 'pending' && i < 10) {
        User.addTodo({
          id,
          type: 'warning',
          icon: 'cui-star',
          message: 'TOKEN Request',
          link: `#/coins/${id}`,
          status: 'Pending Approval',
          date: timestamp,
        });
      }
    }
    coins.push({
      timestamp: moment()
        .subtract(86400 * 30, 'seconds')
        .format(),
      activity_id: Math.random().toString(36).substring(2, 10).toUpperCase(),
      type: 'Top up',
      organisation: 'Starbucks',
      entity: 'Starbucks',
      entityType: faker.random.arrayElement(['Partner']),
      amount: -16000,
      status: 'completed',
      purpose: 'Top up balance',
      startedBy: faker.name.lastName() + ' ' + faker.name.firstName(),
    });
    this.coins.rows = coins;
    Admin.getTransactions('all').then((results) => {
      if (results) {
        this.coins.rows = results.concat(this.coins.rows);
      } else {
        this.coins.rows = [];
      }
    });

    //
    // Transactions
    const transactions = [];
    // eslint-disable-next-line
    for (var i = 0; i < 100; i++) {
      const status = Math.random() > 0.05 ? 'successful' : 'voided';
      const seconds = Math.floor(Math.random() * (86400 * 30));
      const value = (Math.floor(Math.random() * 100) + 1) * 1000;
      transactions.push({
        timestamp: moment().subtract(seconds, 'seconds').format(),
        activity_id: Math.random().toString(36).substring(2, 10).toUpperCase(),
        type: 'Burn',
        nsc: 'UNIFY HQ',
        partner: 'Starbucks',
        entity: 'Starbucks Coffee Korea Co Ltd',
        entityType: faker.random.arrayElement(['Partner']),
        outlet: 'Myeongdong Jungang',
        customer_id: Math.random().toString(36).substring(2, 10).toUpperCase(),
        amount: value,
        fiatAmount: value,
        coinsEarned: 0,
        status,
        void: status === 'voided' ? { reason: 'Incorrect Payment' } : null,
      });
    }
    this.transactions.rows = transactions;

    //
    // Rewards
    Admin.getData('rewards').then((rewards) => {
      if (rewards) {
        this.rewards.rows = rewards.sort(Utils.sortByFeatured);
      } else {
        this.rewards.rows = [];
      }
    });

    //
    // Services
    Admin.getData('services').then((services) => {
      this.services.rows = services;
    });
  }

  exchange_rate = {
    currency: 'SGD',
    rate: 1.0,
    format: '0',
  };

  exchange_rates = [
    {
      currency: 'SGD',
      rate: 1.0,
      format: '0,0',
    },
    {
      currency: 'USD',
      rate: 0.625,
      format: '0.00',
    },
  ];

  getFormattedExchangeRate = () => {
    return `${numeral(this.exchange_rate.rate).format(this.exchange_rate.format)} ${this.exchange_rate.currency}`;
  };

  /**
   * Polling
   */
  updatePeriodicData() {
    Admin.getBalance('ag')
      .then((result) => {
        if (result.data) {
          this.global.coins_balance = parseInt(result.data);
          this.country.GLOBAL.coins_balance = parseInt(result.data);
        }
      })
      .catch((err) => {});
    Admin.getBalance('kr')
      .then((result) => {
        if (result.data) {
          this.country.KR.coins_balance = parseInt(result.data);
        }
      })
      .catch((err) => {});
    Admin.getBalance('starbucks')
      .then((result) => {
        if (result.data) {
          this.country.STARBUCKS.coins_balance = parseInt(result.data);
          this.partner.STARBUCKS.coins_balance = parseInt(result.data);
        }
      })
      .catch((err) => {});
  }

  /**
   * Events
   * @param event
   */
  handleEvent = (event) => {
    const data = JSON.parse(event.data);
    if ('type' in data) {
      if (data['type'] === 'getBalance') {
        const account = data['account'];
        const balance = data['balance'];
      }
    }
  };

  global = {
    coins_balance: 0,
    coins_balance_fiat: 0,
    coins_distributed: 400000,
    transactions: 10560,
    customers: 800000,
  };

  country = {
    GLOBAL: {
      coins_balance: 0,
      coins_balance_fiat: 0,
      coins_distributed: 350000,
      transactions: 10560,
      customers: 800000,
      signups: 800,
      referrals: 320,
    },
    KR: {
      coins_balance: 0,
      coins_balance_fiat: 0,
      coins_distributed: 350000,
      transactions: 10560,
      customers: 800000,
      signups: 800,
      referrals: 320,
    },
    STARBUCKS: {
      coins_balance: 0,
      coins_balance_fiat: 0,
      coins_distributed: 880,
      transactions: 560,
      customers: 800,
      signups: 10,
      referrals: 20,
    },
  };

  partner = {
    STARBUCKS: {
      coins_balance: 0,
      coins_distributed: 880,
      transactions: 560,
      customers: 800,
      signups: 10,
      referrals: 20,
    },
  };

  coins = {
    rows: [],
  };

  transactions = {
    rows: [],
  };

  services = {
    rows: [],
  };

  getTransactionById = (id) => {
    const transactions = [].concat(this.coins.rows).concat(this.transactions.rows);
    for (let i = 0; i < transactions.length; i++) {
      const row = transactions[i];
      if (row !== null) {
        if (row['activity_id'] === id) {
          return Object.assign({}, row, {
            id: row.activity_id,
            datetime: row.timestamp,
            type: row.type,
            amount: {
              amount: row.amount,
              currency: 'JOY',
            },
            location: {
              name: row.entity,
            },
            status: row.status,
          });
        }
      }
    }
    return null;
  };

  voidTransactionById = (id, reason) => {
    for (let i = 0; i < this.transactions.rows.length; i++) {
      const row = this.transactions.rows[i];
      if (row['activity_id'] === id) {
        row.status = 'voided';
        row.void = { reason };
      }
    }
  };

  rejectTransactionById = (id, reason) => {
    for (let i = 0; i < this.coins.rows.length; i++) {
      const row = this.coins.rows[i];
      if (row['activity_id'] === id) {
        row.status = 'rejected';
        row.reject = { reason };
      }
    }
  };

  approveTransactionById = (id) => {
    for (let i = 0; i < this.coins.rows.length; i++) {
      const row = this.coins.rows[i];
      if (row['activity_id'] === id) {
        row.status = 'completed';

        // Update totals
        if (row['entityType'] === 'NSC') {
          this.global.coins_balance -= row.amount;
          this.country.GLOBAL.coins_balance -= row.amount;
          this.country.GLOBAL.coins_distributed += row.amount;
          this.country.KR.coins_balance += row.amount;
        }
        if (row['entityType'] === 'Partner') {
          this.country.KR.coins_balance -= row.amount;
          this.country.KR.coins_distributed += row.amount;
          this.country.STARBUCKS.coins_balance += row.amount;
        }
      }
    }
  };
}

export default new Analytics();
