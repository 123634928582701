import React from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastr';
import { NoIENotification } from './components/PermanentNotification';
import { LocalizationProvider, withLocalization } from './i18n';

class Page extends Route {
  render() {
    const { name, store, ...rest } = this.props;

    // Sometimes the Toast gets stuck
    const clearToast = () => {
      const toastr = window.toastr;
      if (toastr) {
        toastr.clear();

        // Forcefully remove them
        const container = document.getElementById('toast-container');
        const children = container.childNodes;
        for (const key in children) {
          if (children[key]) {
            if (children[key].style) {
              children[key].style.display = 'none';
            }
          }
        }
      }
    };

    return (
      <LocalizationProvider store={store}>
        <NoIENotification />
        <Route {...rest} store={store} />
        <ToastContainer ref={(ref) => (window.toastr = ref)} className="toast-top-center" onClick={clearToast} />
      </LocalizationProvider>
    );
  }
}

export default withLocalization(Page);
