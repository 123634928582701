// Updates an entity cache in response to any action with response.entities.
export default function partners(state = {}, action) {
  switch (action.type) {
    case 'PARTNER_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    case 'PARTNER_GET_SUCCESS':
      return Object.assign({}, state, {
        partner: action.payload,
      });
    case 'PARTNER_GET_ANALYTICS_SUCCESS':
      return Object.assign({}, state, {
        analytics: action.payload,
      });
    case 'PARTNER_GET_TRANSACTIONS_SUCCESS':
      return Object.assign({}, state, {
        transactions: action.payload,
      });
    case 'PARTNER_CLEAR':
      return Object.assign({}, state, {
        partner: undefined,
      });
    default:
      return state;
  }
}
