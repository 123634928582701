// Updates an entity cache in response to any action with response.entities.

export default function services(state = {}, action) {
  switch (action.type) {
    case 'SERVICES_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    default:
      return state;
  }
}
