import { EventSourcePolyfill } from 'event-source-polyfill';
import Auth from './Auth';
import config from './config';
import Unify from './Unify';

/**
 *
 */
class Data {
  constructor() {
    this.store = null;
    this.started = false;
    this.evtSource = null;
    this.websocket = null;
  }

  initialise(props) {
    // Defer startup if the user is not logged in
    if (!Auth.isAuthenticated()) {
      return setTimeout(() => {
        this.initialise(props);
      }, 1000);
    }

    this.store = props.store;

    // Prefer EventSource, though it's not supported on Internet Explorer
    // this.startEventSource();

    // Try W3CWebSocket
    // if (!this.started) {
    //   this.startW3CWebSocket();
    // }

    // Fallback to Polling? Really?
  }

  /**
   *
   */
  startEventSource = () => {
    try {
      // We use a polyfill here because we want to set the Authorization header
      const EventSource = EventSourcePolyfill;
      this.evtSource = new EventSource(`${config.endpoint}/api/admin/events`, {
        headers: {
          Authorization: 'Bearer ' + Unify.getToken(),
        },
      });
      this.evtSource.addEventListener('event', this.handleEvent, false);
      this.evtSource.onopen = () => {};
      this.evtSource.onerror = (e) => {
        console.error('EventSource error:', e);
        e.preventDefault();
      };

      this.started = true;
    } catch (err) {
      console.error('Failed to start data connection with EventSource', err);
    }
  };

  /**
   *
   */
  startW3CWebSocket = () => {
    try {
      const W3CWebSocket = require('websocket').w3cwebsocket;
      let reconnectTimeout = null;

      const clearRetry = () => {
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
          reconnectTimeout = null;
        }
      };

      const retry = () => {
        clearRetry();
        reconnectTimeout = setTimeout(() => {
          this.websocket = connect();
        }, 5000);
      };

      const connect = () => {
        const client = new W3CWebSocket(`${config.endpoint}/api/admin/eventsws`, 'echo-protocol');
        client.onerror = function (err) {
          console.error('W3CWebSocket error', err);
          retry();
        };
        client.onopen = function () {
          clearRetry();
        };
        client.onclose = function () {
          retry();
        };
        const instance = this;
        client.onmessage = function (event) {
          if (typeof event.data === 'string') {
            instance.handleEvent(event);
          }
        };
        return client;
      };

      this.websocket = connect();
      this.started = true;
    } catch (err) {
      console.error('Failed to start data connection with W3CWebSocket', err);
    }
  };

  /**
   * Receives server-side events and forwards them to Redux
   * @param event
   */
  handleEvent = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (typeof data.type !== 'undefined') {
        if (data.type !== 'PING') {
          this.store.dispatch(data);
        }
      }
    } catch (err) {
      console.error('Failed to parse message:', event, err);
    }
  };
}

export default new Data();
