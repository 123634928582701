import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './polyfill';
import { Data } from './services/Unify';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const store = configureStore();

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '445738bb0cb161c8f95253aeca04d873',
    plugins: [new BugsnagPluginReact(React)],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary
      beforeSend={(report) => {
        if (report.errorClass === 'ChunkLoadError') {
          window.location.reload();
        }
      }}
    >
      <App store={store} />
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<App store={store} />, document.getElementById('root'));
}

// Initialise the data connection
Data.initialise({ store });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
