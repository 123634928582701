import Logger from './Logger';
import Unify from './Unify';

const logger = new Logger('Email');

/**
 *
 */
class Email {
  constructor() {}

  /**
   *
   * @returns {Promise<any|never>}
   */
  async eventInvite(params) {
    return Unify._post('/api/admin/email/invite-event', params);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async partnerInvite(params) {
    return Unify._post('/api/admin/email/invite-partner', params);
  }
}

export default new Email();
