import { BrowserRouter, HashRouter, Redirect, Switch } from 'react-router-dom';
import React, { Suspense, useMemo, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import { Auth } from './services/Unify';
import { dark, light } from './theme';
import Page from './Page';
import Nav from './_nav';
import './App.scss';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading,
});

// const Forgot = Loadable({
//   loader: () => import('./views/Pages/Forgot'),
//   loading,
// });

const Reset = Loadable({
  loader: () => import('./views/Pages/Reset'),
  loading,
});

const Deeplink = Loadable({
  loader: () => import('./views/Pages/Deeplink'),
  loading,
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading,
});

const OnboardingPartner = Loadable({
  loader: () => import('./views/Onboarding/Partner'),
  loading,
});

const Activate = Loadable({
  loader: () => import('./views/Pages/Activate'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading,
});

/**
 * Generates the login redirect
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  // always start with "/"
  // TODO: move this somewhere else in config
  const excludedPaths = ['/vantage-customer'];

  const [accessibleRoutes, setAccessibleRoutes] = useState({});

  const extractRoutes = (items) => {
    return items.reduce((result, currentItem) => {
      if (currentItem.url) {
        result[currentItem.url] = true;
      }
      if (currentItem.children) {
        currentItem.children.map((child) => child.url).forEach((url) => (result[url] = true));
      }

      return result;
    }, {});
  };

  const mapAccessibleRoutes = () => {
    const routes = extractRoutes(Nav.nav);
    const routesChildren = extractRoutes(Nav.navChildren);
    const marketplaceRoutes = extractRoutes([Nav.marketplace]);

    setAccessibleRoutes({ ...routes, ...routesChildren, ...marketplaceRoutes });
  };

  // Read all accessible routes from _nav and store in Map
  useEffect(() => {
    mapAccessibleRoutes();

    const storageListener = addEventListener('storage', (event) => {
      if (event?.detail?.key === 'permissions') {
        mapAccessibleRoutes();
      }
    });

    return () => removeEventListener('storage', storageListener);
  }, []);

  return (
    <Page
      {...rest}
      render={(props) => {
        const path = window.location.pathname;
        const excluded = excludedPaths.filter((ex) => path.startsWith(ex)).length > 0;

        if (excluded) return null;

        if (!Auth.isAuthenticated()) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        const currentPath = props.history.location.pathname;
        if (accessibleRoutes[currentPath]) {
          return <Component {...props} />;
        }

        // TODO: Improve this checking logic later
        // Check further path accessibility. e.g. /rewards/:id
        const currentPathChildren = currentPath.split('/').slice(1);
        if (currentPathChildren.length > 1) {
          const possibleRoutes = currentPathChildren.map((child, index) => {
            let result = '';
            for (let i = 0; i < index; i++) {
              result += `/${currentPathChildren[i]}`;
            }
            return result + `/${child}`;
          });

          for (let i = 0; i < possibleRoutes.length; i++) {
            if (accessibleRoutes[possibleRoutes[i]]) {
              return <Component {...props} />;
            }
          }
        }

        return <Redirect to={{ pathname: '/dashboard' }} />;
      }}
    />
  );
};

/**
 *
 */
function App(props) {
  const theme = useMemo(() => {
    return createTheme(props.isDarkTheme ? dark : light);
  }, [props.isDarkTheme]);

  return (
    <div>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content="frame-src https://*"></meta>
      </Helmet>
      <Provider store={props.store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={loading()}>
            <HashRouter>
              <Switch>
                <Page exact path="/login" name="Login" component={Login} store={props.store} />
                <Page
                  exact
                  path="/forgot"
                  name="Forgot"
                  component={Login} // Disabled feature
                  store={props.store}
                />
                <Page
                  exact
                  path="/reset/:token"
                  name="Reset"
                  component={Login} // Disabled feature
                  store={props.store}
                />
                <Page exact path="/deeplink/:scheme" name="Deeplink" component={Deeplink} store={props.store} />
                <Page exact path="/register/:token" name="Register" component={Register} store={props.store} />
                <Page exact path="/onboarding/partner/:token" name="Onboarding" component={OnboardingPartner} store={props.store} />
                <Page exact path="/activate/:token" name="Activate" component={Activate} store={props.store} />
                <Page exact path="/404" name="Page 404" component={Page404} store={props.store} />
                <Page exact path="/500" name="Page 500" component={Page500} store={props.store} />
                <PrivateRoute path="/" name="Home" component={DefaultLayout} store={props.store} />
                {/*<DevTools />*/}
              </Switch>
            </HashRouter>
            <BrowserRouter>
              <Switch>
                <Page exact path="/vantage-customer/:token" name="ResetV2" component={Reset} store={props.store} />
              </Switch>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isDarkTheme: state.user.isDarkTheme,
});

export default connect(mapStateToProps, null)(App);
