import { call, put, takeLatest } from 'redux-saga/effects';
import { Analytics } from '../services/Unify';

function* load_analytics(action) {
  try {
    const result = yield call(Analytics.load, action.payload);
    yield put({ type: 'ANALYTICS_UPDATE', payload: result });
  } catch (e) {
    yield put({ type: 'ANALYTICS_FAILED', message: e.message });
  }
}

function* analytics_customers(action) {
  try {
    const result = yield call(Analytics.customers, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_active_customers(action) {
  try {
    const result = yield call(Analytics.activeCustomers, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_active_sales(action) {
  try {
    const result = yield call(Analytics.activeSales, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_active_employees(action) {
  try {
    const result = yield call(Analytics.activeEmployees, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customers_insights(action) {
  try {
    const result = yield call(Analytics.customersInsights, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customers_insights_six_months(action) {
  try {
    const result = yield call(Analytics.customersInsights, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customers_insights_thirty_days(action) {
  try {
    const result = yield call(Analytics.customersInsights, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_rewards(action) {
  try {
    const result = yield call(Analytics.rewards, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customer_activity(action) {
  try {
    const result = yield call(Analytics.customerActivity, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_spender_categories(action) {
  try {
    const result = yield call(Analytics.spenderCategories, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customer_pool(action) {
  try {
    const result = yield call(Analytics.getCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_burn_customer_pool(action) {
  try {
    const result = yield call(Analytics.getBurnCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_earn_customer_pool(action) {
  try {
    const result = yield call(Analytics.getEarnCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_customer_pool_update(action) {
  try {
    const result = yield call(Analytics.updateCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_burn_customer_pool_update(action) {
  try {
    const result = yield call(Analytics.updateBurnCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}
function* analytics_earn_customer_pool_update(action) {
  try {
    const result = yield call(Analytics.updateEarnCustomerPool, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_ecosystem_analysis(action) {
  try {
    const result = yield call(Analytics.ecosystemAnalysis, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analytics_geolocation_heatmap(action) {
  try {
    const result = yield call(Analytics.geolocationHeatmap, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* analyticsSaga() {
  yield takeLatest('ANALYTICS_LOAD', load_analytics);
  yield takeLatest('ANALYTICS_CUSTOMERS', analytics_customers);
  yield takeLatest('ANALYTICS_ACTIVE_CUSTOMERS', analytics_active_customers);
  yield takeLatest('ANALYTICS_ACTIVE_SALES', analytics_active_sales);
  yield takeLatest('ANALYTICS_ACTIVE_EMPLOYEES', analytics_active_employees);
  yield takeLatest('ANALYTICS_CUSTOMERS_INSIGHTS', analytics_customers_insights);
  yield takeLatest('ANALYTICS_CUSTOMERS_INSIGHTS_SIX_MONTHS', analytics_customers_insights_six_months);
  yield takeLatest('ANALYTICS_CUSTOMERS_INSIGHTS_THIRTY_DAYS', analytics_customers_insights_thirty_days);
  yield takeLatest('ANALYTICS_REWARDS', analytics_rewards);
  yield takeLatest('ANALYTICS_CUSTOMER_ACTIVITY', analytics_customer_activity);
  yield takeLatest('ANALYTICS_SPENDER_CATEGORIES', analytics_spender_categories);
  yield takeLatest('ANALYTICS_CUSTOMER_POOL', analytics_customer_pool);
  yield takeLatest('ANALYTICS_BURN_CUSTOMER_POOL', analytics_burn_customer_pool);
  yield takeLatest('ANALYTICS_EARN_CUSTOMER_POOL', analytics_earn_customer_pool);
  yield takeLatest('ANALYTICS_CUSTOMER_POOL_UPDATE', analytics_customer_pool_update);
  yield takeLatest('ANALYTICS_BURN_CUSTOMER_POOL_UPDATE', analytics_burn_customer_pool_update);
  yield takeLatest('ANALYTICS_EARN_CUSTOMER_POOL_UPDATE', analytics_earn_customer_pool_update);
  yield takeLatest('ANALYTICS_ECOSYSTEM_ANALYSIS', analytics_ecosystem_analysis);
  yield takeLatest('ANALYTICS_GEOLOCATION_HEATMAP', analytics_geolocation_heatmap);
}

export default analyticsSaga;
