import Logger from './Logger';
import User from './User';

const logger = new Logger('Client');

/**
 *
 */
class Client {
  constructor() {}

  /**
   * Return the user segments
   * @returns {*[]|*}
   */
  getSegments() {
    const client = User.getClient();
    if (client) {
      return client.segments;
    } else {
      return [];
    }
  }

  // /**
  //  *
  //  * @returns {Promise<any|never>}
  //  */
  // async list() {
  //   return Unify._get(`/api/admin/coins`);
  // }
  //
  // /**
  //  *
  //  * @returns {Promise<any|never>}
  //  */
  // async get(id) {
  //   return Unify._get(`/api/admin/coins/${id}`);
  // }
  //
  // /**
  //  *
  //  * @param settings
  //  * @returns {Promise<any|never>}
  //  */
  // async put(id, obj) {
  //   return Unify._put(`/api/admin/coins/${id}`, obj);
  // }
  //
  // /**
  //  *
  //  * @param id
  //  * @returns {Promise<any|never>}
  //  */
  // async delete(id) {
  //   return Unify._delete(`/api/admin/coins/${id}`);
  // }
  //
  // /**
  //  *
  //  * @param id
  //  * @param obj
  //  * @returns {Promise<any|never>}
  //  */
  // async update(id, obj) {
  //   return Unify._patch(`/api/admin/coins/${id}`, obj);
  // }
  //
  // /**
  //  *
  //  * @returns {Promise<any|never>}
  //  */
  // async approve(id) {
  //   return Unify._get(`/api/admin/coins/${id}/approve`);
  // }
  //
  // /**
  //  *
  //  * @returns {Promise<any|never>}
  //  */
  // async balance(account) {
  //   return Unify._get(`/api/admin/coins/balance/${account}`);
  // }
  //
  // /**
  //  *
  //  * @returns {Promise<any|never>}
  //  */
  // async mint(params) {
  //   return Unify._post(`/api/admin/coins/mint`, params);
  // }
}

export default new Client();
