export default function analytics(state = {}, action) {
  switch (action.type) {
    case 'ANALYTICS_UPDATE': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_CUSTOMERS_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_ACTIVE_CUSTOMERS_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_CUSTOMERS_INSIGHTS_SUCCESS': {
      const { ageData, genderData, countryData, tierData, interestsData, geolocationData, notificationsData } = action.payload;
      return Object.assign({}, state, {
        ageData,
        genderData,
        countryData,
        tierData,
        interestsData,
        geolocationData,
        notificationsData,
      });
    }
    case 'ANALYTICS_CUSTOMERS_INSIGHTS_SIX_MONTHS_SUCCESS': {
      const { totalCustomerSpend, totalPurchasesData, newUsersData, customerLifetimeValueData } = action.payload;
      return Object.assign({}, state, {
        totalCustomerSpend,
        totalPurchasesData,
        newUsersData,
        customerLifetimeValueData,
      });
    }
    case 'ANALYTICS_CUSTOMERS_INSIGHTS_THIRTY_DAYS_SUCCESS': {
      const { activeUserData, sessionDuration, userSessions } = action.payload;
      return Object.assign({}, state, {
        activeUserData,
        sessionDuration,
        userSessions,
      });
    }
    case 'ANALYTICS_REWARDS_SUCCESS': {
      return Object.assign({}, state, {
        rewards: action.payload,
      });
    }
    case 'ANALYTICS_CUSTOMER_ACTIVITY_SUCCESS': {
      return Object.assign({}, state, {
        customerActivity: action.payload,
      });
    }
    case 'ANALYTICS_SPENDER_CATEGORIES_SUCCESS': {
      return Object.assign({}, state, {
        spenderCategories: action.payload,
      });
    }
    case 'ANALYTICS_CUSTOMER_POOL_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_BURN_CUSTOMER_POOL_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_EARN_CUSTOMER_POOL_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_CUSTOMER_POOL_UPDATE_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_BURN_CUSTOMER_POOL_UPDATE_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_EARN_CUSTOMER_POOL_UPDATE_SUCCESS': {
      return Object.assign({}, state, action.payload);
    }
    case 'ANALYTICS_ECOSYSTEM_ANALYSIS_SUCCESS': {
      return Object.assign({}, state, {
        ecosystemAnalysis: action.payload,
      });
    }
    case 'ANALYTICS_GEOLOCATION_HEATMAP_SUCCESS': {
      return Object.assign({}, state, {
        geolocationHeatmap: action.payload,
      });
    }
    default:
      return state;
  }
}
