import { Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { capitalize } from 'lodash';
import React, { Suspense } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CustomChip from './components/Widget/Chip';
import i18n, { moment, numeral } from './i18n';
/* eslint-disable react/no-unknown-property */

class Utils {
  generateId(prefix) {
    return prefix + Math.random().toString(36).substring(2, 9).toUpperCase();
  }

  generateRandomColors(amount) {
    var randomColors = [];

    for (let i = 0; i < amount; i++) {
      let newColor = Math.floor(Math.random() * 16777215).toString(16);
      while (randomColors.indexOf(newColor) > 0) {
        newColor = Math.floor(Math.random() * 16777215).toString(16);
      }
      randomColors.push('#' + newColor);
    }

    return randomColors;
  }

  dateyearValidation = (date) => {
    const dt = date.split('-');
    if (dt[0].length > 4) {
      dt[0] = dt[0].substring(1, 5);
      date = dt.join('-');
    }
    //convert array back to string
    dt.join('-');
    return date;
  };

  snakeCaseFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    return `${cell}`.replace(/_/g, ' ').replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
  }

  countFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (Array.isArray(cell)) {
      return cell.length;
    }
    return `${cell}`;
  }

  labelFormatter(cell, row) {
    if (cell === null) {
      return '';
    }
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (Array.isArray(cell)) {
      return cell.map((e) => e.label);
    }
    return `${cell}`;
  }

  nameFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    return `${cell}`;
  }

  capitalize(str) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  }

  dateFormatter(cell, row) {
    if (typeof cell === 'undefined' || cell === null || cell === '') {
      return cell;
    }
    const timestamp = moment(cell).format('DD MMM YYYY');
    return `${timestamp}`;
  }

  dateFormatterV2(cell, row) {
    if (typeof cell === 'undefined' || cell === null || cell === '') {
      return cell;
    }
    const timestamp = moment(cell).format('DD/MM/YYYY');
    return `${timestamp}`;
  }

  timeFormatter(cell, row) {
    if (typeof cell === 'undefined' || cell === null || cell === '') {
      return cell;
    }
    const timestamp = moment(cell).format('HH:mm');
    return `${timestamp}`;
  }

  transactionTypeFormatter(cell, row) {
    if (typeof cell === 'undefined' || cell === null || cell === '') {
      return cell;
    }
    const type = cell;
    const subType = row.subType;
    if (type && subType) {
      return capitalize(type) + ' / ' + capitalize(subType);
    } else if (type) {
      return capitalize(type);
    } else {
      return cell;
    }
  }

  //Dont delete these two functions  being used in  lot of places
  // soon we will fill these func with redux state
  setUserIDMapping = () => {};

  getUserIDMapping = () => {
    return [
      { key: '00000000-0000-0000-0000-000000000001', value: 'ag', type: 'ag' },
      { key: '00000000-0000-0000-0000-000000000002', value: 'kr', type: 'kr' },
      {
        key: '00000000-0000-0000-0000-000000000003',
        value: 'crm',
        type: 'department',
      },

      {
        key: '00000000-0000-0000-0000-000000000004',
        value: 'sales',
        type: 'department',
      },
      {
        key: '00000000-0000-0000-0000-000000000005',
        value: 'marketing',
        type: 'department',
      },
      {
        key: '00000000-0000-0000-0000-000000000006',
        value: 'driving_centre',
        type: 'department',
      },
      {
        key: '00000000-0000-0000-0000-000000000007',
        type: 'department',
        value: 'after_sales_marketing',
      },
      {
        key: '00000000-0000-0000-0000-000000000008',
        value: 'after_sales_sales',
        type: 'department',
      },
      {
        key: '00000000-0000-0000-0000-000000000009',
        value: 'alliance_event',
        type: 'department',
      },
    ];
  };

  getListDepartment = () => {
    const NoDepartment = [
      'Sales',
      'Marketing',
      'CRM',
      // 'Driving Centre',
      // 'After-Sales Marketing',
      // 'After-Sales Sales',
      'Alliance Event',
    ];
    return NoDepartment;
  };

  timestampFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    const timestamp = moment.utc(cell).local().format('YYYY-MM-DD HH:mm');
    return `${timestamp}`;
  }

  timesFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    const timestamp = moment.utc(cell).local().format('HH:mm');
    return `${timestamp}`;
  }

  amountFormatter(cell, row) {
    if (cell === '-') return '<strong>-</strong>'; // please change to a more accepted format

    return !cell && cell !== 0 ? '' : `<strong>${cell}</strong>`;
  }

  percentFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (typeof cell === 'string') {
      return cell;
    }
    const amount = numeral(cell);
    return `<strong>${amount.format('0.0%')}</strong>`;
  }

  badgeFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    return <span className="badge badge-primary">{cell}</span>;
  }

  versionFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (row.status === 'draft') {
      return '-';
    } else {
      return cell;
    }
  }

  renderStatus = (status) => {
    status = status.toLowerCase();
    if (typeof status === 'undefined') {
      return status;
    }

    if (['inactive', 'complete_unpublish', 'invite'].includes(status)) {
      return (
        <CustomChip
          size="small"
          label={<strong>{i18n.t(capitalize(status))}</strong>}
          style={{ borderRadius: 4, backgroundColor: '#C7D5E8', color: '#FFF' }}
        />
      );
    }
    if (['approved', 'published', 'active', 'completed', 'success', 'successful', 'scheduled', 'ok', 'valid', 'add'].includes(status)) {
      return (
        <CustomChip
          size="small"
          label={<strong>{i18n.t(capitalize(status))}</strong>}
          style={{ borderRadius: 4, backgroundColor: '#449D44', color: '#FFF' }}
        />
      );
    }
    if (['pending', 'draft', 'unpublished', 'cancelled', 'warning', 'modify', ' activate'].includes(status)) {
      return (
        <CustomChip
          size="small"
          label={<strong>{i18n.t(capitalize(status))}</strong>}
          style={{ borderRadius: 4, backgroundColor: '#FDAD15', color: '#FFF' }}
        />
      );
    }
    if (['rejected', 'voided', 'failed'].includes(status)) {
      return (
        <CustomChip
          size="small"
          label={<strong>{i18n.t(capitalize(status))}</strong>}
          style={{ borderRadius: 4, backgroundColor: '#d9534f', color: '#FFF' }}
        />
      );
    }
    if (['fraud'].includes(status)) {
      return (
        <CustomChip
          size="small"
          label={<strong>{i18n.t(capitalize(status))}</strong>}
          style={{ borderRadius: 4, backgroundColor: '#E4595E', color: '#FFF' }}
        />
      );
    }
  };

  statusFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (cell.indexOf('inactive') > -1) {
      return `<span class="dot dot-secondary"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('approved') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('published') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('complete_unpublish') > -1) {
      return `<span class="dot dot-secondary"></span> <strong>${i18n.t(cell.split('_')[0])}</strong>`;
    } else if (cell.indexOf('active') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('complete') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('success') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('pending') > -1) {
      return `<span class="dot dot-warning"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('rejected') > -1) {
      return `<span class="dot dot-danger"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('voided') > -1) {
      return `<span class="dot dot-danger"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('failed') > -1) {
      return `<span class="dot dot-danger"></span> <strong>${i18n.t(cell)}</strong>`;
    } else if (cell.indexOf('successful') > -1) {
      return `<span class="dot dot-success"></span> <strong>${i18n.t(cell)}</strong>`;
    } else {
      return `<span class="dot dot-warning"></span> <strong>${i18n.t(cell)}</strong>`;
    }
  }

  permissionFormatter(cell, row) {
    if (typeof cell === 'undefined') {
      return cell;
    }
    if (cell.indexOf('enabled') > -1) {
      return (
        <Button type="link">
          <i className="fas fa-check-circle text-success"></i>
        </Button>
      );
    } else if (cell.indexOf('partial') > -1) {
      return (
        <Button type="link">
          <i className="fas fa-minus-circle text-warning"></i>
        </Button>
      );
    } else {
      return (
        <Button type="link">
          <i className="fas fa-times-circle text-secondary"></i>
        </Button>
      );
    }
  }
  typesserviceformatter(cell, row) {
    if (cell === '' || cell === undefined) {
      return '';
    } else if (cell.toLowerCase() === 'airport_service' || cell.toLowerCase() === 'service_centre') {
      return 'F&B';
    } else {
      return cell
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
  featuredSort = (a, b, order, field) => {
    const va = a[field];
    const vb = b[field];
    if (va === '') {
      return 1;
    }
    if (vb === '') {
      return -1;
    }
    if (order === 'desc') {
      return va < vb ? 1 : va > vb ? -1 : 0;
    } else {
      return va < vb ? -1 : va > vb ? 1 : 0;
    }
  };

  // return the tooltip text
  getToolText = () => {
    return 'FIFO accounting method is used for coin distribution from departments to customers and customer’s coin usage.';
  };

  error = (<div className=" d-block invalid-feedback">This field is required</div>);
  errorOpenClose = (
    <div className=" d-block invalid-feedback">These field are required and closing time should always be greater than opening time </div>
  );

  inputClass = 'border border-danger bg-white';

  sortByFeatured = (a, b) => {
    if (typeof a.featured === 'number' && typeof b.featured === 'number') {
      return a.featured > b.featured ? 1 : -1;
    } else {
      if (typeof a.featured === 'number' && typeof b.featured !== 'number') {
        return 1;
      }
      if (typeof b.featured === 'number' && typeof a.featured !== 'number') {
        return 1;
      }
      return a.dateSubmitted > b.dateSubmitted ? -1 : 1;
    }
  };
  validError = (data) => {
    //only checking for contact and main contact mobile
    //customise it for outlet
    let isValidContact;
    let isValidMainContact;

    if (data.properties) {
      isValidContact = data?.properties?.contact.length <= 5 ? false : true;
      isValidMainContact = data?.properties?.main_contact_mobile.length <= 5 ? false : true;
    } else {
      isValidContact = data?.contact?.length <= 5 ? false : true;
      isValidMainContact = data?.main_contact_mobile?.length <= 5 ? false : true;
    }
    return { isValidContact, isValidMainContact };
  };

  validOutletError = (data) => {
    const MultiSelectDays = data.multi_select_days;

    const isValidSelectedall = true;
    const isValidNotSelectedall = [true];
    //array of boolean values based on which decision making will be done

    isValidNotSelectedall.fill(true, 0, MultiSelectDays.length);
    const isValidContact = data?.contact?.length <= 5 ? false : true;
    const isValidAddress = data?.address === '' ? false : true;

    //check for opening and closing

    if (!data?.is_all_days && data?.multi_select_days) {
      //we have to run loop to identity invalid rows and return them //
      //mutliselectDays is an array of objects
      const MultiSelectDays = data?.multi_select_days;
      MultiSelectDays.map((e, i) => {
        if (
          moment(e.open_close_time.opening, 'h:mma').isAfter(moment(e.open_close_time.closing, 'h:mma')) ||
          e.open_close_time.opening === '' ||
          e.open_close_time.closing === ''
        ) {
          isValidNotSelectedall[i] = false;
        } else {
          isValidNotSelectedall[i] = true;
        }
        return e;
      });
    }
    return {
      isValidContact,
      isValidAddress,
      isValidSelectedall,
      isValidNotSelectedall,
    };
  };

  /**
   *
   * @param {Array<string>} permissions array of v2 permissions
   */
  transformToV1Permissions(permissions) {
    if (!Array.isArray(permissions)) return [];
    const mergedPermissions = {};
    // merge initial permissions
    for (const v2 of permissions) {
      mergedPermissions[v2] = true;
      const splitString = v2.split('_');
      if (splitString.length <= 1) continue;
      // get possible resource index
      const resourceIndex = splitString.length - 1;
      // get edge case role/resource where it's swapped
      // TODO: to further refine to reduce number of permissions
      mergedPermissions[splitString[0]] = true;
      mergedPermissions[splitString[resourceIndex]] = true;
      // start combining role + resource
      if (splitString.length > 2) {
        for (let i = 0; i < resourceIndex; i++) {
          const v1RoleResource = `${splitString[i]}_${splitString[resourceIndex]}`;
          mergedPermissions[v1RoleResource] = true;
        }
      }
    }

    const arr = Object.keys(mergedPermissions).map((key) => key);
    return arr;
  }

  isThumbnailAndCoverImageIdentical(object) {
    if (!object) return false;
    const { thumbnailImage, coverImage } = object;
    if (!thumbnailImage || !coverImage) return false;
    const tmbImage = thumbnailImage ? thumbnailImage.id : uuidv4(); // randomise
    const covImage = coverImage ? coverImage.id : uuidv4(); // randomise
    return tmbImage === covImage;
  }

  /**
   * TODO: please support other data structures
   * @param {Set<string>} a
   * @param {Set<string>} b
   */
  isSetEqual(a, b) {
    if (a.size !== b.size) return false;
    for (const x of a) if (!b.has(x)) return false;
    return true;
  }

  /**
   *
   * @param {number|string} num
   * @param {number} min
   * @param {number} max
   */
  clampNumber(num, min, max) {
    const number = parseInt(num, 10);
    if (!number) return 0;
    return Math.min(Math.max(num, min), max);
  }

  clampNumberMin(num, min) {
    const number = parseInt(num, 10);
    if (!number) return 0;
    return Math.max(num, min);
  }
  formatCreatedDateForSort = (createdAt, time = '00:00') => {
    return moment(`${createdAt} ${time}`, 'DD MMM YYYY HH:mm').format();
  };

  daysBetweenTwoDates(start, end, unit = 'days') {
    if (!end) {
      return moment().diff(moment(start), unit);
    }
    return moment(end).diff(moment(start), unit);
  }

  setItemCategoryArray(item) {
    if (item.category && !Array.isArray(item.category)) {
      const value = item.category;
      item.category = [value];
    }
    return item;
  }
  validateRequiredInput = (data, type) => {
    switch (type) {
      case 'string': {
        if (data === '' || typeof data !== 'string') {
          return false;
        }
        return true;
      }
      case 'tel': {
        return data?.length <= 5 ? false : true;
      }
      case 'email': {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (data.match(mailformat)) {
          return true;
        }
        return false;
      }
      case 'array': {
        return Array.isArray(data) && data.length > 0;
      }
      case 'object': {
        if (typeof data !== 'object') {
          return false;
        }
        return Array.isArray(data) ? false : Object.keys(data).length > 0;
      }
      case 'website': {
        let url;
        try {
          url = new URL(data);
        } catch (_) {
          return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
      }
    }
  };
  dateRangePayloadFormatter = (dateRange) => {
    return {
      startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
    };
  };

  phoneFormater = (item) => {
    const payload = { ...item };

    if (payload?.contact && !payload.contact.startsWith('+')) {
      payload.contact = '+' + payload.contact;
    }

    if (payload?.main_contact_mobile && !payload.main_contact_mobile.startsWith('+')) {
      payload.main_contact_mobile = '+' + payload.main_contact_mobile;
    }

    return payload;
  };

  stylePopover(inputId, popoverEl, content, popoverId) {
    const rect = document.getElementById(inputId).getBoundingClientRect();

    const text = document.createElement('div');
    text.innerText = content;
    popoverEl.style.backgroundColor = '#f1f4f8';
    popoverEl.style.borderRadius = '8px';
    popoverEl.style.position = 'absolute';
    popoverEl.style.left = (Number(rect.left) - 160 >= 0 ? Number(rect.left) - 160 : 0).toString() + 'px';
    popoverEl.style.top = rect.y + rect.height + 4 + 'px';
    popoverEl.style.zIndex = '1000';
    popoverEl.style.display = 'none';
    if (!popoverEl.hasChildNodes(text)) {
      popoverEl.appendChild(text);
    }
    popoverEl.id = popoverId;
  }
  keepsStaticNumberOfTicks(numberOfSticks, datasets, options) {
    const newOptions = { ...options };
    const data = datasets.map((item) => item.data).flat();

    let maxValue = 0;
    if (datasets?.length) {
      data.forEach((item) => {
        maxValue = Math.max(maxValue, item);
      });

      if (datasets?.length === 3) {
        maxValue = maxValue * datasets?.length;
      }
      if (datasets?.length > 3) {
        maxValue = (maxValue * datasets?.length) / 2;
      }

      if (maxValue < numberOfSticks) {
        maxValue = numberOfSticks;
      }
      let stepSize = Math.floor(maxValue / numberOfSticks);
      if (maxValue > stepSize * numberOfSticks) {
        stepSize = maxValue / numberOfSticks;
      }
      if (!newOptions.scales.yAxes[0].ticks) {
        newOptions.scales.yAxes[0].ticks = {};
      }

      newOptions.scales.yAxes[0].ticks.min = 0;
      newOptions.scales.yAxes[0].ticks.max = maxValue;
      newOptions.scales.yAxes[0].ticks.stepSize = stepSize;
      newOptions.scales.yAxes[0].ticks.callback = (value) => {
        if (value >= 1000 && value < 1000000) {
          return `${(value / 1000).toFixed(1)}K`;
        } else if (value >= 1000000 && value < 1000000000) {
          return `${(value / 1000000).toFixed(1)}M`;
        } else if (value >= 1000000000) {
          return `${(value / 1000000000).toFixed(1)}B`;
        } else {
          return `${Math.round(value)}`;
        }
      };
    }

    return newOptions;
  }

  removeCountryNameTranslation(name) {
    if (name.includes('(')) {
      return name.split('(')[0].trim();
    }

    return name;
  }

  formatPermission(permissions) {
    return {
      home: permissions.home,
      wallet: permissions.wallet,
      insights: permissions.insights,
      transactions: permissions.transactions,
      campaigns: permissions.campaigns,
      marketing_content: permissions.marketing_content,
      partner_management: permissions.partner_management,
      entity_management: permissions.entity_management,
      user_management: permissions.user_management,
      lead_management: permissions.lead_management,
      settings_permissions: permissions.settings_permissions,
      security: permissions.security,
      tc: permissions.tc,
      faq: permissions.faq,
    };
  }
}

const Loader = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const withSuspense = (Component) => {
  return function ComponentWithSuspense(props) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

const isIEOrTrident = () => {
  const userAgent = navigator.userAgent;
  return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1;
};

export default new Utils();
export { Loader, withSuspense, isIEOrTrident };
