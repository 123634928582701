import { isError, isFSA } from 'flux-standard-action';
import jwt from 'jsonwebtoken';
import { DEFAULT_CLIENT } from '../../constants';
import config from './config';
import Logger from './Logger';
import secureStorage from './SecureStorage';
const logger = new Logger('Unify');

/**
 * Singleton utility class for accessing Unify API services
 */
class UnifyError extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) Error.captureStackTrace(this, UnifyError);
    this.name = 'UnifyError';
    if (typeof args[0] === 'string') {
      this.message = args[0];
    } else if (typeof args[0] === 'object') {
      const arg0 = args[0];
      if (arg0.message) {
        this.message = arg0.message;
      }
      if (arg0.error) {
        const error = arg0.error;
        const parts = [];
        if (error.errorcode) {
          parts.push(error.errorcode + ':');
        }
        if (error.message) {
          parts.push(error.message);
        }
        if (error.logkey) {
          parts.push('(' + error.logkey + ')');
        }
        this.message = parts.join(' ');
      }
    }
  }

  toString() {
    return 'UnifyError likes cheese';
  }
}

/**
 *
 */
class Unify {
  // Stores the JWT token for the current session
  _token = null;

  //
  _tokenData = null;

  //
  _client = null;

  /**
   *
   */
  constructor() {
    this.restoreToken();
  }

  /**
   *
   * @returns {null}
   */
  getClient() {
    // Try the current value
    if (this._client !== null) {
      return this._client;
    }

    // If thats not set try localStorage
    if (window && window.sessionStorage) {
      if (secureStorage.key('_client') in sessionStorage) {
        let value = secureStorage.getItem('_client');
        if (value === null || value === '' || value === 'null') {
          value = DEFAULT_CLIENT;
        }
        this._client = value;
        return this._client;
      }
    }

    // Otherwise default to this entity
    const tokenData = this.getTokenData();
    if (tokenData) {
      if (tokenData['entity']) {
        let value = tokenData['entity'];
        if (value === null || value === '' || value === 'null') {
          value = DEFAULT_CLIENT;
        }
        this._client = value;
        return this._client;
      }
    }

    // return this._client; // May be null
    return DEFAULT_CLIENT;
  }

  /**
   *
   * @param value
   */
  setClient(value) {
    this._client = value;
    if (window && window.sessionStorage) {
      secureStorage.setItem('_client', value);
    }
  }

  /**
   * Restore token from sessionStorage
   */
  restoreToken() {
    if (window.sessionStorage) {
      // if ('_token' in sessionStorage) {
      //   this._token = sessionStorage.getItem('_token');
      //   return this.validToken();
      // }
      // return false;
      if (secureStorage.key('_token') in sessionStorage) {
        this._token = secureStorage.getItem('_token');
        return this.validToken();
      }
      return false;
    } else {
      this._token = null;
    }
    return false;
  }

  /**
   * Simple token validation, note that it decodes the token and does not check the signature
   * @returns {boolean}
   */
  validToken() {
    if (this._token === null) {
      return false;
    }
    if (typeof this._token === 'undefined') {
      return false;
    }
    if (typeof this._token === 'string') {
      const decoded = jwt.decode(this._token);
      if (decoded === null) {
        return false;
      }

      // Must have a user_id
      if (typeof decoded['user_id'] === 'undefined') {
        return false;
      }

      // Must have type admin
      if (decoded['type'] !== 'admin') {
        return false;
      }

      // Must have a role
      if (typeof decoded['role'] === 'undefined') {
        return false;
      }

      // Must have an entity
      if (typeof decoded['entity'] === 'undefined') {
        return false;
      }

      // Store the token
      this._tokenData = decoded;

      // Must not have expired
      if (typeof decoded['exp'] !== 'undefined') {
        const now = new Date().getTime() / 1000;
        if (decoded['exp'] <= now) {
          this.deleteToken();
          return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * Update the token
   * @param token
   */
  setToken(token) {
    this._token = token;
    try {
      // if (window.sessionStorage) {
      //   sessionStorage.setItem('_token', token);
      // }
      secureStorage.setItem('_token', token);
    } catch (ignore) {}
  }

  /**
   * Get the current token
   * @returns {string}
   */
  getToken() {
    return this._token;
  }

  /**
   *
   * @returns {null}
   */
  getTokenData() {
    return this._tokenData;
  }

  /**
   * Delete the token
   */
  deleteToken() {
    this._token = null;
    try {
      // if (window.sessionStorage) {
      //   sessionStorage.removeItem('_token');
      // }
      secureStorage.removeItem('_token');
    } catch (ignore) {}
  }

  /**
   * Extracts the last date from the DatePicker
   * @returns {{}|{endDate: Date, startDate: Date}}
   */
  getDateParams() {
    // Extract from DatePicker
    if (sessionStorage) {
      const str = sessionStorage.getItem('_datepicker');
      if (str) {
        try {
          const obj = JSON.parse(str);
          const state = {
            startDate: new Date(obj.startDate),
            endDate: new Date(obj.endDate),
          };
          return state;
        } catch (err) {
          return {};
        }
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  /**
   *
   * @param path
   * @returns {Promise<any|never>}
   * @private
   */
  async _get(path) {
    return this._call('GET', path);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _patch(path, data) {
    return this._call('PATCH', path, data);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _post(path, data) {
    return this._call('POST', path, data);
  }

  /**
   *
   * @param path
   * @param data
   * @returns {Promise<any|never>}
   * @private
   */
  async _put(path, data) {
    return this._call('PUT', path, data);
  }

  /**
   *
   * @param path
   * @returns {Promise<any|never>}
   * @private
   */
  async _delete(path) {
    return this._call('DELETE', path);
  }

  async _downloadFile(path, data) {
    return new Promise((resolve, reject) => {
      fetch(path, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + this._token,
          'Content-Type': 'application/json',
        },
        body: data,
      })
        .then(async (res) => {
          if (res.status !== 201) {
            const error = res.errorMassage || 'Download file Error';
            reject(error);
          }
          const blob = await res.blob();

          // Add BOM sequence to force UTF-8 encoding
          const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
          const UTF8Blob = new Blob([BOM, blob]);

          resolve(UTF8Blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param path
   * @param method
   * @param data
   * @returns {Promise<any | never>}
   * @private
   */
  async _call(method = 'GET', path, data = undefined) {
    // Initialise the body and headers with defaults
    let requestBody = undefined;
    let requestHeaders = {
      'Content-Type': 'application/json',
      ...config.headers,
    };

    // Prepare the body
    if (data instanceof FormData) {
      // Leave FormData untouched
      requestHeaders = {};
      requestBody = data;
    } else if (typeof data !== 'undefined') {
      // JSONify everything else
      requestBody = JSON.stringify(data);
    }

    // Add the Authorization header if a token is set
    if (typeof this._token !== 'undefined') {
      if (this._token !== null) {
        requestHeaders['Authorization'] = 'Bearer ' + this._token;
      }
    }

    // Execute the fetch inside a promise
    return new Promise((resolve, reject) => {
      fetch(config.endpoint + path, {
        method,
        mode: 'cors',
        body: requestBody,
        headers: requestHeaders,
      })
        .then(
          async (res) => {
            if (res.ok) {
              try {
                const data = await res.json();
                return data;
              } catch (e) {
                // console.error(e);
              }
            } else {
              let message = 'E_ERROR';
              try {
                const data = await res.json();
                if (typeof data['message'] !== 'undefined') {
                  message = data['message'];
                }
                if (data && data.error && data.error.message) {
                  message = data.error.message;
                }
              } catch (e) {
                // console.error(e);
              }
              throw new UnifyError(message, res);
            }
          },
          (error) => {
            logger.warn('E_NETWORK_ERROR', error);
            reject(new UnifyError('E_NETWORK_ERROR', error));
          }
        )
        .catch((err) => {
          if (err === 'E_TOKEN_EXPIRED') {
            this.deleteToken();
          }
          reject(new UnifyError(err));
        })
        .then((responseJson) => {
          // Make flux-standard-action compliant responses backward-compatible
          if (isFSA(responseJson)) {
            if (isError(responseJson)) {
              responseJson['status'] = 'ERROR';
            } else {
              responseJson['status'] = 'OK';
            }
          }
          // Legacy responses
          if (typeof responseJson !== 'undefined') {
            if (typeof responseJson['status'] !== 'undefined') {
              if (responseJson['status'] === 'OK') {
                if (typeof responseJson['token'] !== 'undefined') {
                  this.setToken(responseJson['token']);
                }
              } else if (responseJson['status'] === 'ERROR') {
                let message = 'E_ERROR';
                if (typeof responseJson['message'] !== 'undefined') {
                  message = responseJson['message'];
                }
                reject(new UnifyError(responseJson));
              }
            }
          }
          resolve(responseJson);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const unifySingleton = new Unify();

// TODO: Remove from production
window.Unify = unifySingleton;

export default unifySingleton;
