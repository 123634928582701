// Updates an entity cache in response to any action with response.entities.
export default function rewards(state = {}, action) {
  switch (action.type) {
    case 'EVENT_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload,
      });
    case 'EVENT_GET_SUCCESS':
      return Object.assign({}, state, {
        event: action.payload,
      });
    case 'EVENT_GET_ANALYTICS_SUCCESS':
      return Object.assign({}, state, {
        analytics: action.payload,
      });
    case 'EVENT_GET_ATTENDEES_SUCCESS':
      return Object.assign({}, state, {
        attendees: action.payload,
      });
    case 'EVENT_CLEAR':
      return Object.assign({}, state, {
        event: undefined,
      });
    case 'NEWS_EVENT_EDIT_STATUS':
      return Object.assign({}, state, {
        edit: action.payload,
      });
    case 'NEWS_EDIT_ONLY':
      return Object.assign({}, state, {
        update: action.payload,
      });

    default:
      return state;
  }
}
