import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * @summary Customized styles and colors for MUI <LinearProgress />
 */
export const useChipStyles = makeStyles((theme) =>
  createStyles({
    customChip: {
      borderRadius: theme.spacing(0.5),
      height: 'auto',
      padding: theme.spacing(0),
      fontWeight: 'bold',
    },
    chipWarning: {
      backgroundColor: theme.palette.warning.main,
    },
  })
);

const CustomChip = ({ label, ...props }) => <Chip {...props} label={label} />;

export default CustomChip;
