const typography = {
  h1: {
    fontSize: 32,
    lineHeight: '44.8px',
    letterSpacing: '0.43px',
  },
  h2: {
    fontSize: 28,
    lineHeight: '39.2px',
    letterSpacing: '0.37px',
  },
  h3: {
    fontSize: 20,
    lineHeight: '28px',
    letterSpacing: '0.27px',
  },
  body1: {
    fontSize: 18,
    lineHeight: '25.2px',
    letterSpacing: '0.24px',
  },
  body2: {
    fontSize: 14,
    lineHeight: '19.07px',
  },
  subtitle1: {
    fontSize: 14,
    lineHeight: '19.6px',
    letterSpacing: '0.19px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '16.8px',
    letterSpacing: '0.16px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '15.4px',
    letterSpacing: '0.15px',
  },
  button: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21.79px',
  },
  chartTitle: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.186667px',
    fontWeight: 700,
  },
  chartTotal: {
    fontSize: 28,
    lineHeight: '39px',
    letterSpacing: '0.373333px',
    fontWeight: 700,
  },
  chartCaption: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 1,
    letterSpacing: '0.16px',
  },
  fontSize12: '12px',
  fontFamily: 'Open Sans, sans-serif',
};
const overrides = {
  MuiTableContainer: {
    root: {
      '& * ': {
        fontSize: 11,
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiTableBody: {
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
  },
  MuiTableHead: {
    root: {
      '& * ': {
        borderBottomWidth: 2,
        borderColor: '#059ED9',
      },
    },
  },
  MuiTableRow: {
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#FAFAFA',
      },
    },
  },
  MuiTableCell: {
    root: {
      '&:last-child': {
        paddingRight: 'inherit !important',
      },
      '&.MuiTableCell-root': {
        padding: 5,
      },
      '&.MuiTableCell-head': {
        padding: 0,
      },
    },
  },
  MuiButton: {
    root: {
      boxShadow: 'none',
      fontWeight: 700,
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
};
// Define theme settings
export const light = {
  palette: {
    mode: 'light',
    background: {
      default: '#F2F4F8',
      paper: '#F2F4F8',
      footer: '#CBD0D8',
      accordionSummary: '#F2F4F8',
      accordionDetails: '#FFFFFF',
      aside: '#FFFFFF',
      drawerItem: 'rgba(255, 255, 255, 0.15)',
      divider: '#D1D1D1',
      filterBtn: '#ffffff',
    },
    primary: {
      main: '#e4595e',
      dark: '#666666',
      light: '#ef9c9f',
      contrastText: '#FFFFFF',
      edit: '#23282c',
      breadcrumbs: '#3A3A3C',
      textFilter: '#4B4F53',
      searchbox: '#B7BCC2',
    },
    secondary: {
      main: '#73818f',
      light: '#D1D1D1',
      dark: '#c8cedF',
      contrastText: '#000000',
      menu: '#1B212C',
      breadcrumbs: '#73818F',
    },
    error: {
      main: '#F82E2E',
      hover: '#E06A55',
    },
    warning: {
      main: '#F2B600',
      light: '#f9c743',
      dark: '#715401',
      contrastText: '#f1e4ce',
    },
    info: {
      main: '#17A2B8',
      light: '#8ad1e6',
      dark: '#3c788c',
      contrastText: 'rgba(0,0,0,0.87)',
      darkText: '#434E61',
    },
    success: {
      main: '#449D44',
      light: '#78ce98',
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#2a6d41',
    },
    hover: {
      main: '#535353',
      process: '#df383e',
      preview: '#cf2228',
      edit: '#b3bbc2',
      background: '#f9f9f9',
    },
    disabled: {
      main: '#ec9295',
      disable: '#9a9a9a',
      light: '#e4e7ea',
    },
    backgroundDrawer: {
      default: '#455570',
    },
    btnFilter: {
      color: '#73818F',
      border: '1px solid #B7BCC2',
    },
    dateRange: {
      color: '#D1D1D1',
    },
    pageSizeBtn: {
      border: '1px solid #D1D1D1',
    },
    filterTag: {
      background: '#E9EEF4',
      color: '#23282C',
      divider: '#23282C',
      description: '#23282c',
    },
    btnFilterSelected: {
      color: '#17A2B8',
      backgroundColor: '#F8FAFB',
      border: '1px solid #17A2B8',
    },
    chartGridLine: {
      color: '#D1D1D1',
    },
    selectBtn: {
      color: '#17A2B8',
      backgroundColor: '#E9EEF4  ',
      border: '1px solid #17A2B8',
    },
    text: {
      main: '#3A3A3C',
      cardHeader: '#6B6B6F',
      cardCaption: '#9DA8B7',
      filterTag: '#23282C',
      expiryDate: '#01719C',
      input: '#323C4E',
      cardTitle: 'rgba(0, 0, 0, 0.87)',
    },
    border: {
      filterBtn: '#B7BCC2',
      activeCard: '#E4595E',
    },
    drawer: {
      main: '#455570',
    },
    tripedTableRow: {
      odd: '#F8FAFB',
      even: '#FFFFFF',
      hover: '#E9EEF4',
    },
    switch: {
      track: '#F2F4F7 ',
      thumb: '#FFFFFF',
      checkedTrack: '#01719C',
      checkedThumb: '#FFFFFF',
    },
    paginationBtn: {
      text: '#434e60',
      activeBg: '#E4595E',
      activeText: '#ffffff',
    },
    topupBtn: {
      backgroundColor: '#E9EEF4',
      color: '#17A2B8',
    },
    modalTitle: {
      background: '#95A1B7',
      color: '#ffffff',
      closeBtn: '#6C757D',
    },
    modalContent: {
      background: '#fafafa',
    },
    chartColors: {
      yellow: '#FFC107',
      red: '#E4595E',
      orange: '#f3900f',
      blue: '#63C2DE',
      darkBlue: '#01719C',
      purple: '#800080',
      green: '#C4D576',
      gray: '#979797',
      linearBlue: 'linear-gradient(180deg, #BAC8E0 0%, #6A85B6 100%)',
      linearSilver: 'linear-gradient(135deg, #929CAC 0%, #424C5B 100%)',
      linearGold: 'linear-gradient(135deg, #CEA76C 0%, #77501E 100%)',
      linearBlack: 'linear-gradient(135deg, #555E6B 0%, #202832 100%)',
    },
    input: {
      background: '#ffffff',
      color: '#3A3A3C',
      placeholder: '#9D9D9D',
    },
    buttonGroup: {
      border: '#C7D5E8',
      amount: '#000000',
      lightBg: '#F4F4F4',
    },
  },
  typography,
  overrides,
};
export const dark = {
  palette: {
    mode: 'dark',
    background: {
      default: '#222831',
      paper: '#1B212C',
      footer: '#36404F',
      accordionSummary: '#4f545e',
      accordionDetails: '#2C3440',
      aside: '#1B212C',
      drawerItem: 'rgba(255, 255, 255, 0.15)',
      divider: '#566378',
      filterBtn: '#E9EEF4',
    },
    primary: {
      main: '#e4595e',
      dark: '#666666',
      light: '#ef9c9f',
      contrastText: '#FFFFFF',
      edit: '#23282c',
      breadcrumbs: '#D1D1D1',
      textFilter: '#FFFFFF',
      searchbox: '#434E61',
    },
    secondary: {
      main: '#E9EEF4',
      light: '#D1D1D1',
      dark: '#c8cedF',
      contrastText: '#000000',
      menu: '#F2F4F8',
      breadcrumbs: '#D1D1D1',
    },
    error: {
      main: '#F82E2E',
      hover: '#E06A55',
    },
    warning: {
      main: '#F2B600',
      light: '#f9c743',
      dark: '#715401',
      contrastText: '#f1e4ce',
    },
    info: {
      main: '#BDE3F1',
      light: '#8ad1e6',
      dark: '#3c788c',
      contrastText: 'rgba(0,0,0,0.87)',
      darkText: '#434E61',
    },
    success: {
      main: '#449D44',
      light: '#78ce98',
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#2a6d41',
    },
    hover: {
      main: '#535353',
      process: '#df383e',
      preview: '#cf2228',
      edit: '#b3bbc2',
      background: '#f9f9f9',
    },
    disabled: {
      main: '#ec9295',
      disable: '#9a9a9a',
      light: '#e4e7ea',
    },
    backgroundDrawer: {
      default: '#1B232F',
    },
    btnFilter: {
      color: '#E9EEF4',
      border: '1px solid #434E61',
    },
    dateRange: {
      color: '#9D9D9D',
    },
    pageSizeBtn: {
      border: '1px solid #434E61',
    },
    filterTag: {
      background: '#ffffff40',
      color: '#C7D5E8',
      divider: '#566378',
      description: '#B7BCC2',
    },
    btnFilterSelected: {
      color: '#BDE3F1',
      backgroundColor: 'rgba(233, 238, 244, 0.15)',
      border: '1px solid #BDE3F1',
    },
    chartGridLine: {
      color: 'rgba(209, 209, 209, 0.4)',
    },
    selectBtn: {
      color: '#E4595E',
      backgroundColor: '#222831',
      border: '1px solid rgba(228, 89, 94, 0.75)',
    },
    text: {
      main: '#FFFFFF',
      cardHeader: '#FFFFFF',
      cardCaption: '#C7D5E8',
      filterTag: '#C7D5E8',
      expiryDate: '#01719C',
      input: '#FFFFFF',
      cardTitle: '#FFFFFF',
    },
    border: {
      filterBtn: '#ffffff',
      activeCard: '#E4595E',
    },
    drawer: {
      main: '##1B232F',
    },
    tripedTableRow: {
      odd: '#36404F',
      even: '#2C3440',
      hover: '#222831',
    },
    switch: {
      track: '#222831 ',
      thumb: '#01719C',

      checkedTrack: '#01719C',
      checkedThumb: '#000000',
    },
    paginationBtn: {
      text: '#ffffff',
      activeBg: 'rgba(242, 244, 248, 0.25)',
      activeText: '#ffffff',
    },
    topupBtn: {
      backgroundColor: '#E9EEF4',
      color: '#17A2B8',
    },
    modalTitle: {
      background: '#1B212C',
      color: '#ffffff',
      closeBtn: '#C7D5E8',
    },
    modalContent: {
      background: '#222831',
    },
    chartColors: {
      yellow: '#FFC107',
      red: '#E4595E',
      blue: '#63C2DE',
      green: '#C4D576',
      darkBlue: '#01719C',
      gray: '#979797',
      linearBlue: 'linear-gradient(180deg, #BAC8E0 0%, #6A85B6 100%)',
      linearSilver: 'linear-gradient(135deg, #929CAC 0%, #424C5B 100%)',
      linearGold: 'linear-gradient(135deg, #CEA76C 0%, #77501E 100%)',
      linearBlack: 'linear-gradient(135deg, #555E6B 0%, #202832 100%)',
    },
    input: {
      background: '#222831',
      color: '#C7D5E8',
      placeholder: '#C7D5E8',
    },
    buttonGroup: {
      border: '#566378',
      amount: '#FFFFFF',
      lightBg: '#2C3440',
    },
  },
  typography,
  overrides,
};
