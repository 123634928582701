import { isUndefined } from 'lodash';
import Unify from './Unify';

// const logger = new Logger('Broker');

/**
 *
 */
class Broker {
  /**
   *
   * @returns {Promise<any|never>}
   */
  async getPlatforms() {
    return Unify._get('/api/broker/platforms');
  }

  /**
   *
   * @param platform
   * @returns {Promise<any|never>}
   */
  async getCodes(platform) {
    if (isUndefined(platform)) throw new Error('platform is required');
    return Unify._get(`/api/broker/codes?platform=${platform}`);
  }

  /**
   *
   * @param platform
   * @returns {Promise<any|never>}
   */
  async getCode(code) {
    if (isUndefined(code)) throw new Error('code is required');
    return Unify._get(`/api/broker/codes/${code}`);
  }

  /**
   *
   * @returns {Promise<any|never>}
   */
  async getCoinMeta() {
    return Unify._get('/api/broker/coin');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async retrieveExchangeRate() {
    return Unify._get('/api/admin/settings/broker-enjin-exchange');
  }

  /**
   *
   * @param id
   * @returns {Promise<any|never>}
   */
  async UpdateExchangeRate(rate) {
    return Unify._put('/api/admin/settings/broker-enjin-exchange', { rate });
  }
}

export default new Broker();
