import moment from 'moment-timezone';

//
const tz_guess = moment.tz.guess();
function localize_timestamp(timestamp) {
  return moment(timestamp).tz(tz_guess).toISOString(true);
}

// Updates an entity cache in response to any action with response.entities.
export default function rewards(state = {}, action) {
  switch (action.type) {
    case 'COINS_LIST_SUCCESS':
      return Object.assign({}, state, {
        list: action.payload
          .map((e) => {
            if (e.created_at) {
              e.created_at = localize_timestamp(e.created_at);
            }
            if (e.timestamp) {
              e.timestamp = localize_timestamp(e.timestamp);
            }
            return e;
          })
          .sort((a, b) => {
            if (a.timestamp > b.timestamp) {
              return -1;
            } else if (a.timestamp < b.timestamp) {
              return 1;
            } else {
              return 0;
            }
          }),
      });
    case 'COINS_GET_SUCCESS':
      return Object.assign({}, state, {
        transaction: action.payload,
      });
    case 'REWARD_CLEAR':
      return Object.assign({}, state, {
        transaction: undefined,
        list: [],
      });
    default:
      return state;
  }
}
