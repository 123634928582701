import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Admin } from '../services/Unify';

const REWARDS = 'rewards';

function getRewardById(id) {
  return Admin.getDataById(REWARDS, id);
}

function deleteRewardById(id) {
  return Admin.deleteDataById(REWARDS, id);
}

function updateRewardById(payload) {
  return Admin.patchDataById(REWARDS, payload.id, payload.data);
}

function getAnalyticsById(id) {
  return Admin.getAnalyticsById(REWARDS, id);
}

function* listRewards(action) {
  try {
    const rewards = yield call(Admin.getData, REWARDS);
    yield put({ type: 'REWARD_LIST_SUCCESS', payload: rewards });
  } catch (e) {
    yield put({ type: 'REWARD_LIST_FAILED', payload: e.message });
  }
}

function* getReward(action) {
  try {
    // const user = yield call(User.getProfile, action.payload.userId);
    const reward = yield call(getRewardById, action.payload);
    yield put({ type: 'REWARD_GET_SUCCESS', payload: reward });
  } catch (e) {
    yield put({ type: 'REWARD_GET_FAILED', payload: e.message });
  }
}

function* deleteReward(action) {
  try {
    // const user = yield call(User.getProfile, action.payload.userId);
    const reward = yield call(deleteRewardById, action.payload);
    yield put({ type: 'REWARD_DELETE_SUCCESS', payload: reward });
    yield put({ type: 'REWARD_LIST' });
  } catch (e) {
    yield put({ type: 'REWARD_DELETE_FAILED', payload: e.message });
  }
}

function* updateReward(action) {
  try {
    const reward = yield call(updateRewardById, action.payload);
    yield put({ type: 'REWARD_UPDATE_SUCCESS', payload: reward });
  } catch (e) {
    yield put({ type: 'REWARD_UPDATE_FAILED', payload: e.message });
  }
}

function* getRewardAnalytics(action) {
  try {
    const result = yield call(Admin.getAnalyticsById, REWARDS, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* getRewardTransactions(action) {
  try {
    const result = yield call(Admin.getTransactionsById, REWARDS, action.payload);
    yield put({ type: action.type + '_SUCCESS', payload: result });
  } catch (e) {
    yield put({ type: action.type + '_FAILED', payload: e.message });
  }
}

function* rewardsSaga() {
  yield takeLatest('REWARD_LIST', listRewards);
  yield takeEvery('REWARD_GET', getReward);
  yield takeEvery('REWARD_DELETE', deleteReward);
  yield takeEvery('REWARD_UPDATE', updateReward);
  yield takeEvery('REWARD_GET_ANALYTICS', getRewardAnalytics);
  yield takeEvery('REWARD_GET_TRANSACTIONS', getRewardTransactions);
}

export default rewardsSaga;
