import React from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { withLocalization } from '../../i18n';
import { isIEOrTrident } from '../../Utils';
import './NoIENotification.scss';

class NoIENotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: true,
    };
  }

  componentDidMount() {
    const showNotification = isIEOrTrident();
    this.setState({ showNotification });
  }

  _onClick = () => {
    window.open('https://www.google.com/chrome/', '_blank');
    return;
  };

  toggleModal = () => {
    this.setState({
      showNotification: false,
    });
  };

  render() {
    const { showNotification } = this.state;
    const { t } = this.props;
    if (!showNotification) return null;

    return (
      // <div className={'no-ie-container'}>
      //   <div
      //     className={'no-ie-text-button-container'}
      //     style={{textAlign: 'left', paddingLeft: '80px', paddingRight: '80px'}}
      //   >
      //     <span className={'no-ie-text'}>
      //       <b>
      //         {t('internet_explorer_error') +
      //           `If you do not have, please `}

      //         <Button
      //           className={'no-ie-button'}
      //           color={'primary'}
      //           onClick={this._onClick}
      //         >
      //           {t('download_chrome')}
      //         </Button>
      //         <br></br>
      //         {t(
      //           " If you wish to continue on this browser, you'll encounter a degraded experience.",
      //         )}
      //       </b>
      //     </span>
      //   </div>
      // </div>

      // <ConfirmModal />
      <Modal isOpen={this.state.showNotification} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>{t('Update Browser')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              {t('internet_explorer_error') + " If you wish to continue on this browser, you'll encounter a degraded experience."}
              <br></br>
              <br></br>
              <Button className={'no-ie-button'} color={'primary'} onClick={this._onClick}>
                {t('download_chrome')}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default withLocalization(NoIENotification);
